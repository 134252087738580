import { InvariantException } from '@kontent-ai/errors';
import { ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import { ScheduleUnpublishErrorMessage } from '../../../../../../_shared/features/ChangeWorkflowStepModal/constants/uiConstants.ts';
import { ContentItemId } from '../../../../../../_shared/models/ContentItemId.ts';
import { TrackUserEventAction } from '../../../../../../_shared/models/TrackUserEvent.type.ts';
import { ContentItemEditingChangeAction } from '../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { getTimestamp } from '../../../../../../_shared/utils/dateTime/timeUtils.ts';
import { logErrorToMonitoringToolWithCustomMessage } from '../../../../../../_shared/utils/logError.ts';
import { IContentItemRepository } from '../../../../../../repositories/interfaces/IContentItemRepository.type.ts';
import { IContentItemWithVariantServerModel } from '../../../../../../repositories/serverModels/INewContentItemServerModel.ts';
import { getModalDialogActionOrigin } from '../../../../selectors/getModalDialogActionOrigin.ts';
import { IParsedItemVariant } from '../../utils/parseContentItem.ts';
import { prepareWorkflowStepTrackingData } from '../../utils/prepareWorkflowStepTrackingData.ts';
import {
  contentItemEditingSidebarSectionCleanedUp,
  scheduledUnpublishingFailed,
  scheduledUnpublishingFinished,
  scheduledUnpublishingStarted,
} from '../contentItemEditingActions.ts';
import { ILoadRelatedContentItemElementsDataAction } from './loadRelatedContentItemElementsData.ts';

interface IScheduleUnpublishContentItemVariantDependencies {
  readonly trackUserEvent: TrackUserEventAction;
  readonly contentItemRepository: IContentItemRepository;
  readonly parseContentItemVariant: (
    contentItemWithVariant: IContentItemWithVariantServerModel,
  ) => IParsedItemVariant;
  readonly loadRelatedContentItemElementsData: ILoadRelatedContentItemElementsDataAction;
}

const getSecondsSinceLastPublished = (
  lastPublishedAt: DateTimeStamp | null,
  lastModifiedAt: DateTimeStamp | null,
): number => (getTimestamp(lastModifiedAt) - getTimestamp(lastPublishedAt)) / 1000;

export const scheduleUnpublishOfContentItemVariantActionCreator =
  (deps: IScheduleUnpublishContentItemVariantDependencies) =>
  ({ itemId, variantId }: ContentItemId): ThunkPromise =>
  async (dispatch, getState): Promise<void> => {
    const state = getState();
    const {
      contentApp: {
        editorUi: { itemEditingModalDialog },
        listingUi: { filter },
        changeWorkflowStepModalData: { scheduledToUnpublishAt, scheduledUnpublishDisplayTimeZone },
      },
    } = state;

    if (!scheduledToUnpublishAt) {
      throw InvariantException('scheduledToUnpublishAt is falsy');
    }

    dispatch(scheduledUnpublishingStarted());
    try {
      const itemWithVariant = await deps.contentItemRepository.scheduleVariantUnpublish(
        itemId,
        variantId,
        scheduledToUnpublishAt,
        scheduledUnpublishDisplayTimeZone,
      );
      const variantData = deps.parseContentItemVariant(itemWithVariant);
      await dispatch(
        deps.loadRelatedContentItemElementsData(
          itemId,
          variantId,
          variantData.editedContentItemVariantElements,
          null,
        ),
      );
      const compiledVariant = {
        ...variantData,
        editedContentItemVariantElements: variantData.editedContentItemVariantElements,
      };
      dispatch(
        scheduledUnpublishingFinished({
          itemWithVariant,
          itemVariantData: compiledVariant,
          filter,
        }),
      );

      dispatch(
        deps.trackUserEvent(
          TrackedEvent.ContentItemEditing,
          prepareWorkflowStepTrackingData(compiledVariant, {
            action: ContentItemEditingChangeAction.ScheduleUnpublish,
            origin: getModalDialogActionOrigin(state),
            secondsSinceLastPublish: getSecondsSinceLastPublished(
              itemWithVariant.variant.lastPublishedAt,
              itemWithVariant.variant.lastModified,
            ),
          }),
        ),
      );

      dispatch(contentItemEditingSidebarSectionCleanedUp());
    } catch (error) {
      logErrorToMonitoringToolWithCustomMessage(
        'Error during scheduling unpublish of an item variant',
        error,
      );

      if (!itemEditingModalDialog.properties) {
        throw InvariantException(
          'No modal dialog properties when trying to open dialog after failed schedule-unpublish.',
        );
      }
      dispatch(
        scheduledUnpublishingFailed(
          ScheduleUnpublishErrorMessage,
          itemEditingModalDialog.properties,
        ),
      );
    }
  };
