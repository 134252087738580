import { WebSpotlightInItemEditingPreferencesStorageKey } from '../_shared/constants/localStorageKeys.ts';
import { jsonTryParse } from '../_shared/utils/jsonUtils.ts';
import { logErrorMessageToMonitoringTool } from '../_shared/utils/logError.ts';
import { isUuid } from '../_shared/utils/validation/typeValidators.ts';
import { WebSpotlightPreviewResolutionType } from '../applications/webSpotlight/models/webSpotlightPreviewResolutionType.ts';

export type WebSpotlightPreferences = {
  readonly customPreviewWidth: number | null;
  readonly customPreviewHeight: number | null;
  readonly customPreviewScale: number | null;
  readonly isPreviewAutoRefreshEnabled: boolean;
  readonly previewResolutionType: WebSpotlightPreviewResolutionType;
};

const defaultValue: WebSpotlightPreferences = {
  customPreviewWidth: null,
  customPreviewHeight: null,
  customPreviewScale: null,
  isPreviewAutoRefreshEnabled: true,
  previewResolutionType: WebSpotlightPreviewResolutionType.FitScreen,
};

export const webSpotlightInItemEditingPreferencesStorage = {
  get: (projectId: Uuid): WebSpotlightPreferences => {
    const parsedValue = jsonTryParse(self.localStorage.getItem(createStorageKey(projectId)) ?? '');
    return isWebSpotlightPreferences(parsedValue) ? parsedValue : defaultValue;
  },
  set: (
    projectId: Uuid,
    updater:
      | Partial<WebSpotlightPreferences>
      | ((prevState: WebSpotlightPreferences) => WebSpotlightPreferences),
  ): void => {
    const prevState = webSpotlightInItemEditingPreferencesStorage.get(projectId);
    const newValue =
      typeof updater === 'function' ? updater(prevState) : { ...prevState, ...updater };
    self.localStorage.setItem(createStorageKey(projectId), JSON.stringify(newValue));
  },
} as const;

const createStorageKey = (projectId: Uuid): string => {
  if (isUuid(projectId)) {
    return `${WebSpotlightInItemEditingPreferencesStorageKey}-${projectId}`;
  }

  logErrorMessageToMonitoringTool(`The projectId is not of type Uuid. The value: "${projectId}".`);
  return `${WebSpotlightInItemEditingPreferencesStorageKey}-unknown`;
};

const isWebSpotlightPreferences = (input: unknown): input is WebSpotlightPreferences => {
  return (
    !!input &&
    typeof input === 'object' &&
    'customPreviewHeight' in input &&
    (typeof input.customPreviewHeight === 'number' || input.customPreviewHeight === null) &&
    'customPreviewScale' in input &&
    (typeof input.customPreviewScale === 'number' || input.customPreviewScale === null) &&
    'customPreviewWidth' in input &&
    (typeof input.customPreviewWidth === 'number' || input.customPreviewWidth === null) &&
    'isPreviewAutoRefreshEnabled' in input &&
    typeof input.isPreviewAutoRefreshEnabled === 'boolean' &&
    'previewResolutionType' in input &&
    typeof input.previewResolutionType === 'string' &&
    (Object.values(WebSpotlightPreviewResolutionType) as ReadonlyArray<string>).includes(
      input.previewResolutionType,
    ) &&
    'spaceId' in input &&
    (typeof input.spaceId === 'string' || input.spaceId === null)
  );
};
