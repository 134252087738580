import { Dispatch, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { logErrorToMonitoringToolWithCustomMessage } from '../../../../../_shared/utils/logError.ts';
import { touchProjectsDependencies } from '../../../../../repositories/cacheKeys/projectCacheUtils.ts';

interface IDeps {
  readonly subscriptionRepository: {
    readonly acceptTermsOfService: (subscriptionId: Uuid) => Promise<void>;
  };
  readonly loadProjects: () => ThunkPromise;
  readonly loadUserProjectsInfo: () => ThunkPromise;
  readonly loadAdministratedSubscriptions: () => ThunkPromise;
}

export const acceptTermsOfServiceCreator = (deps: IDeps) => {
  const {
    subscriptionRepository,
    loadProjects,
    loadUserProjectsInfo,
    loadAdministratedSubscriptions,
  } = deps;

  return (subscriptionId: Uuid) =>
    async (dispatch: Dispatch): Promise<boolean> => {
      try {
        await subscriptionRepository.acceptTermsOfService(subscriptionId);

        touchProjectsDependencies();
        await Promise.all([
          dispatch(loadAdministratedSubscriptions()),
          dispatch(loadProjects()),
          dispatch(loadUserProjectsInfo()),
        ]);

        return true;
      } catch (e) {
        logErrorToMonitoringToolWithCustomMessage('Accepting the terms of service has failed.', e);

        return false;
      }
    };
};
