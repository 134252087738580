import { assert } from '@kontent-ai/utils';
import { logErrorMessageToMonitoringTool } from '../../../../../_shared/utils/logError.ts';
import { isString } from '../../../../../_shared/utils/stringUtils.ts';
import {
  ICoordinates,
  getCoordinatesFromDepth,
} from '../../../../richText/plugins/tables/api/depthCoordinatesConversion.ts';
import { BlockType } from '../../../../richText/utils/blocks/blockType.ts';
import { DiffDeps } from '../types/diffDeps.type.ts';
import { DiffType } from './DiffType.ts';
import {
  BlockBoundaryChar,
  DiffBlockTypeTable,
  FakeCharType,
  IDiffInputBlock,
  IDiffResultBlock,
  IInputChar,
  IOutputChar,
  IRawOutputBlock,
  ITextDiffPart,
  ZeroWidthSpaceChar,
  checkBlockTypeChanged,
  createAtomicOutputBlock,
  createOutputBlock,
  createOutputCharFromInputChar,
  createRawOutputBlock,
  getTextPartsFromInputBlock,
  isAtBlockEdge,
  isContentComponentBlock,
  isDiffTableBlock,
  isImageOrModularBlock,
  mergeDiffType,
} from './diffDataUtils.ts';
import {
  DiffTableResult,
  createEmptyTable,
  createMissingOutputCell,
  getTableSize,
  insertTableColumn,
  insertTableRow,
} from './diffTableUtils.ts';
import { convertFromPrivateUseArea } from './diffUnicodeUtils.ts';
import { diffWordsWithSpace } from './diffWordsWithSpace.ts';
import {
  validateAllContentProcessed,
  validateCustomBlockInputCharacter,
  validateUnchangedCharInputBlocks,
  validateUnchangedCharInputChars,
  validateUnchangedContentInput,
  validateUnchangedCustomBlockInput,
} from './richTextDiffValidationUtils.ts';
import { normalizeTextDiffResult } from './textDiffUtils.ts';

function createOutputBlockFromInputBlock(
  block: IDiffInputBlock,
  diffType: DiffType,
): IDiffResultBlock {
  const outputChars: Array<IOutputChar> = [];

  const inputChars = block.characters;
  for (let inputCharIndex = 0; inputCharIndex < inputChars.length; inputCharIndex++) {
    const inputChar = inputChars[inputCharIndex];
    assert(
      inputChar,
      () => `Character at index ${inputCharIndex} is falsy. Value: ${JSON.stringify(inputChar)}`,
    );

    if (!inputChar.fakeCharType) {
      outputChars.push(createOutputCharFromInputChar(inputChar, diffType));
    } else if (inputChar.fakeCharType === FakeCharType.Text && inputChar.fakedOriginalChars) {
      outputChars.push(
        ...inputChar.fakedOriginalChars.map((originalChar) =>
          createOutputCharFromInputChar(originalChar, diffType),
        ),
      );
      // Skip the rest of the fake chars representing the original content
      while (inputChars[inputCharIndex + 1]?.fakedOriginalChars === inputChar.fakedOriginalChars) {
        inputCharIndex++;
      }
    }
  }

  const blocks = block.blocks
    ? { blocks: block.blocks.map((b) => convertInputBlockToOutput(b, diffType)) }
    : undefined;

  return {
    characters: outputChars,
    depth: block.depth,
    type: block.type,
    data: block.data,
    diffType,
    ...blocks,
  };
}

const EmptyDiffInputBlock: IDiffInputBlock = {
  type: BlockType.Unstyled,
  key: '',
  characters: [],
  depth: 0,
};

async function createDiffForContainerBlock(
  oldBlock: IDiffInputBlock,
  newBlock: IDiffInputBlock,
): Promise<IDiffResultBlock> {
  // Fallback content to unstyled paragraph if child blocks not present
  const oldChildren = oldBlock.blocks ?? [
    { ...EmptyDiffInputBlock, characters: oldBlock.characters },
  ];
  const newChildren = newBlock.blocks ?? [
    { ...EmptyDiffInputBlock, characters: newBlock.characters },
  ];
  const resultChildren = await createDiffFromInputBlocks(oldChildren, newChildren, {
    calculateDiff: (oldTextParts, newTextParts) =>
      Promise.resolve(diffWordsWithSpace(oldTextParts, newTextParts)),
  });

  const resultDiffType =
    resultChildren.map((block) => block.diffType).reduce(mergeDiffType, null) ?? DiffType.None;
  const emptyResultBlock = createOutputBlockFromInputBlock(
    {
      ...newBlock,
      characters: [],
      blocks: undefined,
    },
    resultDiffType,
  );
  const resultBlock = {
    ...emptyResultBlock,
    blocks: resultChildren,
  };
  return resultBlock;
}

function mutateDiffTableResult(
  diffTableResult: DiffTableResult,
  coords: ICoordinates,
  newCellResult: IDiffResultBlock,
): void {
  const diffTableResultColumnY = diffTableResult[coords.y];
  if (diffTableResultColumnY) {
    diffTableResultColumnY[coords.x] = newCellResult;
  }
}

async function createTableDiff(
  oldCells: ReadonlyArray<IDiffInputBlock>,
  newCells: ReadonlyArray<IDiffInputBlock>,
): Promise<ReadonlyArray<IDiffResultBlock>> {
  const oldTableSize = getTableSize(oldCells);
  const newTableSize = getTableSize(newCells);
  const resultTable = createEmptyTable(newTableSize);

  const oldCellsByKey = new Map<string, IDiffInputBlock>(
    oldCells.map((block) => [block.key, block]),
  );

  // Add all cells in new as a base table
  const newCellPromises = newCells.map(async (newCell) => {
    const oldCell = oldCellsByKey.get(newCell.key);
    if (oldCell) {
      // Do regular diff of cells content for all cells present in both new and old
      oldCellsByKey.delete(newCell.key);

      const resultCell = await createDiffForContainerBlock(oldCell, newCell);
      return resultCell;
    }

    // All cells not present in old are added ones
    const addedCell = createOutputBlockFromInputBlock(newCell, DiffType.Added);
    return addedCell;
  });

  const newCellResults = await Promise.all(newCellPromises);
  newCellResults.forEach((newCellResult) => {
    const coords = getCoordinatesFromDepth(newCellResult.depth);
    mutateDiffTableResult(resultTable, coords, newCellResult);
  });

  // Add cells which are only in old (were removed)
  const remainingOldCells = Array.from(oldCellsByKey.values());
  const remainingOldCellsCoords = remainingOldCells.map((cell) =>
    getCoordinatesFromDepth(cell.depth),
  );

  remainingOldCells.forEach((oldCell) => {
    const coords = getCoordinatesFromDepth(oldCell.depth);
    // Extend the table if not large enough
    while (coords.y >= resultTable.length) {
      resultTable.push(Array(newTableSize.cols).fill(undefined));
    }
    while (resultTable[0] && coords.x >= resultTable[0].length) {
      resultTable.forEach((row) => row.push(undefined));
    }
    if (resultTable[coords.y]?.[coords.x]) {
      const removedCellsInRow = remainingOldCellsCoords.filter(
        (cellCoords) => cellCoords.y === coords.y,
      ).length;
      const removedCellsInColumn = remainingOldCellsCoords.filter(
        (cellCoords) => cellCoords.x === coords.x,
      ).length;

      // We estimate whether the row or column was likely deleted based on the ratio of deleted cells to total cells in given dimension
      // If we compared just the absolute number of cells, many removed columns in a table with only a few rows could indicate a removed rows which would be incorrect
      const addRow =
        removedCellsInRow / oldTableSize.cols > removedCellsInColumn / oldTableSize.rows;
      if (addRow) {
        insertTableRow(resultTable, coords.y);
      } else {
        insertTableColumn(resultTable, coords.x);
      }
    }
    const removedCell = createOutputBlockFromInputBlock(oldCell, DiffType.Removed);
    mutateDiffTableResult(resultTable, coords, removedCell);
  });

  return resultTable.flatMap((row, rowIndex) =>
    row.map(
      (cell, colIndex) =>
        cell ||
        createMissingOutputCell({
          x: colIndex,
          y: rowIndex,
        }),
    ),
  );
}

async function createTableOutputBlock(
  oldBlock: IDiffInputBlock | undefined,
  newBlock: IDiffInputBlock | undefined,
): Promise<IDiffResultBlock | null> {
  if (!newBlock || !newBlock.blocks || !newBlock.blocks.length) {
    if (!oldBlock) {
      return null;
    }
    return convertInputBlockToOutput(oldBlock, DiffType.Removed);
  }
  if (!oldBlock || !oldBlock.blocks || !oldBlock.blocks.length) {
    if (!newBlock) {
      return null;
    }
    return convertInputBlockToOutput(newBlock, DiffType.Added);
  }

  const oldCells = oldBlock.blocks;
  const newCells = newBlock.blocks;

  const cellsDiff = await createTableDiff(oldCells, newCells);
  const tableDiffType =
    cellsDiff.reduce(
      (result, cell) => mergeDiffType(result, cell.diffType ?? DiffType.None),
      null,
    ) ?? DiffType.None;

  return {
    characters: [],
    depth: newBlock.depth,
    type: DiffBlockTypeTable,
    data: newBlock.data,
    blocks: cellsDiff,
    diffType: tableDiffType,
  };
}

function convertInputBlockToOutput(block: IDiffInputBlock, diffType: DiffType): IDiffResultBlock {
  if (isImageOrModularBlock(block) || isContentComponentBlock(block)) {
    return createAtomicOutputBlock(block, diffType);
  }
  return createOutputBlockFromInputBlock(block, diffType);
}

async function createDiffFromInputChars(
  oldChars: ReadonlyArray<IInputChar>,
  newChars: ReadonlyArray<IInputChar>,
): Promise<ReadonlyArray<IOutputChar>> {
  const oldBlocks: ReadonlyArray<IDiffInputBlock> = [
    {
      characters: oldChars,
      depth: 0,
      key: '',
      type: BlockType.Unstyled,
    },
  ];
  const newBlocks: ReadonlyArray<IDiffInputBlock> = [
    {
      characters: newChars,
      depth: 0,
      key: '',
      type: BlockType.Unstyled,
    },
  ];

  const outputBlocks = await createDiffFromInputBlocks(oldBlocks, newBlocks, {
    calculateDiff: (oldParts, newParts) => Promise.resolve(diffWordsWithSpace(oldParts, newParts)),
  });

  return outputBlocks[0]?.characters ?? [];
}

export async function createDiffFromInputBlocks(
  oldBlocks: ReadonlyArray<IDiffInputBlock>,
  newBlocks: ReadonlyArray<IDiffInputBlock>,
  deps: DiffDeps,
): Promise<ReadonlyArray<IDiffResultBlock>> {
  if (oldBlocks.length === 0) {
    return newBlocks.map((block) => convertInputBlockToOutput(block, DiffType.Added));
  }

  if (newBlocks.length === 0) {
    return oldBlocks.map((block) => convertInputBlockToOutput(block, DiffType.Removed));
  }

  // Whole content is converted to text representation and the diff is done over text to avoid false positives due to different block keys or types
  const oldTextParts = oldBlocks.flatMap(getTextPartsFromInputBlock);
  const newTextParts = newBlocks.flatMap(getTextPartsFromInputBlock);

  const textDiffResult = await deps.calculateDiff(oldTextParts, newTextParts);
  const normalizedTextDiffResult = normalizeTextDiffResult(textDiffResult);

  // Set this variable to true to convert the text diff content to readable form for debugging
  const makeReadable = false;
  const buildDiffInput = makeReadable
    ? normalizedTextDiffResult.map((part) => ({
        ...part,
        value: convertFromPrivateUseArea(part.value),
      }))
    : normalizedTextDiffResult;

  const diffOutput = await buildDiffOutput(oldBlocks, newBlocks, buildDiffInput);

  return diffOutput;
}

type CustomBlockDiffBuilder = () => Promise<IDiffResultBlock | null>;

function getCustomBlockDiffBuilder(
  oldBlock: IDiffInputBlock | undefined,
  newBlock: IDiffInputBlock | undefined,
  diffType: DiffType,
): {
  readonly builder: CustomBlockDiffBuilder;
  readonly expectedFakeCharType: FakeCharType;
} | null {
  const primaryBlock = newBlock || oldBlock;
  if (!primaryBlock) {
    return null;
  }
  if (isImageOrModularBlock(primaryBlock) || isContentComponentBlock(primaryBlock)) {
    return {
      builder: async () => Promise.resolve(createAtomicOutputBlock(primaryBlock, diffType)),
      expectedFakeCharType: FakeCharType.Block,
    };
  }
  if (isDiffTableBlock(primaryBlock)) {
    return {
      builder: async () => await createTableOutputBlock(oldBlock, newBlock),
      expectedFakeCharType: FakeCharType.BlockContainer,
    };
  }
  return null;
}

const isTextCharacter = (ch: IInputChar | undefined): boolean =>
  !!ch && (!ch.fakeCharType || ch.fakeCharType === FakeCharType.Text);

function isInsideBlockText(block: IDiffInputBlock | undefined, position: number): boolean {
  if (!block) {
    return false;
  }
  const chars = block.characters;
  const charBefore = chars[position - 1];
  const charAfter = chars[position];

  return isTextCharacter(charBefore) && isTextCharacter(charAfter);
}

function getEntityCharacterCount(
  currentBlock: IDiffInputBlock,
  startIndex: number,
  entityKey: string,
): number {
  let index = startIndex;
  while (currentBlock.characters[index]?.entityKey === entityKey) {
    index++;
  }
  return index - startIndex;
}

export function getDiffTypeForUnchangedChar(
  blockTypeChanged: boolean,
  oldChar: IInputChar,
  newChar: IInputChar,
): DiffType {
  const styleChanged =
    blockTypeChanged ||
    (oldChar.inlineStyles &&
      newChar.inlineStyles &&
      !oldChar.inlineStyles.equals(newChar.inlineStyles));
  const addedOrRemovedEntity = !!oldChar.entityKey !== !!newChar.entityKey;

  return styleChanged || addedOrRemovedEntity ? DiffType.Changed : DiffType.None;
}

async function buildDiffOutput(
  oldBlocks: ReadonlyArray<IDiffInputBlock>,
  newBlocks: ReadonlyArray<IDiffInputBlock>,
  textDiffResult: ReadonlyArray<ITextDiffPart>,
): Promise<ReadonlyArray<IDiffResultBlock>> {
  if (oldBlocks.length === 0) {
    return newBlocks.map((block) => convertInputBlockToOutput(block, DiffType.Added));
  }

  if (newBlocks.length === 0) {
    return oldBlocks.map((block) => convertInputBlockToOutput(block, DiffType.Removed));
  }

  const outputBlocks: Array<IDiffResultBlock> = [];

  let blockTypeChanged = false;

  // The resulting diff is a merge of data from oldBlocks and newBlocks, based on text-based diff data
  // The process adjusts position in oldBlocks and newBlocks according to added / removed flags while crawling textDiffResult
  let positionInOldBlock = 0;
  let currentIndexOfOldBlock = 0;
  let currentOldBlock: IDiffInputBlock | undefined = oldBlocks[0];

  let positionInNewBlock = 0;
  let currentIndexOfNewBlock = 0;
  let currentNewBlock: IDiffInputBlock | undefined = newBlocks[0];

  blockTypeChanged = checkBlockTypeChanged(currentOldBlock, currentNewBlock);

  // Shortcuts for repetitive actions to avoid code bloating
  const moveToNextOldBlock = () => {
    currentIndexOfOldBlock++;
    currentOldBlock = oldBlocks[currentIndexOfOldBlock];
    positionInOldBlock = 0;
    blockTypeChanged = checkBlockTypeChanged(currentOldBlock, currentNewBlock);
  };

  const moveToNextNewBlock = () => {
    currentIndexOfNewBlock++;
    currentNewBlock = newBlocks[currentIndexOfNewBlock];
    positionInNewBlock = 0;
    blockTypeChanged = checkBlockTypeChanged(currentOldBlock, currentNewBlock);
  };

  // Merged output is collected as raw data, and when the process detects the end of the block, it pushes it to the output and starts new block
  let pendingOutputBlock: IRawOutputBlock = createRawOutputBlock();

  const finalizePendingOutputBlock = () => {
    // Only finalize old block in case it has some actual content, empty removed old blocks are processed specially through pendingEmptyOldBlock
    // Other blocks always yield new block as a source
    if (
      !pendingOutputBlock.sourceBlock ||
      (pendingOutputBlock.sourceBlockIsOld && !pendingOutputBlock.characters.length)
    ) {
      return;
    }
    const outputBlock = createOutputBlock(pendingOutputBlock, pendingOutputBlock.sourceBlock);
    outputBlocks.push(outputBlock);
    pendingOutputBlock = createRawOutputBlock();
  };

  let skipChars = 0;

  const processCustomInputBlock = async (
    charactersToSkip: number,
    buildCustomBlock: CustomBlockDiffBuilder,
    moveToNextBlock: () => void,
  ): Promise<void> => {
    finalizePendingOutputBlock();

    const customBlock = await buildCustomBlock();
    if (customBlock) {
      outputBlocks.push(customBlock);
    }
    pendingOutputBlock.sourceBlock = null;
    pendingOutputBlock.sourceBlockIsOld = false;

    // Skip special word in compared text representing block element (image, linked items)
    skipChars += charactersToSkip;

    moveToNextBlock();
  };

  const addOutputCharToPendingOutputBlock = (char: IOutputChar) => {
    pendingOutputBlock.characters.push(char);
  };

  const addCharToPendingOutputBlock = (char: IInputChar, diffType: DiffType) => {
    addOutputCharToPendingOutputBlock({
      inlineStyles: char.inlineStyles,
      entityKey: char.entityKey,
      char: char.char,
      diffType,
    });
  };

  const addAddedCharToPendingOutputBlock = (addedChar: IInputChar) => {
    // Add additional new line character to visually indicate added new line
    if (addedChar.char === '\n') {
      addCharToPendingOutputBlock({ ...addedChar, char: '↲' }, DiffType.Added);
    }
    addCharToPendingOutputBlock(addedChar, DiffType.Added);
  };

  const addRemovedCharToPendingOutputBlock = (removedChar: IInputChar) => {
    // Add new line character to visually indicate removed new line instead of physically making new line in output
    if (removedChar.char === '\n') {
      addCharToPendingOutputBlock({ ...removedChar, char: '↲' }, DiffType.Removed);
    } else {
      addCharToPendingOutputBlock(removedChar, DiffType.Removed);
    }
  };

  const addBlockBoundaryToPendingOutputBlock = (diffType: DiffType) => {
    addCharToPendingOutputBlock(BlockBoundaryChar, diffType);
    // Insert also space to allow line break in case multiple words are connected with just block boundaries
    addCharToPendingOutputBlock(ZeroWidthSpaceChar, diffType);
  };

  const updatePendingOutputBlockDiffType = (charDiffType: DiffType): void => {
    pendingOutputBlock.diffType = pendingOutputBlock.characters.length
      ? mergeDiffType(pendingOutputBlock.diffType, charDiffType)
      : charDiffType;
  };

  const addSubDiffOutputCharToPendingOutputBlock = (char: IOutputChar) => {
    const charDiffType = char.diffType ?? DiffType.None;

    // Sub-diff output char may be unchanged, but if block type has changed, we want to adopt the changed diff result from it as a default
    const defaultDiffType = blockTypeChanged ? DiffType.Changed : DiffType.None;
    const diffType = charDiffType === DiffType.None ? defaultDiffType : charDiffType;

    // We also want to propagate its diff type back to the output block because in case it is added/removed together with other block chars it may make the whole block the same
    updatePendingOutputBlockDiffType(diffType);
    addOutputCharToPendingOutputBlock({
      ...char,
      diffType,
    });
  };

  let pendingEmptyOldBlock: IDiffInputBlock | undefined = undefined;

  const diffResultIsFalsyMessage = (partIndex: number) => () =>
    `Text diff result at index ${partIndex} is falsy.`;
  const charIsNotAStringMessage = (charIndex: number) => () =>
    `Diff char at index ${charIndex} is not a string.`;

  // Crawl the diff data and merge output from input blocks
  for (let partIndex = 0; partIndex < textDiffResult.length; partIndex++) {
    const currentTextDiffPart = textDiffResult[partIndex];
    assert(!!currentTextDiffPart, diffResultIsFalsyMessage(partIndex));
    // Each character from diff part (text-based diff) is processed individually because the text-based diff is not aware of block boundaries
    for (let charIndex = 0; charIndex < currentTextDiffPart.value.length; charIndex++) {
      if (skipChars > 0) {
        skipChars--;
        continue;
      }

      // Finalize pending and adjust sources in case the processed content is beyond the current blocks
      const isStartOfNextOldBlock =
        !currentTextDiffPart.added &&
        currentOldBlock &&
        currentOldBlock.characters.length <= positionInOldBlock;
      const isStartOfNextNewBlock =
        !currentTextDiffPart.removed &&
        currentNewBlock &&
        currentNewBlock.characters.length <= positionInNewBlock;

      const diffChar = currentTextDiffPart.value[charIndex];
      assert(isString(diffChar), charIsNotAStringMessage(charIndex));

      if (isStartOfNextNewBlock) {
        if (isInsideBlockText(currentOldBlock, positionInOldBlock)) {
          // The old block had been split - Output paragraph separator to indicate it
          addBlockBoundaryToPendingOutputBlock(DiffType.Added);
        }
        finalizePendingOutputBlock();
        if (pendingEmptyOldBlock) {
          outputBlocks.push(
            createOutputBlockFromInputBlock(pendingEmptyOldBlock, DiffType.Removed),
          );
          pendingEmptyOldBlock = undefined;
        }
        if (isStartOfNextOldBlock) {
          moveToNextOldBlock();
        }
        moveToNextNewBlock();
      } else if (isStartOfNextOldBlock) {
        if (pendingOutputBlock.sourceBlockIsOld) {
          // Content from only old block - just output it
          finalizePendingOutputBlock();
          moveToNextOldBlock();
        } else {
          moveToNextOldBlock();
          const isAtNewBlockEdge =
            !currentNewBlock || isAtBlockEdge(currentNewBlock, positionInNewBlock);
          if (pendingEmptyOldBlock) {
            // Extra removed empty paragraph
            if (isAtNewBlockEdge) {
              // When at the edge of the new block, we can just place it to the output as removed
              // but we also need to flush current output to keep proper order
              finalizePendingOutputBlock();
              outputBlocks.push(
                createOutputBlockFromInputBlock(pendingEmptyOldBlock, DiffType.Removed),
              );
            } else {
              // When in the middle, we need to output it as a paragraph separator so we don't break the new block endings which have higher priority
              addBlockBoundaryToPendingOutputBlock(DiffType.Removed);
            }
          } else if (isAtNewBlockEdge && currentOldBlock?.characters.some(isTextCharacter)) {
            // If there are any characters in the new old block, finalize current new block so the block boundaries of removed blocks are honored
            finalizePendingOutputBlock();
          }
        }
        if (currentOldBlock) {
          const isEmptyOldBlock = currentOldBlock.characters.every((ch) => !isTextCharacter(ch));
          if (isEmptyOldBlock) {
            pendingEmptyOldBlock = currentOldBlock;
          } else if (isInsideBlockText(currentNewBlock, positionInNewBlock)) {
            // The old blocks have been merged - Output paragraph separator to indicate it
            addBlockBoundaryToPendingOutputBlock(DiffType.Removed);
          }
        }
      }

      // By default each character is processed as regular text in the content
      // Extra added block separators are processed as text (adjust input position) but not added to output
      // Block elements (image, linked items), are represented by special words in text, so position is adjusted, but not included into output
      if (currentTextDiffPart.added) {
        if (currentNewBlock) {
          const addedChar = currentNewBlock.characters[positionInNewBlock];
          if (addedChar) {
            const customBlockDiffBuilder = getCustomBlockDiffBuilder(
              undefined,
              currentNewBlock,
              DiffType.Added,
            );
            if (customBlockDiffBuilder) {
              validateCustomBlockInputCharacter(
                addedChar,
                DiffType.Added,
                customBlockDiffBuilder.expectedFakeCharType,
              );

              await processCustomInputBlock(
                currentNewBlock.characters.length - 1 - positionInNewBlock,
                customBlockDiffBuilder.builder,
                moveToNextNewBlock,
              );
              continue;
            }
            // Added text
            if (!pendingOutputBlock.sourceBlock || pendingOutputBlock.sourceBlockIsOld) {
              pendingOutputBlock.sourceBlock = currentNewBlock;
              pendingOutputBlock.sourceBlockIsOld = false;
            }

            updatePendingOutputBlockDiffType(DiffType.Added);

            if (!addedChar.fakeCharType) {
              addAddedCharToPendingOutputBlock(addedChar);
            } else if (addedChar.fakedOriginalChars) {
              // Entity represented by a fake char word, output its original content
              addedChar.fakedOriginalChars.forEach(addAddedCharToPendingOutputBlock);

              // Skip the rest of the entity fake chars
              if (addedChar.entityKey) {
                skipChars =
                  getEntityCharacterCount(
                    currentNewBlock,
                    positionInNewBlock,
                    addedChar.entityKey,
                  ) - 1;
                positionInNewBlock += skipChars;
              }
            }
          }
        } else {
          logErrorMessageToMonitoringTool(
            `Char added but corresponding new block is not available: ${diffChar}`,
          );
        }
        positionInNewBlock++;
      } else if (currentTextDiffPart.removed) {
        if (currentOldBlock) {
          const removedChar = currentOldBlock.characters[positionInOldBlock];
          if (removedChar) {
            const customBlockDiffBuilder = getCustomBlockDiffBuilder(
              currentOldBlock,
              undefined,
              DiffType.Removed,
            );
            if (customBlockDiffBuilder) {
              validateCustomBlockInputCharacter(
                removedChar,
                DiffType.Removed,
                customBlockDiffBuilder.expectedFakeCharType,
              );

              await processCustomInputBlock(
                currentOldBlock.characters.length - 1 - positionInOldBlock,
                customBlockDiffBuilder.builder,
                moveToNextOldBlock,
              );

              pendingEmptyOldBlock = undefined;
              continue;
            }
            if (isTextCharacter(removedChar)) {
              // Removed text - Only process removed content for real characters
              // Empty removed blocks and their respective fake characters are handled with pendingEmptyOldBlock
              if (!pendingOutputBlock.sourceBlock) {
                pendingOutputBlock.sourceBlock = currentOldBlock;
                pendingOutputBlock.sourceBlockIsOld = true;
              }

              updatePendingOutputBlockDiffType(DiffType.Removed);

              if (!removedChar.fakeCharType) {
                addRemovedCharToPendingOutputBlock(removedChar);
              } else if (removedChar.fakedOriginalChars) {
                // Entity represented by a fake char word, output its original content
                removedChar.fakedOriginalChars.forEach(addRemovedCharToPendingOutputBlock);

                // Skip the rest of the entity fake chars
                if (removedChar.entityKey) {
                  skipChars =
                    getEntityCharacterCount(
                      currentOldBlock,
                      positionInOldBlock,
                      removedChar.entityKey,
                    ) - 1;
                  positionInOldBlock += skipChars;
                }
              }

              pendingEmptyOldBlock = undefined;
            }
          }
        } else {
          logErrorMessageToMonitoringTool(
            `Char removed but corresponding old block is not available: ${diffChar}`,
          );
        }
        positionInOldBlock++;
      } else {
        validateUnchangedCharInputBlocks(currentNewBlock, currentOldBlock, diffChar);

        if (currentOldBlock && currentNewBlock) {
          const oldChar: IInputChar | undefined = currentOldBlock.characters[positionInOldBlock];
          const newChar: IInputChar | undefined = currentNewBlock.characters[positionInNewBlock];

          validateUnchangedCharInputChars(newChar, oldChar, diffChar);

          if (oldChar && newChar) {
            const customBlockDiffBuilder = getCustomBlockDiffBuilder(
              currentOldBlock,
              currentNewBlock,
              DiffType.None,
            );
            if (customBlockDiffBuilder) {
              validateUnchangedCustomBlockInput(
                customBlockDiffBuilder.expectedFakeCharType,
                currentOldBlock,
                currentNewBlock,
                oldChar,
                newChar,
              );

              await processCustomInputBlock(
                currentNewBlock.characters.length - 1 - positionInNewBlock,
                customBlockDiffBuilder.builder,
                () => {
                  moveToNextOldBlock();
                  moveToNextNewBlock();
                },
              );
              pendingEmptyOldBlock = undefined;
              continue;
            }
            validateUnchangedContentInput(oldChar, newChar);

            // Unchanged text but with possible style change
            if (!pendingOutputBlock.sourceBlock || pendingOutputBlock.sourceBlockIsOld) {
              pendingOutputBlock.sourceBlock = currentNewBlock;
              pendingOutputBlock.sourceBlockIsOld = false;
            }

            if (!newChar.fakeCharType) {
              const diffType = getDiffTypeForUnchangedChar(blockTypeChanged, oldChar, newChar);
              updatePendingOutputBlockDiffType(diffType);
              addCharToPendingOutputBlock(newChar, diffType);
            } else if (newChar.fakedOriginalChars && oldChar.fakedOriginalChars) {
              // Entity didn't change but we still want to make diff on its original contents which could have changed
              const subDiffOutputChars = await createDiffFromInputChars(
                oldChar.fakedOriginalChars,
                newChar.fakedOriginalChars,
              );
              subDiffOutputChars.forEach(addSubDiffOutputCharToPendingOutputBlock);

              // Skip the rest of the entity fake chars
              if (newChar.entityKey) {
                skipChars =
                  getEntityCharacterCount(currentNewBlock, positionInNewBlock, newChar.entityKey) -
                  1;
                positionInOldBlock += skipChars;
                positionInNewBlock += skipChars;
              }
            } else {
              updatePendingOutputBlockDiffType(blockTypeChanged ? DiffType.Changed : DiffType.None);
            }

            // Only reset pending empty old block with first char to prevent omitting more instances of empty old blocks
            if (!positionInOldBlock) {
              pendingEmptyOldBlock = undefined;
            }
          }
        }
        positionInOldBlock++;
        positionInNewBlock++;
      }
    }
  }

  validateAllContentProcessed(
    currentOldBlock,
    positionInOldBlock,
    currentNewBlock,
    positionInNewBlock,
  );

  // Finalize last block if anything is remaining, type of new block has higher priority
  finalizePendingOutputBlock();

  // If there is pending empty removed block, add it as well to not miss out anything
  if (pendingEmptyOldBlock) {
    outputBlocks.push(createOutputBlockFromInputBlock(pendingEmptyOldBlock, DiffType.Removed));
    pendingEmptyOldBlock = undefined;
  }

  return outputBlocks;
}
