import { parseError } from '@kontent-ai/errors';
import { assert, makeCancellablePromise, swallowCancelledPromiseError } from '@kontent-ai/utils';
import {
  HttpError,
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
  IHttpConnectionOptions,
  LogLevel,
} from '@microsoft/signalr';
import { injectedAccessTokenStorage } from '../../../localStorages/injectedAccessTokenStorage.ts';
import { getWebAuth, isTokenCloseToExpiration } from '../../utils/authorization/authorization.ts';
import { getMilliseconds } from '../../utils/dateTime/timeUtils.ts';
import {
  logErrorMessageToMonitoringTool,
  logErrorToMonitoringToolWithCustomMessage,
} from '../../utils/logError.ts';
import { getUrlFactory } from '../../utils/urlFactory.ts';
import {
  ISignalRConnectionCallbacks,
  Notifications,
  ServerMethods,
  SignalRConnectionFailReason,
} from './signalRClient.type.ts';

// Extend client's keepAlive interval not to overload our BE.
const keepAliveInMs = getMilliseconds({ minutes: 1 });

// Extend client's serverTimeout so our BE can ping less often.
const serverTimeoutInMs = getMilliseconds({ seconds: 90 });

// Gradually back off reconnect attempts in a single reconnect sequence.
// If the last interval is reached it's repeated until reconnect is successful.
const reconnectBackoffIntervals = [
  getMilliseconds({ seconds: 2 }),
  getMilliseconds({ seconds: 10 }),
  getMilliseconds({ seconds: 30 }),
  getMilliseconds({ minutes: 1 }),
];

// Jitter is added to each reconnection attempt to help distribute the negotiation requests over time,
// reducing the likelihood of overwhelming the backend.
// The number represents the maximum percentage of the base interval that can be added as jitter.
const maxBackoffIntervalJitter = 0.2;

// Limit the time forceInvoke waits for the connection to start
// to avoid useless waiting for the server handshake timeout (15s by default).
const forceInvokeTimeoutInMs = getMilliseconds({ seconds: 5 });

// Give up forceInvoke if too many consecutive connection attempts timed out.
const tooManyStartTimeoutsThreshold = 3;

export class SignalRClient {
  #connection: HubConnection | null = null;
  #callbacks: ISignalRConnectionCallbacks | null = null;
  #scheduledReconnectTimeoutId?: number;
  #pendingStartPromise: Promise<void> | null = null;
  #consecutiveStartTimeoutCount: number = 0;
  #reconnectIntervalGenerator = createReconnectIntervalGenerator();

  public connect(projectId: Uuid, connectionCallbacks: ISignalRConnectionCallbacks): void {
    if (this.#connection) {
      throw new Error(
        'You are about to create new SignalR connection while another active connection exists. Disconnect existing connection before creating a new one.',
      );
    }

    this.#callbacks = connectionCallbacks;

    const url = `${getUrlFactory().getDraftSignalRUrl()}?projectId=${projectId}`;
    const options: IHttpConnectionOptions = {
      accessTokenFactory: getAuthToken,
    };

    this.#connection = new HubConnectionBuilder()
      .withUrl(url, options)
      .configureLogging(LogLevel.Error)
      .build();

    this.#connection.keepAliveIntervalInMilliseconds = keepAliveInMs;
    this.#connection.serverTimeoutInMilliseconds = serverTimeoutInMs;

    this.#connection.onreconnected(() => this.#callbacks?.onConnected());

    const onNotify = <TNotificationType extends keyof Notifications = never>(
      type: TNotificationType,
      args: Notifications[TNotificationType]['payload'],
    ) => this.#callbacks?.onNotified(type, args);
    this.#connection.on('notify', onNotify);

    this.#connection.onclose(() => this.#scheduleReconnect());

    this.#startConnection();
  }

  public disconnect(): void {
    this.#connection?.stop();
    this.#connection = null;
  }

  #startConnection(): Promise<void> {
    if (this.#pendingStartPromise) {
      return this.#pendingStartPromise;
    }

    if (this.#connection?.state === HubConnectionState.Disconnected) {
      self.clearTimeout(this.#scheduledReconnectTimeoutId);

      this.#pendingStartPromise = this.#connection
        .start()
        .then(() => this.#onConnected())
        .catch((e) => this.#onConnectingFailed(e))
        .finally(() => {
          this.#pendingStartPromise = null;
        });

      return this.#pendingStartPromise;
    }

    return Promise.resolve();
  }

  #onConnected(): void {
    this.#consecutiveStartTimeoutCount = 0;
    this.#reconnectIntervalGenerator = createReconnectIntervalGenerator();
    this.#callbacks?.onConnected();
  }

  #onConnectingFailed(e: any): void {
    const isTimeout = isConnectionNotFoundError(e);

    if (isTimeout) {
      this.#consecutiveStartTimeoutCount++;

      if (this.#consecutiveStartTimeoutCount === tooManyStartTimeoutsThreshold) {
        logErrorMessageToMonitoringTool(
          `SignalRClient reached too many consecutive start timeouts (${tooManyStartTimeoutsThreshold}).`,
        );
      }

      const failReason =
        this.#consecutiveStartTimeoutCount >= tooManyStartTimeoutsThreshold
          ? SignalRConnectionFailReason.TooManyStartTimeouts
          : SignalRConnectionFailReason.StartTimeout;

      this.#callbacks?.onConnectingFailed(failReason);
    } else {
      this.#consecutiveStartTimeoutCount = 0;
      this.#callbacks?.onConnectingFailed(SignalRConnectionFailReason.Unspecified);
    }

    this.#scheduleReconnect();
  }

  #scheduleReconnect(): void {
    self.clearTimeout(this.#scheduledReconnectTimeoutId);
    const scheduleInterval = this.#reconnectIntervalGenerator.next().value;

    if (scheduleInterval) {
      this.#scheduledReconnectTimeoutId = self.setTimeout(() => {
        this.#startConnection();
      }, scheduleInterval);
    }
  }

  #isConnected(): boolean {
    return this.#connection?.state === HubConnectionState.Connected;
  }

  public async invoke<TServerMethod extends keyof ServerMethods = never>(
    args: ServerMethods[TServerMethod]['arguments'],
  ): Promise<ServerMethods[TServerMethod]['returnType'] | null> {
    assert(this.#connection, () => 'SignalRClient not initialized.');

    if (this.#isConnected()) {
      return this.#connection.invoke(args.methodName, args.payload).catch((e) => {
        if (shouldLogInvocationError(parseError(e))) {
          logErrorToMonitoringToolWithCustomMessage(
            `SignalRClient failed to invoke '${args.methodName}' method on remote server.`,
            e,
          );
        }
        return null;
      });
    }

    return Promise.resolve(null);
  }

  public async forceInvoke<TServerMethod extends keyof ServerMethods = never>(
    args: ServerMethods[TServerMethod]['arguments'],
  ): Promise<ServerMethods[TServerMethod]['returnType'] | null> {
    if (this.#consecutiveStartTimeoutCount >= tooManyStartTimeoutsThreshold) {
      return null;
    }

    const forcedInvokePromise = makeCancellablePromise(() => this.#startConnection())
      .then(() => this.invoke(args))
      .catch(swallowCancelledPromiseError);

    self.setTimeout(() => forcedInvokePromise.cancel(), forceInvokeTimeoutInMs);
    return (await forcedInvokePromise) ?? null;
  }

  public send<TServerMethod extends keyof ServerMethods = never>(
    args: ServerMethods[TServerMethod]['arguments'],
  ): void {
    assert(this.#connection, () => 'SignalRClient not initialized.');

    if (this.#isConnected()) {
      this.#connection.send(args.methodName, args.payload).catch((e) => {
        if (shouldLogInvocationError(parseError(e))) {
          logErrorToMonitoringToolWithCustomMessage(
            `SignalRClient failed to send "${args.methodName}" method to remote server.`,
            e,
          );
        }
      });
    }
  }
}

function shouldLogInvocationError(error: Error): boolean {
  return !isClientOfflineError(error) && !isAccessDeniedError(error);
}

function getIntervalWithJitter(baseInterval: number | undefined): number {
  let intervalWithJitter = 0;
  if (baseInterval) {
    intervalWithJitter = Math.floor(
      baseInterval + Math.random() * baseInterval * maxBackoffIntervalJitter,
    );
  }

  return intervalWithJitter;
}

function* createReconnectIntervalGenerator(): Generator<number | undefined> {
  let index = 0;

  while (true) {
    yield getIntervalWithJitter(reconnectBackoffIntervals.at(index));
    if (index < reconnectBackoffIntervals.length - 1) {
      index++;
    }
  }
}

function getAuthToken(): Promise<string> {
  const injectedToken = injectedAccessTokenStorage.load();
  if (!!injectedToken && !isTokenCloseToExpiration(injectedToken)) {
    return Promise.resolve(injectedToken);
  }

  return new Promise((resolve, reject) => {
    getWebAuth().checkSession({ usePostMessage: true }, (error, authResult) => {
      if (error || !authResult) {
        reject(error);
      } else {
        resolve(authResult.accessToken);
      }
    });
  });
}

function isHttpError(arg: any): arg is HttpError {
  return arg !== null && typeof arg === 'object' && arg instanceof HttpError;
}

const isConnectionNotFoundError = (e: any) => isHttpError(e) && e.statusCode === 404;
const isAccessDeniedError = (e: any) => isHttpError(e) && e.statusCode === 403;
const isClientOfflineError = (e: Error) => !e.message.toLowerCase().includes('failed to fetch');
