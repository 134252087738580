import { swallowXMLHttpRequestWithStatus } from '@kontent-ai/errors';
import { useCallback, useSyncExternalStore } from 'react';
import { useTranslationTaskManager } from '../../../contexts/TranslationTaskManagerProvider.tsx';
import { repositoryCollection } from '../../../repositories/repositories.ts';
import { VariantTranslationTask } from '../../../utils/translations/VariantTranslationTask.type.ts';
import {
  ElementTranslationStatus,
  VariantTranslationStatus,
} from '../../../utils/translations/variantTranslationStatus.ts';
import { getVariantTaskFromServerModel } from '../../../utils/translations/variantTranslationTaskConversionUtils.ts';
import { makeVariantTranslationTaskId } from '../../../utils/translations/variantTranslationTaskId.ts';
import { useAiSessionId } from './useAiSessionId.ts';

type AiVariantTranslation = {
  readonly cancelTranslation: () => Promise<void>;
  readonly fetchLatestProgress: () => Promise<VariantTranslationTask | null>;
  readonly retryTranslation: () => Promise<void>;
  readonly startTranslation: (sourceLanguageId: Uuid, userId: string) => Promise<void>;
  readonly translationStartedBy: string | null;
  readonly translationStatus: VariantTranslationStatus | null;
  readonly translationStatusPerElement: ReadonlyMap<Uuid, ElementTranslationStatus> | null;
  readonly completedAt: DateTimeStamp | null;
  readonly sourceLanguageId: Uuid | null;
};

export const useVariantTranslation = (
  projectEnvironmentId: Uuid,
  itemId: Uuid,
  variantId: Uuid,
): AiVariantTranslation => {
  const { createTask, cancelTask, getTask, subscribe, messageReceived } =
    useTranslationTaskManager();
  const { aiSessionId } = useAiSessionId();
  const taskId = makeVariantTranslationTaskId(projectEnvironmentId, itemId, variantId);

  const fetch = useCallback(async () => {
    const translationTaskServerModel = await repositoryCollection.translateVariantRepository
      .getTranslationStatus(projectEnvironmentId, itemId, variantId)
      .catch((error) => swallowXMLHttpRequestWithStatus(error, 404));

    if (!translationTaskServerModel) {
      return null;
    }

    const variantData = getVariantTaskFromServerModel(
      projectEnvironmentId,
      translationTaskServerModel,
    );
    return messageReceived(variantData);
  }, [itemId, messageReceived, projectEnvironmentId, variantId]);

  const start = useCallback(
    async (sourceLanguageId: Uuid, userId: string) => {
      await createTask(
        taskId,
        {
          aiSessionId,
          itemId,
          projectEnvironmentId,
          sourceLanguageId,
          createdBy: userId,
          variantId,
        },
        async (params) =>
          await repositoryCollection.translateVariantRepository.startTranslation(
            projectEnvironmentId,
            itemId,
            variantId,
            params,
          ),
      );
    },
    [aiSessionId, createTask, itemId, projectEnvironmentId, taskId, variantId],
  );

  const cancel = useCallback(async () => {
    await cancelTask(
      taskId,
      async () =>
        await repositoryCollection.translateVariantRepository.cancelTranslation(
          projectEnvironmentId,
          itemId,
          variantId,
        ),
    );
  }, [cancelTask, itemId, projectEnvironmentId, taskId, variantId]);

  const translationTask = useSyncExternalStore(subscribe, () => {
    return getTask(taskId) ?? null;
  });

  const retry = useCallback(async () => {
    if (translationTask) {
      await start(translationTask.params.sourceLanguageId, translationTask.params.createdBy);
    }
  }, [start, translationTask]);

  return {
    cancelTranslation: cancel,
    fetchLatestProgress: fetch,
    retryTranslation: retry,
    startTranslation: start,
    translationStartedBy: translationTask?.params.createdBy ?? null,
    translationStatusPerElement: translationTask?.statusPerElement ?? null,
    completedAt: translationTask?.completedAt ?? null,
    translationStatus: translationTask?.overallProgress ?? null,
    sourceLanguageId: translationTask?.params.sourceLanguageId ?? null,
  };
};
