import { Box } from '@kontent-ai/component-library/Box';
import { Stack } from '@kontent-ai/component-library/Stack';
import {
  Spacing,
  spacingMainLayoutLeft,
  spacingMainLayoutRight,
  spacingMainLayoutTop,
} from '@kontent-ai/component-library/tokens';
import { not } from '@kontent-ai/utils';
import { useContext, useState } from 'react';
import { FilterButton } from '../../../../_shared/components/FilterButton.tsx';
import { HtmlPageTitle } from '../../../../_shared/components/HtmlPageTitle.tsx';
import { AppNames } from '../../../../_shared/constants/applicationNames.ts';
import { NotificationBar } from '../../../../_shared/containers/NotificationBar.tsx';
import { LoadingStatus } from '../../../../_shared/models/LoadingStatusEnum.ts';
import { ContentItemFilterOrigin } from '../../../../_shared/models/events/ContentItemFilterEventData.type.ts';
import {
  DataUiAppName,
  getDataUiAppNameAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ContentItemFilter } from '../../../contentInventory/content/features/ListingFilter/containers/ContentItemFilter.tsx';
import { EnsureInventoryRelatedData } from '../../../contentInventory/content/features/ListingFilter/containers/EnsureInventoryRelatedData.tsx';
import { useIsRefinedNavigationFeatureEnabled } from '../../../refinedNavigation/contexts/RefinedNavigationContext.tsx';
import { CategoryItemsListingModal } from '../containers/CategoryItemsListingModal.tsx';
import { ProjectOverviewPageContextProvider } from '../containers/ProjectOverviewPageContext.tsx';
import {
  ProjectOverviewPageContent,
  ProjectOverviewPageType,
} from './ProjectOverviewPageContent.tsx';
import { ProjectOverviewPageContext } from './ProjectOverviewPageContext.tsx';

const ProjectOverviewModal = () => {
  const { isVisible, dismissCategory, viewedCategory, listingSelectedWorkflowStep } = useContext(
    ProjectOverviewPageContext,
  );

  return (
    <CategoryItemsListingModal
      isOpen={isVisible}
      listingSelectedWorkflowStep={listingSelectedWorkflowStep}
      onCancel={dismissCategory}
      viewedCategory={viewedCategory}
    />
  );
};

type Props = {
  readonly listingItemsLoadingStatus: LoadingStatus;
  readonly onClearFilter: () => void;
  readonly onFilterChange: () => void;
  readonly onOpenCreateNewItemDialog: () => void;
  readonly projectOverviewPageType: ProjectOverviewPageType;
};

export const ProjectOverviewPage = (props: Props) => {
  const isRefinedNavigationFeatureEnabled = useIsRefinedNavigationFeatureEnabled();

  return (
    <Box
      flexGrow={1}
      overflowY="auto"
      paddingLeft={spacingMainLayoutLeft}
      paddingTop={isRefinedNavigationFeatureEnabled ? spacingMainLayoutTop : undefined}
      paddingRight={isRefinedNavigationFeatureEnabled ? spacingMainLayoutRight : undefined}
    >
      <div className="canvas" {...getDataUiAppNameAttribute(DataUiAppName.ProjectOverview)}>
        <div className="canvas__workspace">
          <HtmlPageTitle appName={AppNames.ProjectOverview} />
          <EnsureInventoryRelatedData>
            {!isRefinedNavigationFeatureEnabled && (
              <ContentItemFilter
                clearFilter={props.onClearFilter}
                origin={ContentItemFilterOrigin.ProjectOverview}
              />
            )}
            <ProjectOverviewPageContextProvider>
              <div className="canvas__content">
                <div className="canvas__notifications">
                  <NotificationBar />
                </div>
                <Box
                  className="canvas__content-pane"
                  padding={isRefinedNavigationFeatureEnabled ? 0 : undefined}
                >
                  {isRefinedNavigationFeatureEnabled && (
                    <FilterArea clearFilter={props.onClearFilter} />
                  )}
                  <ProjectOverviewPageContent
                    onOpenCreateNewItemDialog={props.onOpenCreateNewItemDialog}
                    projectOverviewPageContentType={props.projectOverviewPageType}
                  />
                </Box>
              </div>
              <ProjectOverviewModal />
            </ProjectOverviewPageContextProvider>
          </EnsureInventoryRelatedData>
        </div>
      </div>
    </Box>
  );
};

const FilterArea = ({
  clearFilter,
}: {
  readonly clearFilter: () => void;
}) => {
  const [isFilterVisible, setIsFilterVisible] = useState(false);

  return (
    <Box paddingBottom={Spacing.L}>
      <Stack spacing={Spacing.None}>
        <Box css="align-self: start">
          <FilterButton isActive={isFilterVisible} onPress={() => setIsFilterVisible(not)} />
        </Box>
        {isFilterVisible && (
          <ContentItemFilter
            clearFilter={clearFilter}
            origin={ContentItemFilterOrigin.ProjectOverview}
            hideFilter={() => setIsFilterVisible(false)}
          />
        )}
      </Stack>
    </Box>
  );
};
