import { assert } from '@kontent-ai/utils';
import { logErrorMessageToMonitoringTool } from '../../../_shared/utils/logError.ts';
import { Capability } from '../../../_shared/utils/permissions/capability.ts';
import { IUserProjectInfoServerModel } from '../../../repositories/serverModels/UserServerModel.type.ts';

export interface IUserProjectInfo {
  readonly capabilities: ReadonlyArray<Capability>;
  readonly masterEnvironmentId: Uuid;
  readonly projectActivatedAt: DateTimeStamp;
  readonly projectContainerId: Uuid;
  readonly projectName: string;
  readonly projectId: Uuid;
  readonly environmentName: string;
  readonly subscriptionId: Uuid;
  readonly isSetUpCorrectly: boolean;
}

export const emptyUserProjectInfo: IUserProjectInfo = {
  capabilities: [],
  masterEnvironmentId: '',
  projectActivatedAt: '',
  projectName: '',
  projectId: '',
  environmentName: '',
  projectContainerId: '',
  subscriptionId: '',
  isSetUpCorrectly: true,
};

export function createUserProjectInfoDomainModel(
  serverModel: IUserProjectInfoServerModel,
): IUserProjectInfo {
  assert(!!serverModel, () => 'UserProjectInfo server model is falsy.');

  return {
    capabilities: translateCapabilities(serverModel),
    masterEnvironmentId: serverModel.masterProjectId ?? emptyUserProjectInfo.masterEnvironmentId,
    projectActivatedAt: serverModel.projectActivatedAt ?? emptyUserProjectInfo.projectActivatedAt,
    projectName: serverModel.projectContainerName ?? emptyUserProjectInfo.projectName,
    projectId: serverModel.projectId ?? emptyUserProjectInfo.projectId,
    projectContainerId: serverModel.projectContainerId ?? emptyUserProjectInfo.projectContainerId,
    environmentName: serverModel.projectName ?? emptyUserProjectInfo.environmentName,
    subscriptionId: serverModel.subscriptionId ?? emptyUserProjectInfo.subscriptionId,
    isSetUpCorrectly: serverModel.isSetUpCorrectly ?? emptyUserProjectInfo.isSetUpCorrectly,
  };
}

function translateCapabilities(rawProject: IUserProjectInfoServerModel): ReadonlyArray<Capability> {
  const rawPermissions = rawProject.capabilities ? rawProject.capabilities : [];
  return rawPermissions.map((permission) => {
    const capabilityKey = Object.keys(Capability).find((key) => Capability[key] === permission);
    if (!capabilityKey || capabilityKey === Capability.UNKNOWN) {
      logErrorMessageToMonitoringTool(
        `Cannot translate permission '${permission}' to a capability. Unknown permission. Recently added maybe?`,
      );
    }

    return capabilityKey ? Capability[capabilityKey] : Capability.UNKNOWN;
  });
}
