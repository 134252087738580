import { InvariantException } from '@kontent-ai/errors';
import { Dispatch, GetState, ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { logErrorToMonitoringToolWithCustomMessage } from '../../../../../../_shared/utils/logError.ts';
import { IContentItemWithVariantServerModel } from '../../../../../../repositories/serverModels/INewContentItemServerModel.ts';
import {
  ServerApiErrorCode,
  tryParseApiError,
} from '../../../../../../repositories/serverModels/ServerApiError.ts';
import { hideValidationResults } from '../../../../actions/contentActions.ts';
import {
  ContentItemEditing_CreateNewVersion_Failed,
  ContentItemEditing_CreateNewVersion_Finished,
  ContentItemEditing_CreateNewVersion_Started,
} from '../../constants/contentItemEditingActionTypes.ts';
import { IParsedItemVariant } from '../../utils/parseContentItem.ts';
import {
  ItemWithVariantAndFilterAndDataPayload,
  contentItemEditingSidebarSectionCleanedUp,
} from '../contentItemEditingActions.ts';
import { ILoadRelatedContentItemElementsDataAction } from './loadRelatedContentItemElementsData.ts';

const createNewVersionStarted = () =>
  ({
    type: ContentItemEditing_CreateNewVersion_Started,
  }) as const;

const createNewVersionFinished = (payload: ItemWithVariantAndFilterAndDataPayload) =>
  ({
    type: ContentItemEditing_CreateNewVersion_Finished,
    payload,
  }) as const;

const createNewVersionFailed = (apiError: ServerApiErrorCode | undefined) =>
  ({
    type: ContentItemEditing_CreateNewVersion_Failed,
    payload: {
      apiError,
    },
  }) as const;

export type CreateNewVersionActionsType = ReturnType<
  typeof createNewVersionStarted | typeof createNewVersionFinished | typeof createNewVersionFailed
>;

interface ICreateNewVersionActionDependencies {
  readonly contentItemRepository: {
    readonly createNewVersion: (
      contentItemId: Uuid,
      variantId: Uuid,
    ) => Promise<IContentItemWithVariantServerModel>;
  };
  readonly loadContentItemUsage: (contentItemId: Uuid, variantId: Uuid) => ThunkPromise;
  readonly loadRelatedContentItemElementsData: ILoadRelatedContentItemElementsDataAction;
  readonly parseContentItemVariant: (
    contentItemWithVariant: IContentItemWithVariantServerModel,
  ) => IParsedItemVariant;
}

export const createNewVersionActionCreator =
  (deps: ICreateNewVersionActionDependencies) =>
  (onNewVersionCreated?: () => void): ThunkPromise =>
  async (dispatch: Dispatch, getState: GetState) => {
    dispatch(createNewVersionStarted());
    const {
      contentApp: { editedContentItemVariant },
      data: {
        listingContentItems: { usedSearchMethod },
      },
    } = getState();

    if (!editedContentItemVariant) {
      throw InvariantException(
        'createNewVersionActionCreator.ts: editedContentItemVariant is falsy',
      );
    }
    const { itemId, variantId } = editedContentItemVariant.id;

    try {
      const itemWithVariant = await deps.contentItemRepository.createNewVersion(itemId, variantId);
      const loadContentItemUsagePromise = dispatch(deps.loadContentItemUsage(itemId, variantId));

      const variantData = deps.parseContentItemVariant(itemWithVariant);
      onNewVersionCreated?.();

      await loadContentItemUsagePromise;
      await dispatch(
        deps.loadRelatedContentItemElementsData(
          itemId,
          variantId,
          variantData.editedContentItemVariantElements,
          null,
        ),
      );
      const compiledVariant = {
        ...variantData,
        editedContentItemVariantElements: variantData.editedContentItemVariantElements,
      };
      dispatch(
        createNewVersionFinished({
          itemWithVariant,
          itemVariantData: compiledVariant,
          filter: getState().contentApp.listingUi.filter,
          orderBy: getState().contentApp.listingUi.orderBy,
          usedSearchMethod,
        }),
      );
      dispatch(hideValidationResults());
      dispatch(contentItemEditingSidebarSectionCleanedUp());
    } catch (error) {
      const apiError = tryParseApiError(error);
      dispatch(createNewVersionFailed(apiError?.code));
      logErrorToMonitoringToolWithCustomMessage('Creating new version failed.', error);
    }
  };
