import { Collection } from '@kontent-ai/utils';
import { useHistory } from 'react-router';
import { Loader } from '../../../../_shared/components/Loader.tsx';
import { HandleUnsavedFormOnNavigation } from '../../../../_shared/containers/HandleUnsavedFormOnNavigation.tsx';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { useThunkPromise } from '../../../../_shared/hooks/useThunkPromise.ts';
import { isAssetTypeEnabled as isAssetTypeEnabledSelector } from '../../../../_shared/selectors/enhancedAssetManagement.ts';
import {
  getCurrentProjectId,
  getCurrentProjectPlan,
  getCurrentProjectSubscription,
} from '../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { isCollectionsConfigurationVisible } from '../../../../_shared/utils/collections/isCollectionsConfigurationVisible.ts';
import { logErrorToMonitoringTool } from '../../../../_shared/utils/logError.ts';
import { createNewRoleLink } from '../../../../_shared/utils/routing/projectSubscriptionRoutingUtils.ts';
import { isSitemapEnabled as isSitemapEnabledUtil } from '../../../contentModels/sitemap/utils/sitemapUtils.ts';
import {
  areSpacesEnabledForCurrentProject,
  isAuditLogEnabledForCurrentProjectPlan,
} from '../../utils/allowedFeaturesUtils.ts';
import {
  roleValidationFailed,
  roleValidationFailedNoSelectedCapabilities,
} from '../actions/rolesActions.ts';
import { initRoleEditor, updateRole } from '../actions/thunkRolesActions.ts';
import { RoleEditor as RoleEditingComponent } from '../components/RoleEditor.tsx';
import { RoleEditorStatus } from '../models/RoleEditorStatus.ts';
import { getCustomRolesLimitInfo } from '../selectors/customRoleLimit.ts';
import { isRoleUsedByCurrentUser } from '../selectors/isRoleUsed.ts';
import { areCustomRolesOverLimit } from '../utils/customRolesUtils.ts';
import { getUpdateServerModelFromEditedRole } from '../utils/getUpdateServerModelFromEditedRole.ts';
import { hasUnsavedChanges, isLoaded } from '../utils/roleEditorStatusUtils.ts';
import { isRoleValid } from '../utils/validationUtils.ts';

type Props = {
  readonly projectId: Uuid;
  readonly roleId: Uuid;
  readonly withSubscriptionIdInRoute?: Uuid;
};

export const RoleEditor = ({ projectId, roleId, withSubscriptionIdInRoute }: Props) => {
  const history = useHistory();
  const [isInitThunkDone] = useThunkPromise(initRoleEditor, history, roleId);

  const status = useSelector((s) => s.rolesApp.editorUi.status);
  const isReadonly = useSelector((s) => {
    const areRolesOverLimit = areCustomRolesOverLimit(getCustomRolesLimitInfo(s));
    return areRolesOverLimit || s.rolesApp.editorUi.editedRole.isReadonly;
  });
  const isSitemapEnabled = useSelector((s) =>
    isSitemapEnabledUtil(getCurrentProjectSubscription(s)),
  );
  const isAuditLogEnabled = useSelector((state) =>
    isAuditLogEnabledForCurrentProjectPlan(getCurrentProjectPlan(state)),
  );
  const areCollectionsEnabled = useSelector((s) =>
    isCollectionsConfigurationVisible(
      getCurrentProjectPlan(s),
      Collection.getValues(s.data.collections.byId),
    ),
  );
  const isAssetTypeEnabled = useSelector((s) =>
    isAssetTypeEnabledSelector(getCurrentProjectPlan(s), s.data.assetTypes.defaultAssetType),
  );
  const areSpacesEnabled = useSelector((state) =>
    areSpacesEnabledForCurrentProject(getCurrentProjectPlan(state)),
  );
  const contentTypesById = useSelector((s) => s.data.contentTypes.byId);
  const editedRole = useSelector((s) => s.rolesApp.editorUi.editedRole);
  const roleToUpdate = getUpdateServerModelFromEditedRole(editedRole, contentTypesById);
  const roleIsUsedByCurrentUser = useSelector((s) => isRoleUsedByCurrentUser(editedRole.id, s));
  const currentProjectId = useSelector(getCurrentProjectId);
  const dispatch = useDispatch();

  const saveFromUnsavedNavigationHandler = async (
    onSuccess: () => void,
    onFail: () => void,
  ): Promise<void> => {
    try {
      if (!isRoleValid(editedRole)) {
        dispatch(roleValidationFailed());
        onFail();
        return;
      }

      if (!roleToUpdate.capabilities.length) {
        dispatch(roleValidationFailedNoSelectedCapabilities());
        onFail();
        return;
      }

      await dispatch(
        updateRole(
          editedRole.id,
          roleToUpdate,
          roleIsUsedByCurrentUser,
          contentTypesById,
          currentProjectId,
        ),
      );
      onSuccess();
    } catch (error) {
      onFail();
      logErrorToMonitoringTool(error);
    }
  };

  const onControlSHandler = async (e: KeyboardEvent): Promise<void> => {
    e.preventDefault();
    if (isReadonly || status === RoleEditorStatus.IsBeingSaved) return;

    if (!isRoleValid(editedRole)) {
      dispatch(roleValidationFailed());
      return;
    }

    if (!roleToUpdate.capabilities.length) {
      dispatch(roleValidationFailedNoSelectedCapabilities());
      return;
    }

    await dispatch(
      updateRole(
        editedRole.id,
        roleToUpdate,
        roleIsUsedByCurrentUser,
        contentTypesById,
        currentProjectId,
      ),
    );
  };

  return isInitThunkDone && isLoaded(status) ? (
    <>
      <HandleUnsavedFormOnNavigation
        hasUnsavedChanges={hasUnsavedChanges(status)}
        isBeingSaved={status === RoleEditorStatus.IsBeingSaved}
        onSaveChanges={saveFromUnsavedNavigationHandler}
      />
      <RoleEditingComponent
        areCollectionsEnabled={areCollectionsEnabled}
        areSpacesEnabled={areSpacesEnabled}
        editedRoleName={editedRole.name}
        isAssetTypeEnabled={isAssetTypeEnabled}
        isAuditLogEnabled={isAuditLogEnabled}
        isRoleBeingCreated={false}
        isSitemapEnabled={isSitemapEnabled}
        newRoleLink={createNewRoleLink({
          projectId,
          subscriptionId: withSubscriptionIdInRoute,
        })}
        onControlSHandler={onControlSHandler}
        projectId={projectId}
        readonly={isReadonly}
      />
    </>
  ) : (
    <Loader />
  );
};
