import { Collection } from '@kontent-ai/utils';
import { useDataSelector } from '../../../../../_shared/hooks/useDataSelector.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { useThunkPromise } from '../../../../../_shared/hooks/useThunkPromise.ts';
import { isLegacyWebhookConfigurationEnabled } from '../../../../../_shared/selectors/isLegacyWebhookConfigurationEnabled.ts';
import { getSelectedSubscription } from '../../../../../_shared/selectors/subscriptionSelectors.ts';
import {
  getCurrentProject,
  getCurrentProjectPlan,
} from '../../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { isCollectionsConfigurationVisible } from '../../../../../_shared/utils/collections/isCollectionsConfigurationVisible.ts';
import { useCanAccessAiAccelerators } from '../../../../environmentSettings/aiAccelerators/hooks/useCanAccessAiAccelerators.ts';
import { canAccessInnovationLab } from '../../../../environmentSettings/innovationLab/utils/canAccessInnovationLab.ts';
import { initEnvironmentSettings } from '../../../../environmentSettings/root/actions/thunkEnvironmentSettingsActions.ts';
import { isEnvironmentGeneralSettingsTabVisibleSelector } from '../../../../environmentSettings/selectors/environmentSettingsMenuCustomVisibilitiesSelectors.ts';
import {
  areSpacesEnabledForCurrentProject,
  isAuditLogEnabledForCurrentProjectPlan,
} from '../../../../environmentSettings/utils/allowedFeaturesUtils.ts';
import { EnvironmentSettings as EnvironmentSettingsComponent } from '../../components/EnvironmentSettings/EnvironmentSettings.tsx';

export const EnvironmentSettings = () => {
  useThunkPromise(initEnvironmentSettings);

  const areCollectionsEnabled = useSelector((state) =>
    isCollectionsConfigurationVisible(
      getCurrentProjectPlan(state),
      Collection.getValues(state.data.collections.byId),
    ),
  );
  const subscriptionId = useSelector(
    (state) => getSelectedSubscription(state)?.subscriptionId ?? '',
  );
  const currentEnvironment = useSelector(getCurrentProject);
  const areGeneralSettingsVisible = useSelector(isEnvironmentGeneralSettingsTabVisibleSelector);
  const areSpacesEnabled = useSelector((state) =>
    areSpacesEnabledForCurrentProject(getCurrentProjectPlan(state)),
  );
  const isAuditLogEnabled = useSelector((state) =>
    isAuditLogEnabledForCurrentProjectPlan(getCurrentProjectPlan(state)),
  );

  const isInnovationLabAccessible = useDataSelector((state) =>
    canAccessInnovationLab(state.user, currentEnvironment),
  );
  const areAiAcceleratorsAccessible = useCanAccessAiAccelerators(currentEnvironment);

  const areLegacyWebhooksEnabled = useSelector(isLegacyWebhookConfigurationEnabled);

  return (
    <EnvironmentSettingsComponent
      areAiAcceleratorsAccessible={areAiAcceleratorsAccessible}
      areCollectionsEnabled={areCollectionsEnabled}
      areLegacyWebhooksEnabled={areLegacyWebhooksEnabled}
      areGeneralSettingsVisible={areGeneralSettingsVisible}
      areSpacesEnabled={areSpacesEnabled}
      isAuditLogEnabled={isAuditLogEnabled}
      isInnovationLabAccessible={isInnovationLabAccessible}
      projectId={currentEnvironment.projectId}
      subscriptionId={subscriptionId}
    />
  );
};
