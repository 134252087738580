import { makeCancellablePromise, swallowCancelledPromiseError } from '@kontent-ai/utils';
import { useEffect, useState } from 'react';
import { Task, createTaskFromServerModel } from '../../../../_shared/models/Task.ts';
import { repositoryCollection } from '../../../../_shared/repositories/repositories.ts';
import { logErrorToMonitoringTool } from '../../../../_shared/utils/logError.ts';
import { ContentStatusTasksTable as ContentItemTasksTableComponent } from '../components/ContentStatusTasksTable.tsx';

const { taskRepository } = repositoryCollection;

type ContentStatusTasksTableContainerProps = Readonly<{
  itemId: Uuid;
  variantId: Uuid;
}>;

const ContentStatusTasksTableContainer: React.FC<ContentStatusTasksTableContainerProps> = ({
  itemId,
  variantId,
}) => {
  const [tasks, setTasks] = useState<ReadonlyArray<Task>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);

    const { cancel } = makeCancellablePromise(
      async () => await taskRepository.getTasksForItemVariant(itemId, variantId, true),
    )
      .then((value) => setTasks(value.map(createTaskFromServerModel)))
      .catch(swallowCancelledPromiseError)
      .catch((error) => {
        logErrorToMonitoringTool(error);
      })
      .finally(() => {
        setIsLoading(false);
      });

    return cancel;
  }, [itemId, variantId]);

  return (
    <ContentItemTasksTableComponent
      itemId={itemId}
      variantId={variantId}
      tasks={tasks}
      isLoading={isLoading}
    />
  );
};

ContentStatusTasksTableContainer.displayName = 'ContentStatusTasksTableContainer';
export { ContentStatusTasksTableContainer as ContentStatusTasksTable };
