import XRegExp from 'xregexp';
import { logErrorMessageToMonitoringTool } from '../../../../../_shared/utils/logError.ts';
import { isString } from '../../../../../_shared/utils/stringUtils.ts';

// We use private use area for fake chars to differentiate them from normal chars
// Otherwise they could overlap with real chars and produce inconsistent results
const PrivateUseAreaStart = 0xe00;
const PrivateUseAreaEnd = 0xf8ff;

export function isInPrivateUseArea(ch: string): boolean {
  const charCode = ch.charCodeAt(0);
  return charCode >= PrivateUseAreaStart && charCode <= PrivateUseAreaEnd;
}

export function convertToPrivateUseArea(text: string): string {
  const chars = text.split('');
  const convertedChars = chars.map((ch) => {
    const charCode = ch.charCodeAt(0);
    const convertedCharCode = PrivateUseAreaStart + charCode;
    if (convertedCharCode > PrivateUseAreaEnd) {
      logErrorMessageToMonitoringTool(
        `Unable to convert string '${text}' to Unicode private use area. The character '${ch}' code extends the private area size.`,
      );
    }
    return String.fromCharCode(convertedCharCode);
  });

  return convertedChars.join('');
}

export function convertFromPrivateUseArea(text: string): string {
  const chars = text.split('');
  const convertedChars = chars.map((ch) => {
    const charCode = ch.charCodeAt(0);
    if (charCode >= PrivateUseAreaStart) {
      const convertedCharCode = charCode - PrivateUseAreaStart;
      return String.fromCharCode(convertedCharCode);
    }
    return ch;
  });

  return convertedChars.join('');
}

// XRegExp is used to properly detect unicode letters such as Cyrilic, this is not supported natively until ECMAScript 2018
export const wordCharRegex = XRegExp('\\pL|[0-9]');

export function isWordChar(ch: string | null | undefined): boolean {
  if (!isString(ch)) {
    return false;
  }

  if (isInPrivateUseArea(ch)) {
    return wordCharRegex.test(String.fromCharCode(ch.charCodeAt(0) - PrivateUseAreaStart));
  }

  return wordCharRegex.test(ch);
}
