import { ReactNode } from 'react';
import { Feature } from '../../../@types/FeatureEnum.ts';
import { IsNavigationTreeEnabledContext } from '../../applications/itemEditor/features/ContentItemEditing/context/IsNavigationTreeEnabledContext.tsx';
import { ItemEditingNewDesignContext } from '../../applications/itemEditor/features/ContentItemEditing/context/ItemEditingNewDesignContext.tsx';
import { LivePreviewConfigurationContextProvider } from '../../applications/itemEditor/features/ContentItemEditing/features/contentItemPreview/context/LivePreviewConfigurationContext.tsx';
import { RefinedNavigationContextProvider } from '../../applications/refinedNavigation/contexts/RefinedNavigationContext.tsx';
import { AiTranslationsContextProvider } from '../../applications/richText/plugins/ai/actions/TranslateContent/contexts/AiTranslationsContext.tsx';
import { AiFeatureSet } from '../constants/AiFeatureSet.ts';
import { ProjectDataRestrictedToSpecificDatacenterContextProvider } from '../contexts/ProjectDataRestrictedToSpecificDatacenterContext.tsx';
import { useSelector } from '../hooks/useSelector.ts';
import { selectEnabledAiFeatureSets } from '../selectors/aiSelectors.ts';
import { isFeatureEnabled } from '../utils/featureToggles.ts';

export const FeatureToggleProviders = ({ children }: { readonly children: ReactNode }) => {
  const isRefinedNavigationEnabled = useSelector(
    (state) => state.sharedApp.projectProperties.RefinedNavigation === 'Enabled',
  );
  const isProjectDataRestrictedToSpecificDatacenter = useSelector(
    (store) =>
      store.sharedApp.subscriptionProperties.IsProjectDataRestrictedToSpecificDatacenter === 'true',
  );
  const isAiTranslationsEnabled = useSelector(
    (store) =>
      !isProjectDataRestrictedToSpecificDatacenter &&
      selectEnabledAiFeatureSets(store).includes(AiFeatureSet.Translations),
  );
  const isLivePreviewEnabled = useSelector(
    (state) => state.data.projectEnvironmentSettings.livePreviewConfiguration.status === 'enabled',
  );
  const isNavigationTreeEnabled =
    useSelector((state) => state.sharedApp.projectFeatureFlags.WebSpotlightIntegrationTree) ||
    isFeatureEnabled(Feature.WebSpotlightIntegrationTree);

  return (
    <RefinedNavigationContextProvider
      isRefinedNavigationFeatureEnabled={isRefinedNavigationEnabled}
    >
      <AiTranslationsContextProvider isTranslationsFeatureEnabled={isAiTranslationsEnabled}>
        <ProjectDataRestrictedToSpecificDatacenterContextProvider
          isProjectDataRestrictedToSpecificDatacenter={isProjectDataRestrictedToSpecificDatacenter}
        >
          <LivePreviewConfigurationContextProvider isLivePreviewEnabled={isLivePreviewEnabled}>
            <ItemEditingNewDesignContext.Provider value>
              <IsNavigationTreeEnabledContext.Provider value={isNavigationTreeEnabled}>
                {children}
              </IsNavigationTreeEnabledContext.Provider>
            </ItemEditingNewDesignContext.Provider>
          </LivePreviewConfigurationContextProvider>
        </ProjectDataRestrictedToSpecificDatacenterContextProvider>
      </AiTranslationsContextProvider>
    </RefinedNavigationContextProvider>
  );
};
