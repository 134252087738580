import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { logErrorToMonitoringToolWithCustomMessage } from '../../../../_shared/utils/logError.ts';
import { IWebSpotlightRepository } from '../../../../repositories/interfaces/IWebSpotlightRepository.type.ts';
import { WebSpotlight_ElementPermissionCheck_Failed } from '../../constants/webSpotlightActionTypes.ts';
import {
  AddButtonElementType,
  AddButtonPermission,
  AddButtonPermissionOption,
  IAddButtonPermissionsServerModel,
} from '../../types/IAddButtonPermissionsServerModels.ts';

interface ILoadElementActionsPermissionsDependencies {
  readonly webSpotlightRepository: IWebSpotlightRepository;
}

const permissionCheckFailed = (itemId: string, elementCodename: string, errorMessage?: string) =>
  ({
    type: WebSpotlight_ElementPermissionCheck_Failed,
    payload: {
      elementCodename,
      errorMessage,
      itemId,
    },
  }) as const;

export type CheckElementPermissionsActionTypes = ReturnType<typeof permissionCheckFailed>;

const unknownPermissionsServerModel: IAddButtonPermissionsServerModel = {
  elementType: AddButtonElementType.Unknown,
  isParentPublished: false,
  permissions: {
    [AddButtonPermission.CreateNew]: AddButtonPermissionOption.Unknown,
    [AddButtonPermission.Edit]: AddButtonPermissionOption.Unknown,
    [AddButtonPermission.ViewParent]: AddButtonPermissionOption.Unknown,
  },
};

export const loadElementActionPermissionsForVariantActionCreator =
  (deps: ILoadElementActionsPermissionsDependencies) =>
  (
    variantId: Uuid,
    itemId: Uuid,
    elementCodename: string,
    deliveryContentComponentId: Uuid | undefined,
  ): ThunkPromise<IAddButtonPermissionsServerModel> =>
  async (dispatch) => {
    try {
      return await deps.webSpotlightRepository.checkElementOperationsStatus(
        variantId,
        itemId,
        elementCodename,
        deliveryContentComponentId,
      );
    } catch (e) {
      dispatch(permissionCheckFailed(itemId, elementCodename));
      logErrorToMonitoringToolWithCustomMessage('Loading add button permissions failed', e);

      return unknownPermissionsServerModel;
    }
  };
