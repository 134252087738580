import { createGuid } from '@kontent-ai/utils';
import { ThunkPromise } from '../../../../../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../../../../../_shared/constants/trackedEvent.ts';
import { BulkMoveToCollectionError } from '../../../../../../../../_shared/features/ChangeWorkflowStepModal/constants/uiConstants.ts';
import { TrackUserEventAction } from '../../../../../../../../_shared/models/TrackUserEvent.type.ts';
import { getSelectedLanguageIdOrThrow } from '../../../../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { logErrorToMonitoringToolWithCustomMessage } from '../../../../../../../../_shared/utils/logError.ts';
import { IContentItemRepository } from '../../../../../../../../repositories/interfaces/IContentItemRepository.type.ts';
import { ILoadListingItemsAction } from '../../../../../../../itemEditor/features/LoadedItems/actions/thunks/loadListingItems.ts';
import { getItemListingOperationQueryFromFilter } from '../../../../../utils/contentItemListModelConverters.ts';
import {
  ContentListing_MoveToCollection_Failed,
  ContentListing_MoveToCollection_Finished,
  ContentListing_MoveToCollection_Started,
} from '../../../constants/contentItemInventoryActionTypes.ts';
import { createBulkActionResult } from '../../../utils/createBulkActionResult.ts';
import {
  createBulkActionFailed,
  createBulkActionFinished,
  createBulkActionStarted,
} from '../../contentItemInventoryActions.ts';
import { setTimeoutForMessageChange } from './setTimeoutForMessageChange.ts';

interface IDeps {
  readonly contentItemRepository: Pick<IContentItemRepository, 'moveItemsToCollection'>;
  readonly loadListingContentItems: () => ThunkPromise;
  readonly loadListingItems: ILoadListingItemsAction;
  readonly trackUserEvent: TrackUserEventAction;
}
const actionStarted = createBulkActionStarted<typeof ContentListing_MoveToCollection_Started>(
  ContentListing_MoveToCollection_Started,
);
const actionFinished = createBulkActionFinished<typeof ContentListing_MoveToCollection_Finished>(
  ContentListing_MoveToCollection_Finished,
);
const actionFailed = createBulkActionFailed<typeof ContentListing_MoveToCollection_Failed>(
  ContentListing_MoveToCollection_Failed,
);

export type MoveSelectedContentItemsToCollectionActionsType = ReturnType<
  typeof actionStarted | typeof actionFinished | typeof actionFailed
>;

export const createMoveSelectedContentItemsToCollectionAction =
  (deps: IDeps) =>
  (collectionId: Uuid, onFail: () => void): ThunkPromise =>
  async (dispatch, getState): Promise<void> => {
    const state = getState();
    const selectedLanguageId = getSelectedLanguageIdOrThrow(state);
    const query = getItemListingOperationQueryFromFilter(state);
    const operationId = createGuid();

    dispatch(
      actionStarted({
        operationId,
      }),
    );
    dispatch(setTimeoutForMessageChange(operationId));

    try {
      const operationResult = await deps.contentItemRepository.moveItemsToCollection(
        selectedLanguageId,
        query,
        collectionId,
      );

      dispatch(actionFinished(createBulkActionResult(operationResult, selectedLanguageId)));
      dispatch(deps.loadListingContentItems());

      dispatch(
        deps.trackUserEvent(TrackedEvent.ContentEntryBulkMoveToCollection, {
          count: operationResult.modifiedItemIds.length,
          'failed-count': operationResult.failedItems.length,
        }),
      );

      if (operationResult.failedItems.length > 0) {
        dispatch(deps.loadListingItems(operationResult.failedItems.map((item) => item.id)));
        onFail();
      }
    } catch (error) {
      logErrorToMonitoringToolWithCustomMessage(
        'moveSelectedContentItemsToCollection.ts: There was an error while moving content items in bulk.',
        error,
      );
      dispatch(actionFailed(BulkMoveToCollectionError));
    }
  };
