import { ReactNode, useMemo } from 'react';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { useThunkPromise } from '../../../../_shared/hooks/useThunkPromise.ts';
import { logErrorMessageToMonitoringTool } from '../../../../_shared/utils/logError.ts';
import { loadAssets } from '../../../../data/actions/thunkDataActions.ts';
import { IAssetRendition } from '../../../../data/models/assetRenditions/AssetRendition.ts';
import { IImage, isImage } from '../../../../data/models/assets/Image.ts';

type Props = {
  readonly assetId: Uuid;
  readonly isOpen: boolean;
  readonly renderDialog: (
    isDataLoading: boolean,
    asset: IImage,
    existingRendition: IAssetRendition | null,
  ) => ReactNode;
  readonly renditionId: Uuid | null;
};

export const AssetRenditionDialogDataEnsurer = ({
  assetId,
  isOpen,
  renderDialog,
  renditionId,
}: Props) => {
  const assetReferences = useMemo(
    () => [
      {
        id: assetId,
        renditions: renditionId ? [{ id: renditionId }] : [],
      },
    ],
    [assetId, renditionId],
  );
  const [isLoadAssetsThunkDone] = useThunkPromise(loadAssets, assetReferences, {
    canRun: isOpen,
  });

  const asset = useSelector((s) => s.data.assets.byId.get(assetId) ?? null);
  const existingRendition = useSelector((s) =>
    renditionId ? (s.data.assetRenditions.byId.get(renditionId) ?? null) : null,
  );

  if (!asset) {
    return null;
  }

  if (!isImage(asset)) {
    logErrorMessageToMonitoringTool(
      'AssetRenditionDialog: asset does not have image data (width, height, preview and download link).',
    );
    return null;
  }

  return renderDialog(!isLoadAssetsThunkDone, asset, existingRendition);
};
