import { memoize } from '@kontent-ai/memoization';
import { assert, identity } from '@kontent-ai/utils';
import {
  CharacterMetadata,
  ContentBlock,
  ContentState,
  DraftInlineStyle,
  EntityInstance,
} from 'draft-js';
import Immutable from 'immutable';
import { splitToGroups } from '../../../../../_shared/utils/arrayUtils/arrayUtils.ts';
import { digestToString } from '../../../../../_shared/utils/crypto/digest.ts';
import { logErrorMessageToMonitoringTool } from '../../../../../_shared/utils/logError.ts';
import { isString } from '../../../../../_shared/utils/stringUtils.ts';
import { DiffDeps } from '../../../../itemEditor/features/Revisions/types/diffDeps.type.ts';
import { DiffType } from '../../../../itemEditor/features/Revisions/utils/DiffType.ts';
import {
  DiffBlockTypeTable,
  EmptyBlockPlaceholder,
  FakeCharType,
  IDiffInputBlock,
  IDiffResultBlock,
  IInputChar,
  IOutputChar,
  mergeDiffType,
} from '../../../../itemEditor/features/Revisions/utils/diffDataUtils.ts';
import { createDiffFromInputBlocks } from '../../../../itemEditor/features/Revisions/utils/richTextDiffUtils.ts';
import {
  DiffObjectBlockType,
  getObjectBlockWord,
} from '../../../../itemEditor/features/Revisions/utils/textDiffUtils.ts';
import { BaseBlockType, BlockType } from '../../../utils/blocks/blockType.ts';
import {
  isContentComponent,
  isContentModule,
  isCustomBlockSleeve,
  isImage,
  isObjectBlock,
  isTableCell,
} from '../../../utils/blocks/blockTypeUtils.ts';
import { getBaseBlockType, getBlockData } from '../../../utils/blocks/editorBlockGetters.ts';
import {
  convertRawBlockToContentBlock,
  createContent,
  createRawBlock,
  getBlockDataValue,
  getUnstyledBlock,
  setBlockDataValue,
  setBlockText,
} from '../../../utils/blocks/editorBlockUtils.ts';
import {
  IBlockHierarchyNode,
  getBlockHierarchy,
} from '../../../utils/blocks/editorHierarchyUtils.ts';
import {
  ensureCustomBlockSleevesInContent,
  ensureTablesConsistency,
} from '../../../utils/consistency/editorConsistencyUtils.ts';
import { EntityMap, getBlocks, getEntityMap } from '../../../utils/general/editorContentGetters.ts';
import { isAiIdStyle } from '../../ai/utils/editorAiStyleUtils.ts';
import { getContentComponentId } from '../../contentComponents/api/editorContentComponentUtils.ts';
import { getImageAssetReference } from '../../images/api/editorImageUtils.ts';
import { getStyles, isVisualStyle } from '../../inlineStyles/api/editorStyleUtils.ts';
import { getModularContentItemId } from '../../linkedItems/api/editorModularUtils.ts';
import { ICoordinates } from '../../tables/api/depthCoordinatesConversion.ts';
import { ITableCell, createEmptyCell } from '../../tables/api/tableGenerator.ts';

export enum InlineStyleDiff {
  Removed = 'REMOVED',
  Added = 'ADDED',
  Changed = 'CHANGED',
}

// Styled in diff.less
export const DiffStyles = {
  [InlineStyleDiff.Removed]: {
    className: 'rte__removed',
  },
  [InlineStyleDiff.Added]: {
    className: 'rte__added',
  },
  [InlineStyleDiff.Changed]: {
    className: 'rte__changed',
  },
} as const;

export const isDiffStyle = (style: string): style is InlineStyleDiff =>
  Object.hasOwn(DiffStyles, style);

function isValidResultingBlockDiffType(blockDiffType: DiffType | null): boolean {
  return (
    !!blockDiffType &&
    (blockDiffType === DiffType.Changed ||
      blockDiffType === DiffType.Added ||
      blockDiffType === DiffType.Removed)
  );
}

const getInputTextCharacter = memoize.maxN(
  (char: string, charMetadata: CharacterMetadata | null): IInputChar => {
    return {
      char,
      inlineStyles: charMetadata
        ? getStyles(charMetadata.getStyle(), isVisualStyle, isAiIdStyle)
        : Immutable.OrderedSet<string>(),
      entityKey: charMetadata?.getEntity() || null,
    };
  },
  1_000,
);

export const getTextInputCharacters = async (
  text: string,
  characterList: Immutable.List<CharacterMetadata>,
  entityMap: EntityMap,
): Promise<ReadonlyArray<IInputChar>> => {
  const charsSplitByEntities = splitToGroups(
    characterList.toArray(),
    (current, previous) => (current.getEntity() || null) !== (previous.getEntity() || null),
  );
  let usedChars = 0;

  const chunkInputChars = await Promise.all(
    charsSplitByEntities.map(async (chunk) => {
      const textInputChars = chunk.map((ch, index) => {
        const char = text[usedChars + index];
        assert(isString(char), () => `Item at index "${index}" is not a string. Value: "${char}".`);
        return getInputTextCharacter(char, ch);
      });
      usedChars += chunk.length;

      const entityKey = chunk[0]?.getEntity();
      const entity = entityKey && entityMap.__get(entityKey);

      return entity
        ? // Treat entities (links) as atomic segments so we can compare changes to links
          await getEntityInputCharacters(entityKey, entity, textInputChars)
        : // Standard text
          textInputChars;
    }),
  );

  return chunkInputChars.flatMap(identity);
};

function getFakeCharacters(
  text: string,
  fakeCharType: FakeCharType,
  extraData?: Partial<IInputChar>,
): ReadonlyArray<IInputChar> {
  return text.split('').map((char) => ({
    char,
    inlineStyles: Immutable.OrderedSet<string>(),
    entityKey: null,
    fakeCharType,
    ...extraData,
  }));
}

const getEntityHash = memoize.weak(async (entity: EntityInstance): Promise<string> => {
  const jsonEntity = JSON.stringify({
    type: entity.getType(),
    mutability: entity.getMutability(),
    data: entity.getData(),
  });
  return await digestToString('SHA-1', jsonEntity);
});

const getEntityInputCharacters = async (
  entityKey: string,
  entity: EntityInstance,
  chars: ReadonlyArray<IInputChar>,
): Promise<ReadonlyArray<IInputChar>> => {
  const entityHash = await getEntityHash(entity);
  const entityWord = `entity${entityHash}entity`;
  return getFakeCharacters(entityWord, FakeCharType.Text, {
    entityKey,
    fakedOriginalChars: chars,
  });
};

// We use some random (but static) ID to represent any table, as table blocks in general do not exist, and any of the participating cells may be deleted
// Table matches to another table in the diff just by its presence and the fact it is a table
const UniqueTableIdentifier = 'd38fcc35-76ed-44d1-be42-d65a64099662';

export function getTableInputCharacters(): ReadonlyArray<IInputChar> {
  const tableWord = getObjectBlockWord(DiffObjectBlockType.Table, UniqueTableIdentifier);
  return getFakeCharacters(tableWord, FakeCharType.BlockContainer);
}

export function getModularContentInputCharacters(contentItemId: Uuid): ReadonlyArray<IInputChar> {
  const moduleWord = getObjectBlockWord(DiffObjectBlockType.Module, contentItemId);
  return getFakeCharacters(moduleWord, FakeCharType.Block);
}

export function getContentComponentContentInputCharacters(
  contentComponentId: Uuid,
): ReadonlyArray<IInputChar> {
  const componentWord = getObjectBlockWord(DiffObjectBlockType.Component, contentComponentId);
  return getFakeCharacters(componentWord, FakeCharType.Block);
}

export function getImageInputCharacters(assetId: Uuid): ReadonlyArray<IInputChar> {
  const imageWord = getObjectBlockWord(DiffObjectBlockType.Image, assetId);
  return getFakeCharacters(imageWord, FakeCharType.Block);
}

const getInputBlockCharacters = async (
  block: ContentBlock,
  entityMap: EntityMap,
): Promise<ReadonlyArray<IInputChar>> => {
  // Each object block (image, linked items) is represented by a single fake word built from the block metadata describing the content
  // This is to ensure the block behaves atomically and helps to pair its representation within the diff algorithm
  // e.g. Linked items with ID f1c4ec75-097b-4a58-a7ec-85726b5f3398 produces "modulef1c4ec75097b4a58a7ec85726b5f3398module"
  if (isContentModule(block)) {
    const contentItemId = getModularContentItemId(block) ?? '';
    return getModularContentInputCharacters(contentItemId);
  }

  if (isContentComponent(block)) {
    const contentComponent = getContentComponentId(block) ?? '';
    return getContentComponentContentInputCharacters(contentComponent);
  }

  if (isImage(block)) {
    const assetReference = getImageAssetReference(block)?.id ?? '';
    return getImageInputCharacters(assetReference);
  }

  return await getTextInputCharacters(block.getText(), block.getCharacterList(), entityMap);
};

export const createDiffInputBlock = async (
  node: IBlockHierarchyNode,
  entityMap: EntityMap,
): Promise<IDiffInputBlock> => {
  const block = node.block;

  const characters = await getInputBlockCharacters(block, entityMap);

  // Every input block must contain at least one character in order to be able to detect its presence within the compared text
  const nonEmptyCharacters =
    characters.length === 0
      ? getFakeCharacters(EmptyBlockPlaceholder, FakeCharType.Block)
      : characters;

  return {
    type: getBaseBlockType(block),
    key: block.getKey(),
    depth: block.getDepth(),
    characters: nonEmptyCharacters,
    data: getBlockData(block),
    blocks: node.childNodes
      ? await getDiffInputBlocksFromNodes(node.childNodes, entityMap)
      : undefined,
  };
};

const createTableInputBlock = async (
  cellNodes: ReadonlyArray<IBlockHierarchyNode>,
  entityMap: EntityMap,
): Promise<IDiffInputBlock> => {
  return {
    type: DiffBlockTypeTable,
    key: '',
    characters: getTableInputCharacters(),
    depth: 0,
    blocks: await Promise.all(
      cellNodes.map((cellNode) => createDiffInputBlock(cellNode, entityMap)),
    ),
  };
};

const getDiffInputBlocksFromNodes = async (
  nodes: ReadonlyArray<IBlockHierarchyNode>,
  entityMap: EntityMap,
): Promise<ReadonlyArray<IDiffInputBlock>> => {
  const result = Array<IDiffInputBlock>();
  const blocks = nodes.map((node) => node.block);
  const nodeIsFalsyMessage = (index: number) => () => `Node at index "${index}" is undefined.`;
  const tableCellIsFalsyMessage = (index: number) => () =>
    `Table cell at index "${index}" is undefined.`;

  for (let i = 0; i < nodes.length; i++) {
    if (isCustomBlockSleeve(i, blocks)) {
      continue;
    }

    const node = nodes[i];
    assert(node, nodeIsFalsyMessage(i));
    if (isTableCell(node.block)) {
      // Wrap all table cells in one group to a single diff block
      const cellNodes = Array<IBlockHierarchyNode>();
      do {
        const cell = nodes[i];
        assert(cell, tableCellIsFalsyMessage(i));
        cellNodes.push(cell);
        i++;
      } while (isTableCell(nodes[i]?.block));

      const table = await createTableInputBlock(cellNodes, entityMap);
      result.push(table);
    } else {
      result.push(await createDiffInputBlock(node, entityMap));
    }
  }

  return result;
};

const getDiffInputBlocks = async (
  blocks: ReadonlyArray<ContentBlock>,
  entityMap: EntityMap,
): Promise<ReadonlyArray<IDiffInputBlock>> => {
  const hierarchy = getBlockHierarchy(blocks);
  return await getDiffInputBlocksFromNodes(hierarchy, entityMap);
};

const createDiffFromBlocks = async (
  oldBlocks: ReadonlyArray<ContentBlock>,
  newBlocks: ReadonlyArray<ContentBlock>,
  entityMap: EntityMap,
  deps: DiffDeps,
): Promise<ReadonlyArray<ContentBlock>> => {
  const normalizedOldBlocksPromise = getDiffInputBlocks(oldBlocks, entityMap);
  const normalizedNewBlocksPromise = getDiffInputBlocks(newBlocks, entityMap);

  const outputBlocks = await createDiffFromInputBlocks(
    await normalizedOldBlocksPromise,
    await normalizedNewBlocksPromise,
    deps,
  );

  return outputBlocks.flatMap((block) => convertOutputBlockToContentBlocks(block, []));
};

const getCharacterMetadata = memoize.allForever(
  (
    style: DraftInlineStyle,
    entity: string | undefined,
    diffType: DiffType | undefined,
  ): CharacterMetadata => {
    const metadata = CharacterMetadata.create({
      style,
      entity,
    });

    switch (diffType) {
      case DiffType.Added:
        return CharacterMetadata.applyStyle(metadata, InlineStyleDiff.Added);

      case DiffType.Removed:
        return CharacterMetadata.applyStyle(metadata, InlineStyleDiff.Removed);

      case DiffType.Changed:
        return CharacterMetadata.applyStyle(metadata, InlineStyleDiff.Changed);

      default:
        return metadata;
    }
  },
);

function getOutputCharMetadata(char: IOutputChar): CharacterMetadata {
  return getCharacterMetadata(char.inlineStyles, char.entityKey ?? undefined, char.diffType);
}

function convertOutputBlockToContentBlock(
  outputBlock: IDiffResultBlock,
  parentBlockTypes: ReadonlyArray<BaseBlockType>,
): ContentBlock {
  const emptyRawBlock = createRawBlock({
    type: outputBlock.type as BlockType,
    depth: outputBlock.depth,
    data: outputBlock.data,
  });
  const emptyBlock = convertRawBlockToContentBlock(parentBlockTypes, emptyRawBlock);

  const text = outputBlock.characters.map((c: IOutputChar) => c.char).join('');
  const characterList = Immutable.List(outputBlock.characters.map(getOutputCharMetadata));

  const block = emptyBlock.merge({
    text,
    characterList,
  }) as ContentBlock;

  if (outputBlock.diffType && isValidResultingBlockDiffType(outputBlock.diffType)) {
    return setDiffType(block, outputBlock.diffType);
  }
  return block;
}

function convertOutputBlockToContentBlocks(
  outputBlock: IDiffResultBlock,
  parentBlockTypes: ReadonlyArray<BaseBlockType>,
): ReadonlyArray<ContentBlock> {
  const type = outputBlock.type;
  if (type === DiffBlockTypeTable) {
    if (outputBlock.blocks?.length) {
      return [
        getUnstyledBlock(parentBlockTypes),
        ...outputBlock.blocks.flatMap((block) =>
          convertOutputBlockToContentBlocks(block, parentBlockTypes),
        ),
        getUnstyledBlock(parentBlockTypes),
      ];
    }
    logErrorMessageToMonitoringTool('Found table without cells, not placing result to the output.');
    return [];
  }

  const resultBlock = convertOutputBlockToContentBlock(
    { ...outputBlock, blocks: undefined },
    parentBlockTypes,
  );
  const resultBlocks = outputBlock.blocks
    ? [
        resultBlock,
        ...outputBlock.blocks.flatMap((block) =>
          convertOutputBlockToContentBlocks(block, [...parentBlockTypes, type]),
        ),
      ]
    : [resultBlock];

  if (isObjectBlock(resultBlock)) {
    return [
      getUnstyledBlock(parentBlockTypes),
      ...resultBlocks,
      getUnstyledBlock(parentBlockTypes),
    ];
  }
  return resultBlocks;
}

function createDummyTableCell(
  parentBlockTypes: ReadonlyArray<BaseBlockType>,
  coords: ICoordinates,
): ITableCell {
  const emptyCell = createEmptyCell(parentBlockTypes, coords);
  return {
    cellBlock: setBlockDataValue(emptyCell.cellBlock, 'dummy', true),
    contentBlocks: [],
  };
}

export const getDiffType = (block: ContentBlock): DiffType =>
  getBlockDataValue(block, 'diffType') || DiffType.None;

export const setDiffType = (block: ContentBlock, diffType: DiffType | undefined): ContentBlock =>
  setBlockDataValue(block, 'diffType', diffType);

function mergeTableCells(existing: ITableCell, duplicate: ITableCell): ITableCell {
  const existingCellBlock = existing.cellBlock;
  const duplicateCellBlock = duplicate.cellBlock;
  const duplicateText = existingCellBlock.getText() + duplicateCellBlock.getText();

  const withMergedContent = setBlockText(existingCellBlock, {
    text: duplicateText,
    characterList: existingCellBlock
      .getCharacterList()
      .concat(duplicateCellBlock.getCharacterList())
      .toList(),
  });

  const newDiffType = mergeDiffType(
    getDiffType(existingCellBlock),
    getDiffType(duplicateCellBlock),
  );

  const diffType = isValidResultingBlockDiffType(newDiffType) ? newDiffType : undefined;
  const mergedCellBlock = setDiffType(withMergedContent, diffType);

  return {
    cellBlock: mergedCellBlock,
    contentBlocks: [...existing.contentBlocks, ...duplicate.contentBlocks],
  };
}

export async function createDiffFromContentState(
  oldContent: ContentState,
  newContent: ContentState,
  deps: DiffDeps,
): Promise<ContentState> {
  if (oldContent === newContent) {
    return newContent;
  }

  // Entity map is a singleton so we can get it only through new content
  const entityMap = getEntityMap(newContent);
  const blocksWithDiff = await createDiffFromBlocks(
    getBlocks(oldContent),
    getBlocks(newContent),
    entityMap,
    deps,
  );

  // Take the empty block from sources, if both contents were empty (diff produced no results), new content has priority
  const nonEmptyBlocksWithDiff = blocksWithDiff.length
    ? blocksWithDiff
    : [newContent.getFirstBlock() || oldContent.getFirstBlock()];

  const contentStateWithDiff = createContent(nonEmptyBlocksWithDiff);

  return ensureTablesConsistency(
    ensureCustomBlockSleevesInContent(contentStateWithDiff),
    // Missing table cells are places of intersection of removed and added columns / rows, provide dummy table cells in their place
    createDummyTableCell,
    mergeTableCells,
  );
}
