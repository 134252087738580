import { Box } from '@kontent-ai/component-library/Box';
import { Divider, DividerDirection } from '@kontent-ai/component-library/Dividers';
import { Inline } from '@kontent-ai/component-library/Inline';
import { Label, LabelSize } from '@kontent-ai/component-library/Label';
import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import { useOurFocusRing } from '@kontent-ai/component-library/hooks';
import {
  Spacing,
  colorBackgroundDisabled,
  colorBorderDisabled,
  colorTextDefault,
  colorTextDisabled,
} from '@kontent-ai/component-library/tokens';
import { noOperation } from '@kontent-ai/utils';
import { animated, useTransition } from '@react-spring/web';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { trackUserEvent } from '../../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../../../../_shared/hooks/useDispatch.ts';
import { useThunkPromise } from '../../../../../../../_shared/hooks/useThunkPromise.ts';
import { ContentItemFilterOrigin } from '../../../../../../../_shared/models/events/ContentItemFilterEventData.type.ts';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ISavedFilter } from '../../../../../../../data/models/filters/ISavedFilter.ts';
import { useIsRefinedNavigationFeatureEnabled } from '../../../../../../refinedNavigation/contexts/RefinedNavigationContext.tsx';
import { ISavedFiltersUi } from '../../../../reducers/listingUi/reducers/savedFiltersUi/ISavedFiltersUi.type.ts';
import { onHideRestoreButton, onSelectSavedFilter } from '../../actions/listingFilterActions.ts';
import {
  deleteSavedFilter,
  initSavedFiltersUi,
  restoreSavedFilter,
  updateSavedFilter,
} from '../../actions/thunkListingFilterActions.ts';
import { RestoreSavedFilterButton } from '../../components/savedFilters/RestoreSavedFilterButton.tsx';
import {
  SavedFilterPillWrapper,
  SavedFiltersListingItem,
} from '../../components/savedFilters/SavedFiltersListingItem.tsx';
import { ShowAllSavedFiltersButton } from '../../components/savedFilters/ShowAllSavedFiltersButton.tsx';
import { RestoreSavedFilterButtonNotificationTime } from '../../constants/listingFilterConstants.ts';

type Props = {
  readonly filters: ReadonlyArray<ISavedFilter>;
  readonly forcedContentTypeIds: ReadonlySet<Uuid> | undefined;
  readonly isAnyOptionSelected: boolean | undefined;
  readonly onClearFilter: (() => void) | undefined;
  readonly origin: ContentItemFilterOrigin;
  readonly savedFiltersUi: ISavedFiltersUi;
  readonly selectedFilterId: Uuid | null;
};

const maxRenderedFiltersWithShowMoreButtonVisible = 5;
const maxRenderedFiltersWithShowMoreButtonHidden = 6;

const isFiltersCountInDefaultVisibleLimit = (filtersCount: number) =>
  filtersCount <= maxRenderedFiltersWithShowMoreButtonHidden;

export const SavedFiltersListing = (props: Props) => {
  const dispatch = useDispatch();
  const [allFiltersButtonVisible, setAllFiltersButtonVisible] = useState(() =>
    isFiltersCountInDefaultVisibleLimit(props.filters.length),
  );
  const [lastDeletedFilter, setLastDeletedFilter] = useState<ISavedFilter | null>(null);
  const isRefinedNavigationFeatureEnabled = useIsRefinedNavigationFeatureEnabled();

  useThunkPromise(initSavedFiltersUi, { canRun: !props.filters.length });

  useEffect(() => {
    if (isFiltersCountInDefaultVisibleLimit(props.filters.length)) {
      setAllFiltersButtonVisible(true);
    }
  }, [props.filters.length]);

  const shouldRenderAllFilters =
    isFiltersCountInDefaultVisibleLimit(props.filters.length) ||
    !allFiltersButtonVisible ||
    isRefinedNavigationFeatureEnabled;
  const filtersToRender = shouldRenderAllFilters
    ? props.filters
    : props.filters.slice(0, maxRenderedFiltersWithShowMoreButtonVisible);

  const transitions = useTransition(
    filtersToRender.map((f) => f.id),
    {
      from: {
        opacity: 0,
      },
      enter: {
        opacity: 1,
      },
      leave: {
        opacity: 0,
      },
    },
  );

  const displaySavedFiltersSection =
    props.filters.length > 0 || props.savedFiltersUi.lastDeletedFilterId;

  const [filterToBeRestoredTimerId, setFilterToBeRestoredTimerId] = useState<number | null>(null);
  const hideRestoreButton = () => dispatch(onHideRestoreButton());

  if (!displaySavedFiltersSection) {
    return null;
  }

  if (!isRefinedNavigationFeatureEnabled) {
    return (
      <div
        className="saved-filters facet"
        {...getDataUiElementAttribute(DataUiElement.SavedFiltersSection)}
      >
        <div className="facet__title">
          <div
            className={classNames('facet__title-text', {
              'facet__title-text--is-deleted': props.savedFiltersUi.lastDeletedFilterId,
            })}
          >
            {props.savedFiltersUi.lastDeletedFilterId
              ? 'Saved filter deleted'
              : 'Your saved filters'}
          </div>
          {props.savedFiltersUi.lastDeletedFilterId && (
            <RestoreSavedFilterButton
              onFilterRestore={() => dispatch(restoreSavedFilter())}
              restoreActionInProgress={props.savedFiltersUi.restoreActionInProgress}
            />
          )}
        </div>
        <ul className="saved-filters__list">
          {transitions((style, filterId) => {
            const filter = filtersToRender.find((f) => f.id === filterId) ?? lastDeletedFilter;
            return (
              filter && (
                <animated.li style={style}>
                  <SavedFiltersListingItem
                    filter={filter}
                    isActive={filterId === props.selectedFilterId}
                    isBeingDeleted={props.savedFiltersUi.filtersBeingDeleted.has(filterId ?? '')}
                    isBeingSaved={props.savedFiltersUi.filtersBeingUpdated.has(filterId ?? '')}
                    onFilterDelete={(deletedFilter) => {
                      setLastDeletedFilter(deletedFilter);
                      dispatch(deleteSavedFilter({ filter: deletedFilter }));
                      if (filterToBeRestoredTimerId) {
                        self.clearTimeout(filterToBeRestoredTimerId);
                      }
                      const timerId = self.setTimeout(() => {
                        hideRestoreButton();
                      }, RestoreSavedFilterButtonNotificationTime);
                      setFilterToBeRestoredTimerId(timerId);
                    }}
                    onFilterRename={(renamedFilter) =>
                      dispatch(updateSavedFilter({ filter: renamedFilter }))
                    }
                    onFilterNameClick={(selectedFilter) => {
                      dispatch(onSelectSavedFilter(selectedFilter, props.forcedContentTypeIds));
                      dispatch(
                        trackUserEvent(TrackedEvent.SavedFilterSelected, {
                          'filter-id': selectedFilter.id,
                          origin: props.origin,
                        }),
                      );
                    }}
                  />
                </animated.li>
              )
            );
          })}
        </ul>
        {!shouldRenderAllFilters && (
          <ShowAllSavedFiltersButton onClick={() => setAllFiltersButtonVisible(false)}>
            Show all filters
          </ShowAllSavedFiltersButton>
        )}
      </div>
    );
  }

  return (
    <Box paddingX={Spacing.L}>
      <Inline spacing={Spacing.S} align="normal">
        <Box paddingY={Spacing.S}>
          <Label size={LabelSize.L} color={colorTextDefault}>
            Saved filters:
          </Label>
        </Box>
        <AllItemsPill
          onClick={props.onClearFilter ?? noOperation}
          disabled={!props.isAnyOptionSelected}
        />
        <Divider
          direction={DividerDirection.Vertical}
          offsetAfter={Spacing.None}
          offsetBefore={Spacing.None}
        />
        {filtersToRender.map((filter) => (
          <SavedFiltersListingItem
            key={filter.id}
            filter={filter}
            isActive={filter.id === props.selectedFilterId}
            isBeingDeleted={props.savedFiltersUi.filtersBeingDeleted.has(filter.id)}
            isBeingSaved={props.savedFiltersUi.filtersBeingUpdated.has(filter.id)}
            onFilterDelete={(deletedFilter) => {
              setLastDeletedFilter(deletedFilter);
              dispatch(deleteSavedFilter({ filter: deletedFilter }));
              if (filterToBeRestoredTimerId) {
                self.clearTimeout(filterToBeRestoredTimerId);
              }
              const timerId = self.setTimeout(() => {
                hideRestoreButton();
              }, RestoreSavedFilterButtonNotificationTime);
              setFilterToBeRestoredTimerId(timerId);
            }}
            onFilterRename={(renamedFilter) =>
              dispatch(updateSavedFilter({ filter: renamedFilter }))
            }
            onFilterNameClick={(selectedFilter) => {
              dispatch(onSelectSavedFilter(selectedFilter, props.forcedContentTypeIds));
              dispatch(
                trackUserEvent(TrackedEvent.SavedFilterSelected, {
                  'filter-id': selectedFilter.id,
                  origin: props.origin,
                }),
              );
            }}
          />
        ))}
      </Inline>
    </Box>
  );
};

const AllItemsPill = ({
  disabled,
  onClick,
}: Readonly<{ disabled: boolean; onClick: () => void }>) => {
  const { isFocusVisible, focusProps } = useOurFocusRing();
  return (
    <Tooltip text={disabled ? 'Filter is empty' : ''} placement="top">
      {/* Disabled elements don't trigger tooltips */}
      <Box>
        <SavedFilterPillWrapper
          aria-disabled={disabled}
          $isActive={false}
          as="button"
          isFocusVisible={isFocusVisible}
          tabIndex={disabled ? -1 : undefined}
          onClick={disabled ? undefined : onClick}
          type="button"
          css={`
            &[aria-disabled="true"] {
              border-color: ${colorBorderDisabled};
              color: ${colorTextDisabled};
              background-color: ${colorBackgroundDisabled};
              cursor: not-allowed;
            }
          `}
          {...focusProps}
        >
          <Box display="flex" alignItems="center">
            <Label size={LabelSize.L} color={colorTextDefault}>
              All items
            </Label>
          </Box>
        </SavedFilterPillWrapper>
      </Box>
    </Tooltip>
  );
};
