import { assert } from '@kontent-ai/utils';
import { History } from 'history';
import {
  Dispatch,
  GetState,
  ThunkFunction,
  ThunkPromise,
} from '../../../../../../@types/Dispatcher.type.ts';
import { UpdateWorkflowStepErrorMessage } from '../../../../../../_shared/features/ChangeWorkflowStepModal/constants/uiConstants.ts';
import { ActiveCapabilityType } from '../../../../../../_shared/models/activeCapability.type.ts';
import { ContentItemEditingEventOrigins } from '../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { logErrorToMonitoringToolWithCustomMessage } from '../../../../../../_shared/utils/logError.ts';
import { hasActiveVariantCapability } from '../../../../../../_shared/utils/permissions/activeCapabilities.ts';
import { redirectToDefaultRoute } from '../../../../../../_shared/utils/routing/redirectToDefaultRoute.ts';
import { IAssignmentUpdateServerModel } from '../../../../../../repositories/serverModels/IAssignmentServerModel.type.ts';
import { IContentItemWithVariantServerModel } from '../../../../../../repositories/serverModels/INewContentItemServerModel.ts';
import {
  IAssignment,
  convertAssignmentToServerModel,
} from '../../../../models/contentItem/Assignment.ts';
import { AssignmentSections } from '../../constants/AssignmentSections.ts';
import { IParsedItemVariant } from '../../utils/parseContentItem.ts';
import {
  submittingVariantAssignmentFailed,
  submittingVariantAssignmentFinished,
  submittingVariantAssignmentStarted,
} from '../contentItemEditingActions.ts';
import { ILoadRelatedContentItemElementsDataAction } from './loadRelatedContentItemElementsData.ts';

interface IDeps {
  readonly trackAssignmentEvents: (
    oldAssigment: IAssignment,
    newAssignment: IAssignment,
    actionOrigin: ContentItemEditingEventOrigins,
  ) => ThunkFunction;
  readonly contentItemRepository: {
    updateVariantAssignment: (
      contentItemId: Uuid,
      variantId: Uuid,
      assignment: IAssignmentUpdateServerModel,
    ) => Promise<IContentItemWithVariantServerModel>;
  };
  readonly parseContentItemVariant: (
    contentItemWithVariant: IContentItemWithVariantServerModel,
  ) => IParsedItemVariant;
  readonly loadRelatedContentItemElementsData: ILoadRelatedContentItemElementsDataAction;
}

export interface IUpdateAssignmentSectionParams {
  readonly actionOrigin: ContentItemEditingEventOrigins;
  readonly getUpdatedAssignment: () => Partial<IAssignment>;
  readonly history: History;
  readonly submittingSection: AssignmentSections;
}

export const updateAssignmentSectionActionCreator =
  (deps: IDeps) =>
  ({ getUpdatedAssignment, ...params }: IUpdateAssignmentSectionParams): ThunkPromise =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const {
      contentApp: {
        editedContentItemVariant,
        editorUi: { itemEditingModalDialog },
        listingUi: { filter, orderBy },
      },
      data: {
        listingContentItems: { usedSearchMethod },
      },
      sharedApp: { currentProjectId },
    } = getState();

    assert(
      editedContentItemVariant,
      () =>
        `updateAssignment.ts: No edited content item variant when dispatching assignment update of ${params.submittingSection} section.`,
    );

    const assignment: IAssignment = {
      ...editedContentItemVariant.assignment,
      ...getUpdatedAssignment(),
    };

    assert(
      assignment.workflowStatus,
      () =>
        'updateAssignment.ts: No workflow status in assignment when dispatching assignment submit.',
    );

    dispatch(submittingVariantAssignmentStarted(params.submittingSection));

    const { itemId, variantId } = editedContentItemVariant.id;

    const assignmentServerModel = convertAssignmentToServerModel(assignment);
    try {
      const itemWithUpdatedItemVariant = await deps.contentItemRepository.updateVariantAssignment(
        itemId,
        variantId,
        assignmentServerModel,
      );
      const variantData = deps.parseContentItemVariant(itemWithUpdatedItemVariant);
      dispatch(
        deps.trackAssignmentEvents(
          editedContentItemVariant.assignment,
          assignment,
          params.actionOrigin,
        ),
      );

      if (
        !hasActiveVariantCapability(ActiveCapabilityType.ViewContent, itemWithUpdatedItemVariant)
      ) {
        dispatch(
          submittingVariantAssignmentFinished({
            itemWithVariant: itemWithUpdatedItemVariant,
            itemVariantData: variantData,
            filter,
            orderBy,
            usedSearchMethod,
          }),
        );
        redirectToDefaultRoute({
          history: params.history,
          currentProjectId,
        });
        return;
      }

      await dispatch(
        deps.loadRelatedContentItemElementsData(
          itemId,
          variantId,
          variantData.editedContentItemVariantElements,
          null,
        ),
      );
      const compiledVariant = {
        ...variantData,
        editedContentItemVariantElements: variantData.editedContentItemVariantElements,
      };

      dispatch(
        submittingVariantAssignmentFinished({
          itemWithVariant: itemWithUpdatedItemVariant,
          itemVariantData: compiledVariant,
          filter,
          orderBy,
          usedSearchMethod,
        }),
      );
    } catch (error) {
      logErrorToMonitoringToolWithCustomMessage(
        'updateAssignment.ts: Error during updating assignment of an item variant',
        error,
      );

      if (params.submittingSection !== AssignmentSections.WorkflowStep) {
        return;
      }

      assert(
        itemEditingModalDialog.properties,
        () =>
          'updateAssignment.ts: No modal dialog properties when trying to open dialog after failed workflow step update.',
      );

      dispatch(
        submittingVariantAssignmentFailed(
          UpdateWorkflowStepErrorMessage,
          params.submittingSection,
          itemEditingModalDialog.type,
          itemEditingModalDialog.properties,
        ),
      );
    }
  };
