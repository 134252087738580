import { areShallowEqual } from '@kontent-ai/utils';
import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { Loader } from '../../../../../../../_shared/components/Loader.tsx';
import {
  ContentItemRoute,
  ContentItemRouteParams,
} from '../../../../../../../_shared/constants/routePaths.ts';
import { useDispatch } from '../../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { useThunkPromise } from '../../../../../../../_shared/hooks/useThunkPromise.ts';
import {
  matchPath,
  parseContentItemIds,
} from '../../../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { loadItemPreviewInfo } from '../../../../../../webSpotlight/actions/thunkWebSpotlightActions.ts';
import { itemPreviewInfoReloadRequested } from '../../../../../../webSpotlight/actions/webSpotlightActions.ts';
import { ContentItemPreview as ContentItemPreviewComponent } from '../components/ContentItemPreview.tsx';
import { usePreviewSpace } from '../context/PreviewSpaceContext.tsx';
import { ContentItemPreviewActionBar } from './ContentItemPreviewActionBar.tsx';

export const ContentItemPreview = () => {
  const { spaceId } = usePreviewSpace();

  const location = useLocation();
  const dispatch = useDispatch();
  const itemPreviewInfo = useSelector((state) => state.webSpotlightApp.itemPreviewInfo);
  const isPreviewConfigurationLoaded = useSelector(
    (state) => !!state.contentApp.previewConfiguration,
  );

  const currentPath = location.pathname;
  const matchedParams = matchPath<ContentItemRouteParams<string>>(currentPath, ContentItemRoute);
  const routeContentItemIds = matchedParams
    ? parseContentItemIds(matchedParams.contentItemIds)
    : [];

  const isCorrectPreviewInfoLoaded =
    areShallowEqual(itemPreviewInfo?.routeContentItemIds, routeContentItemIds) &&
    spaceId === itemPreviewInfo?.previewUrlInfo.spaceId;

  useEffect(() => {
    dispatch(itemPreviewInfoReloadRequested());
  }, []);

  useThunkPromise(loadItemPreviewInfo, routeContentItemIds, spaceId, {
    canRun: !isCorrectPreviewInfoLoaded && isPreviewConfigurationLoaded,
  });

  const isLoading =
    !itemPreviewInfo ||
    !isCorrectPreviewInfoLoaded ||
    !isPreviewConfigurationLoaded ||
    !itemPreviewInfo.previewContentItemInfo?.contentItemId;

  return (
    <>
      {!!itemPreviewInfo?.previewUrlInfo && <ContentItemPreviewActionBar spaceId={spaceId} />}
      {isLoading ? (
        <div css="grid-area: preview;">
          <Loader />
        </div>
      ) : (
        <ContentItemPreviewComponent
          contentItemId={itemPreviewInfo.previewContentItemInfo.contentItemId}
          spaceId={spaceId}
        />
      )}
    </>
  );
};
