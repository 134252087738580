import { Collection } from '@kontent-ai/utils';
import { Action } from '../../../../../@types/Action.type.ts';
import { logErrorMessageToMonitoringTool } from '../../../../../_shared/utils/logError.ts';
import { Data_Collections_Success } from '../../../../../data/constants/dataActionTypes.ts';
import {
  CollectionsMap,
  ICollection,
  createCollectionsFromServerModel,
  emptyCollection,
} from '../../../../../data/models/collections/Collection.ts';
import {
  Collections_Creator_Submitted,
  Collections_Editor_Deleted,
  Collections_Editor_Submitted,
  Collections_Listing_Left,
  Collections_Save_Finished,
} from '../../constants/actionTypes.ts';

const emptyState: CollectionsMap = new Map();

export const editedCollections = (state = emptyState, action: Action): CollectionsMap => {
  switch (action.type) {
    case Collections_Creator_Submitted: {
      const newCollection: ICollection = {
        ...emptyCollection,
        id: action.payload.collectionId,
        name: action.payload.collectionName,
      };

      return Collection.add(state, [newCollection.id, newCollection]);
    }

    case Collections_Editor_Submitted: {
      const { collectionId, collectionName } = action.payload;
      const collection = state.get(collectionId);
      if (!collection) {
        logErrorMessageToMonitoringTool(
          `Collections_Editor_Submitted: Unknown collection ${collectionId}`,
        );
        return state;
      }

      const updatedCollection: ICollection = {
        ...collection,
        name: collectionName,
      };

      return Collection.add(state, [collectionId, updatedCollection]);
    }

    case Collections_Editor_Deleted: {
      const { collectionId } = action.payload;

      return Collection.remove(state, collectionId);
    }

    case Collections_Save_Finished:
    case Data_Collections_Success:
      return new Map(
        createCollectionsFromServerModel(action.payload).collections.map((c) => [c.id, c]),
      );

    case Collections_Listing_Left:
      return emptyState;

    default:
      return state;
  }
};
