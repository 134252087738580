import { History } from 'history';
import { Dispatch, GetState, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import {
  EditContentTypeRoute,
  EditContentTypeRouteParams,
} from '../../../../../_shared/constants/routePaths.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import { TrackUserEventAction } from '../../../../../_shared/models/TrackUserEvent.type.ts';
import { ContentTypeDuplicatedEventData } from '../../../../../_shared/models/TrackUserEventData.ts';
import { logErrorToMonitoringToolWithCustomMessage } from '../../../../../_shared/utils/logError.ts';
import { buildPath } from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { getContentTypeFromServerModel } from '../../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { IContentTypeRepository } from '../../../../../repositories/interfaces/IContentTypeRepository.type.ts';
import { IBaseTypeElementData } from '../../../shared/models/elements/types/TypeElementData.ts';
import { getContentTypeConversionOptions } from '../../../shared/selectors/contentTypeElementSelector.ts';
import {
  contentTypeDuplicatingFailed,
  contentTypeDuplicatingFinished,
  contentTypeDuplicatingStarted,
} from '../contentTypesActions.ts';

interface IDeps {
  readonly contentTypeRepository: IContentTypeRepository;
  readonly getContentTypeDuplicatedEventData: (
    elements: ReadonlyArray<IBaseTypeElementData>,
  ) => ContentTypeDuplicatedEventData;
  readonly trackUserEvent: TrackUserEventAction;
}

export const createDuplicateContentTypeAction =
  (deps: IDeps) =>
  (history: History): ThunkPromise =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const {
      sharedApp: { currentProjectId },
      contentModelsApp: { typeEditor },
    } = getState();

    const contentTypeId = typeEditor.editedType.id;

    dispatch(contentTypeDuplicatingStarted());

    try {
      const duplicatedTypeModel =
        await deps.contentTypeRepository.duplicateContentType(contentTypeId);
      const duplicatedType = getContentTypeFromServerModel(
        duplicatedTypeModel,
        getContentTypeConversionOptions(getState()),
      );

      dispatch(contentTypeDuplicatingFinished(duplicatedType));
      dispatch(
        deps.trackUserEvent(
          TrackedEvent.ContentTypeDuplicated,
          deps.getContentTypeDuplicatedEventData(duplicatedType.typeElements),
        ),
      );

      history.push(
        buildPath<EditContentTypeRouteParams>(EditContentTypeRoute, {
          projectId: currentProjectId,
          contentTypeId: duplicatedType.id,
        }),
      );
    } catch (e) {
      dispatch(contentTypeDuplicatingFailed());
      logErrorToMonitoringToolWithCustomMessage(
        `Duplicating type with id: '${contentTypeId}' failed.`,
        e,
      );
    }
  };
