import { debounce1Args } from './func/debounce.ts';
import { logErrorMessageToMonitoringTool } from './logError.ts';

enum State {
  Running = 'Running',
  RunningAndQueued = 'RunningAndQueued',
  Waiting = 'Waiting',
}

type RunnerState<T> =
  | {
      readonly state: State.Waiting;
    }
  | {
      readonly state: State.Running;
    }
  | {
      readonly state: State.RunningAndQueued;
      readonly queuedData: T;
    };

// See docs: https://kontent-ai.atlassian.net/wiki/spaces/KTD/pages/350519406/Autodispatcher
export const createAutoDispatcherRunner = <T>(
  runEffect: (data: T) => Promise<unknown>,
  debounceTime: number,
  onQueuedDataCancelled?: (data: T) => void,
) => {
  class AutoDispatcherRunner {
    private _runnerState: RunnerState<T> = { state: State.Waiting };

    private readonly _run = debounce1Args((data: T) => {
      switch (this._runnerState.state) {
        case State.RunningAndQueued: {
          const cancelledData = this._runnerState.queuedData;
          this._runnerState = {
            state: State.RunningAndQueued,
            queuedData: data,
          };
          onQueuedDataCancelled?.(cancelledData);
          return;
        }
        case State.Running: {
          this._runnerState = {
            state: State.RunningAndQueued,
            queuedData: data,
          };
          return;
        }
        case State.Waiting: {
          const finished = (): Promise<unknown> => {
            switch (this._runnerState.state) {
              case State.RunningAndQueued: {
                const { queuedData } = this._runnerState;
                this._runnerState = { state: State.Running };
                return runEffect(queuedData).then(() => finished());
              }
              case State.Running: {
                this._runnerState = { state: State.Waiting };
                return Promise.resolve();
              }
              default: {
                logErrorMessageToMonitoringTool(
                  `${finished.name}: Unexpected state (${JSON.stringify(this._runnerState)}).`,
                );
                return Promise.resolve();
              }
            }
          };

          this._runnerState = { state: State.Running };
          runEffect(data)
            .then(() => finished())
            .catch(() => {
              // When the callback fails, we need to clear the state back to Waiting. Otherwise,
              // it would never dispatch again after the callback fails once. For more info, see KCL-11258.
              if (this._runnerState.state === State.Running) {
                this._runnerState = { state: State.Waiting };
              }
            });

          return;
        }
      }
    }, debounceTime);

    private _pendingData: T | null = null;

    public run = (data: T): void => {
      const cancelledData = this._getCancelledData();

      this._pendingData = data;
      this._run(data);

      if (cancelledData) {
        onQueuedDataCancelled?.(cancelledData);
      }
    };

    public cancel = (): void => {
      const cancelledData = this._getCancelledData();

      this._pendingData = null;
      this._run.cancel();

      if (cancelledData) {
        onQueuedDataCancelled?.(cancelledData);
      }
    };

    public flush = (): void => {
      this._run.now();
      this._pendingData = null;
    };

    private readonly _getCancelledData = (): T | null => {
      const isPending = this._run.isPending();
      if (isPending && this._pendingData) {
        return this._pendingData;
      }

      return null;
    };
  }

  return new AutoDispatcherRunner();
};
