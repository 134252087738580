import { MiddlewareAPI } from '@reduxjs/toolkit';
import { Action } from '../../../@types/Action.type.ts';
import { Dispatch } from '../../../@types/Dispatcher.type.ts';
import { ContentModels_TypeEditor_Left } from '../../../applications/contentModels/shared/constants/sharedContentModelsActionTypes.ts';
import {
  Content_Editing_AssignmentSubmittingStarted,
  Content_Editing_CurrentItemOverwritten,
  Content_Editing_ModalDismissed,
  Content_Editing_ModalOpened,
  Content_Editing_ModalOpenedWithProperties,
} from '../../../applications/itemEditor/constants/editorActionTypes.ts';
import {
  ContentItemEditing_Action_Activated,
  ContentItemEditing_Action_Deactivated,
  ContentItemEditing_AssignmentChange_Abandoned,
  ContentItemEditing_CreateTask_Started,
  ContentItemEditing_TaskEditing_Cancelled,
} from '../../../applications/itemEditor/features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';
import { ContentItemEditing_NewVariant_Started } from '../../../applications/itemEditor/features/NewVariantWorkflowSelectionDialog/constants/newVariantActionTypes.ts';
import { isOverwrittenToRegularStep } from '../../../applications/itemEditor/utils/itemOverwriteUtils.ts';
import {
  Shared_Editor_Left,
  Shared_ModalDismissed,
  Shared_ModalOpened,
  Shared_ModalOpenedWithProperties,
} from '../../constants/sharedActionTypes.ts';
import { ActiveCapabilityType } from '../../models/activeCapability.type.ts';
import { projectNotificationService } from '../../services/projectNotificationService.ts';
import { logErrorToMonitoringTool } from '../../utils/logError.ts';
import { IStore } from '../IStore.type.ts';

export const notificationMiddleware = ({ getState }: MiddlewareAPI<Dispatch, IStore>) => {
  return (next: Dispatch) =>
    (action: Action): Action => {
      try {
        switch (action.type) {
          case Content_Editing_AssignmentSubmittingStarted:
          case ContentItemEditing_Action_Activated:
          case ContentItemEditing_Action_Deactivated:
          case ContentItemEditing_AssignmentChange_Abandoned:
          case ContentItemEditing_CreateTask_Started:
          case ContentItemEditing_TaskEditing_Cancelled:
          case ContentModels_TypeEditor_Left:
          case Shared_Editor_Left:
          case Shared_ModalDismissed:
          case ContentItemEditing_NewVariant_Started:
          case Content_Editing_ModalDismissed:
          case Content_Editing_ModalOpenedWithProperties:
          case Content_Editing_ModalOpened:
          case Shared_ModalOpenedWithProperties:
          case Shared_ModalOpened: {
            const {
              contentApp: {
                editorUi: { lockedElements },
              },
              data: { user },
            } = getState();

            projectNotificationService.releaseElementLockForUser(lockedElements, user.info.userId);
            break;
          }

          case Content_Editing_CurrentItemOverwritten: {
            const {
              contentApp: {
                editorUi: { lockedElements },
              },
              data: { user },
            } = getState();

            const workflowStepOverwritten = action.payload.params.difference.workflowStep;
            if (workflowStepOverwritten) {
              const canEditElementsInNewWorkflowStep =
                isOverwrittenToRegularStep(workflowStepOverwritten) &&
                workflowStepOverwritten.variantCapabilities.includes(
                  ActiveCapabilityType.UpdateContent,
                );

              if (!canEditElementsInNewWorkflowStep) {
                projectNotificationService.releaseElementLockForUser(
                  lockedElements,
                  user.info.userId,
                );
              }
            }
            break;
          }

          default:
            break;
        }
      } catch (ex) {
        logErrorToMonitoringTool(ex);
      }

      return next(action);
    };
};
