import { Direction } from '@kontent-ai/types';
import { DraftDecorator } from 'draft-js';
import React, { PropsWithChildren, useCallback, useRef, useState } from 'react';
import { logErrorMessageToMonitoringTool } from '../../../../_shared/utils/logError.ts';
import { useEditorApi } from '../../editorCore/hooks/useEditorApi.ts';
import { useEditorWithPlugin } from '../../editorCore/hooks/useEditorWithPlugin.tsx';
import { CanUpdateContent, IsEditorLocked } from '../../editorCore/types/Editor.base.type.ts';
import { PluginComponent } from '../../editorCore/types/Editor.composition.type.ts';
import { Apply, Render } from '../../editorCore/types/Editor.plugins.type.ts';
import {
  EditorChangeReason,
  internalChangeReasons,
} from '../../editorCore/types/EditorChangeReason.ts';
import { Decorator, decorable } from '../../editorCore/utils/decorable.ts';
import { OnCloseModal } from '../ModalsPlugin.tsx';
import { GetInitialState, RemoveInvalidState } from '../draftJs/DraftJsPlugin.type.ts';
import { EntityDecoratorProps } from '../entityApi/api/editorEntityUtils.ts';
import {
  CancelNewLink,
  EditLink,
  GetLinkOptions,
  GetUnknownLinkEntityComponent,
  LinkEditingCancelled,
  LinkEditingFinished,
  LinksPlugin as LinksPluginType,
} from './LinksPlugin.type.ts';
import { isLink, isLinkPlaceholder, isNewLink } from './api/LinkEntity.ts';
import { editorLinkApi } from './api/editorLinkApi.ts';
import { findLinks, getLinkType, isLinkAtSelection } from './api/editorLinkUtils.ts';
import { renderLinkToolbarButton } from './components/buttons/LinkToolbarButton.tsx';

const getLinkOptions: GetLinkOptions = () => [];

type LinkCustomProps = {
  readonly getLinkEntityComponentRef: React.RefObject<GetUnknownLinkEntityComponent>;
};

const LinkEntity = (props: PropsWithChildren<EntityDecoratorProps<LinkCustomProps>>) => {
  const { getLinkEntityComponentRef, ...entityProps } = props;

  const contentState = props.contentState;
  const entity = contentState.getEntity(props.entityKey);
  const linkType = getLinkType(entity);

  const componentResult = linkType && getLinkEntityComponentRef.current?.(linkType);
  if (componentResult) {
    return componentResult.component({
      ...entityProps,
      ...componentResult.props,
    });
  }

  if (linkType) {
    logErrorMessageToMonitoringTool(
      `Cannot find link entity component for link type '${linkType}'. Make sure that a plugin for this link type is registered and decorates method getLinkEntityComponent.`,
    );
  }
  return props.children;
};

type LinksPlugin = LinksPluginType;

export const LinksPlugin: PluginComponent<LinksPlugin> = (props) => {
  const { disabled } = props;

  // We need to access methods using state callbacks via handle to editor component
  // as we need to pass them to the decorator, and they are not yet available in the init phase
  const getLinkEntityComponentRef = useRef<GetUnknownLinkEntityComponent | null>(null);

  const [editedLinkEntityKey, setEditedLinkEntityKey] = useState<string | null>(null);
  const linkEditingFinished: LinkEditingFinished = useCallback(
    () => setEditedLinkEntityKey(null),
    [],
  );

  const isEditorLocked: Decorator<IsEditorLocked> = useCallback(
    (baseIsEditorLocked) => () => !!editedLinkEntityKey || baseIsEditorLocked(),
    [editedLinkEntityKey],
  );

  const canUpdateContent: Decorator<CanUpdateContent> = useCallback(
    (baseCanUpdateContent) => (changeReason) =>
      // Even reviewer can add comments
      internalChangeReasons.has(changeReason ?? EditorChangeReason.Regular)
        ? baseCanUpdateContent(changeReason)
        : !editedLinkEntityKey && baseCanUpdateContent(changeReason),
    [editedLinkEntityKey],
  );

  const renderInlineToolbarButtons: Decorator<Render<LinksPlugin>> = useCallback(
    (baseRenderInlineToolbarContent) => (state) => {
      const linkToolbarButton = renderLinkToolbarButton(state, !!disabled);
      if (!linkToolbarButton) {
        return baseRenderInlineToolbarContent(state);
      }

      return (
        <>
          {baseRenderInlineToolbarContent(state)}
          {linkToolbarButton}
        </>
      );
    },
    [disabled],
  );

  const getInitialState: Decorator<GetInitialState> = useCallback(
    (baseGetInitialState) => (initialEditorState) => {
      const state = baseGetInitialState(initialEditorState);

      const linkCustomProps: LinkCustomProps = { getLinkEntityComponentRef };
      const linkDecorator: DraftDecorator<LinkCustomProps> = {
        strategy: findLinks,
        component: LinkEntity,
        props: linkCustomProps,
      };

      return {
        ...state,
        decorators: [...state.decorators, linkDecorator],
      };
    },
    [],
  );

  const apply: Apply<LinksPlugin> = useCallback(
    (state) => {
      state.getInitialState.decorate(getInitialState);
      state.renderInlineToolbarButtons.decorate(renderInlineToolbarButtons);
      state.isEditorLocked.decorate(isEditorLocked);
      state.canUpdateContent.decorate(canUpdateContent);

      const editLink: EditLink = async (entityKey) => {
        if (!state.canUpdateContent(EditorChangeReason.Internal)) {
          return;
        }

        await state.executeChange((editorState) => {
          const newEditorState = state.getApi().forceSelectionToEntity(editorState, entityKey);
          if (newEditorState !== editorState) {
            setEditedLinkEntityKey(entityKey);
          }
          return newEditorState;
        }, EditorChangeReason.Internal);
      };

      const linkEditingCancelled: LinkEditingCancelled = (entityKey) => {
        if (!state.canUpdateContent(EditorChangeReason.Internal)) {
          return;
        }

        state.executeChange((editorState) => {
          const newEditorState = state.getApi().forceSelectionToEntity(editorState, entityKey);
          linkEditingFinished();
          return newEditorState;
        }, EditorChangeReason.Internal);
      };

      const cancelNewLink: CancelNewLink = (entityKey, isPlaceholder) => {
        if (!state.canUpdateContent(EditorChangeReason.Internal)) {
          return;
        }

        state.executeChange((editorState) => {
          const selection = state.getApi().getSelectionForEntity(editorState, entityKey);
          if (selection && !selection.isCollapsed()) {
            const newEditorState = isPlaceholder
              ? state.getApi().handleDeleteAtSelection(editorState, selection, Direction.Backward)
                  .editorState
              : state.getApi().unlink(editorState, selection);

            linkEditingFinished();
            return newEditorState;
          }
          return editorState;
        }, EditorChangeReason.Internal);
      };

      const onCloseModal: Decorator<OnCloseModal> = (baseOnCloseModal) => () => {
        if (editedLinkEntityKey) {
          const editorState = state.getEditorState();
          const content = editorState.getCurrentContent();
          const entity = content.getEntity(editedLinkEntityKey);
          if (isLink(entity)) {
            const isNew = isNewLink(entity);

            if (isNew) {
              const isPlaceholder = isLinkPlaceholder(entity);
              cancelNewLink(editedLinkEntityKey, isPlaceholder);
            } else {
              linkEditingCancelled(editedLinkEntityKey);
            }
            return true;
          }
        }
        return baseOnCloseModal();
      };

      state.onCloseModal.decorate(onCloseModal);

      const unlinkAtSelection = (): void => {
        if (state.canUpdateContent()) {
          state.executeChange((editorState) => {
            const selection = editorState.getSelection();
            const content = editorState.getCurrentContent();
            if (isLinkAtSelection(content, selection)) {
              return state.getApi().unlink(editorState, selection);
            }
            return editorState;
          });
        }
      };

      const unlink = (entityKey: string): void => {
        if (state.canUpdateContent(EditorChangeReason.Internal)) {
          state.executeChange((editorState) => {
            const selection = state.getApi().getSelectionForEntity(editorState, entityKey);
            if (selection) {
              const newEditorState = state.getApi().unlink(editorState, selection);
              linkEditingFinished();

              return newEditorState;
            }
            return editorState;
          }, EditorChangeReason.Internal);
        }
      };

      const getLinkEntityComponent = decorable<GetUnknownLinkEntityComponent>(() => null);
      getLinkEntityComponentRef.current = getLinkEntityComponent;

      const removeInvalidState: Decorator<RemoveInvalidState> =
        (baseRemoveInvalidState) => (editorState) => {
          if (editedLinkEntityKey) {
            const content = editorState.getCurrentContent();
            const entity = content.getEntity(editedLinkEntityKey);
            if (!isLink(entity)) {
              linkEditingFinished();
              return;
            }

            const linkSelection = state
              .getApi()
              .getSelectionForEntity(editorState, editedLinkEntityKey);
            if (!linkSelection) {
              linkEditingFinished();
            }
          }
          baseRemoveInvalidState(editorState);
        };

      state.removeInvalidState.decorate(removeInvalidState);

      return {
        cancelNewLink,
        editedLinkEntityKey,
        editLink,
        getLinkEntityComponent,
        getLinkOptions: decorable(getLinkOptions),
        linkEditingCancelled,
        linkEditingFinished,
        setEditedLinkEntityKey,
        unlink,
        unlinkAtSelection,
      };
    },
    [
      canUpdateContent,
      editedLinkEntityKey,
      getInitialState,
      isEditorLocked,
      linkEditingFinished,
      renderInlineToolbarButtons,
    ],
  );

  const { getApiMethods } = useEditorApi<LinksPlugin>(editorLinkApi);

  return useEditorWithPlugin(props, { apply, getApiMethods });
};
