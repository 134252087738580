import { logErrorMessageToMonitoringTool } from '../../../../_shared/utils/logError.ts';
import { WorkflowStepColor } from '../../../constants/WorkflowStepColor.ts';

const workflowStepColors: ReadonlySet<WorkflowStepColor> = new Set(
  Object.values(WorkflowStepColor),
);

const isWorkflowStepColor = (color: string): color is WorkflowStepColor => {
  return workflowStepColors.has(color as WorkflowStepColor);
};

export const getWorkflowStepColorFromServerString = (serverColor: string): WorkflowStepColor => {
  if (isWorkflowStepColor(serverColor)) {
    return serverColor;
  }

  logErrorMessageToMonitoringTool(
    `Could not translate workflow step color '${serverColor}' from the server color to the client model.`,
  );

  return WorkflowStepColor.Gray;
};
