import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { PreviewError } from '../../../utils/previewUtils.ts';
import { ItemEditingLayout as ItemEditingLayoutComponent } from '../components/ItemEditingLayout.tsx';
import { useNavigationTree } from '../features/contentItemPreview/context/NavigationTreeContext.tsx';
import {
  PreviewMetadata,
  WebSpotlightInItemEditingContextProvider,
} from '../features/contentItemPreview/context/WebSpotlightInItemEditingContext.tsx';
import { useIsLivePreviewOpen } from '../hooks/useIsLivePreviewOpen.ts';

export const ItemEditingLayout = () => {
  const isPreviewOpen = useIsLivePreviewOpen();
  const { isTreeOpened } = useNavigationTree();

  const itemPreviewInfo = useSelector((state) => state.webSpotlightApp.itemPreviewInfo);

  return (
    <WebSpotlightInItemEditingContextProvider
      previewMetadata={
        itemPreviewInfo?.previewUrlInfo.url
          ? {
              error: itemPreviewInfo.previewUrlInfo.error,
              url: itemPreviewInfo.previewUrlInfo.url,
              usedUrlSlug: itemPreviewInfo.previewUrlInfo.usedUrlSlug,
            }
          : noPreviewUrlMetadata
      }
    >
      <ItemEditingLayoutComponent isLivePreviewOpen={isPreviewOpen} isTreeOpen={isTreeOpened} />
    </WebSpotlightInItemEditingContextProvider>
  );
};

const noPreviewUrlMetadata: PreviewMetadata = {
  url: '',
  usedUrlSlug: null,
  error: PreviewError.NoPreview,
};
