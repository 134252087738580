import { Collection } from '@kontent-ai/utils';
import Immutable from 'immutable';
import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import { TrackUserEventAction } from '../../../../../_shared/models/TrackUserEvent.type.ts';
import { AssetBulkActionEventTypes } from '../../../../../_shared/models/TrackUserEventData.ts';
import { logErrorMessageToMonitoringTool } from '../../../../../_shared/utils/logError.ts';
import { AssetsRequestTrigger } from '../../../../../_shared/utils/scrollGridUtils.ts';
import { IAsset } from '../../../../../data/models/assets/Asset.ts';
import {
  IMoveAssetItemToCollectionServerModel,
  IMoveAssetsToCollectionResultServerModel,
} from '../../../../../repositories/serverModels/AssetServerModels.type.ts';
import {
  AssetLibrary_Assets_MoveToCollectionCompleted,
  AssetLibrary_Assets_MoveToCollectionStarted,
  AssetLibrary_Assets_MovingToCollectionFailed,
} from '../../constants/assetLibraryActionTypes.ts';
import { LoadListingAssets } from '../../types/depsTypes.type.ts';
import { CollectionReference } from '../../types/lastAssetsAction.ts';
import { AssetsOrderBy } from '../../types/orderBy.type.ts';

interface IDeps {
  readonly assetService: {
    readonly moveAssetsToCollection: (
      items: ReadonlyArray<IMoveAssetItemToCollectionServerModel>,
    ) => Promise<IMoveAssetsToCollectionResultServerModel>;
  };
  readonly loadListingAssets: LoadListingAssets;
  readonly trackUserEvent: TrackUserEventAction;
}

const started = () =>
  ({
    type: AssetLibrary_Assets_MoveToCollectionStarted,
  }) as const;

const completed = (successfulIds: ReadonlyMap<Uuid, CollectionReference | null>) =>
  ({
    type: AssetLibrary_Assets_MoveToCollectionCompleted,
    payload: {
      successfulIds,
    },
  }) as const;

const failed = (errorMessage: string) =>
  ({
    type: AssetLibrary_Assets_MovingToCollectionFailed,
    payload: { errorMessage },
  }) as const;

export type MoveSelectedAssetsToCollectionActionsType = ReturnType<
  typeof started | typeof completed | typeof failed
>;

type Args = {
  readonly assetsById: Immutable.Map<Uuid, IAsset>;
  readonly orderBy: AssetsOrderBy;
  readonly selectedAssets: ReadonlySet<Uuid>;
  readonly targetCollectionId: Uuid | null;
};

export const createMoveSelectedAssetsToCollectionAction =
  (deps: IDeps) =>
  ({ assetsById, orderBy, selectedAssets, targetCollectionId }: Args): ThunkPromise =>
  async (dispatch): Promise<void> => {
    dispatch(started());

    const getAsset = (id: Uuid): IAsset | null => {
      const asset = assetsById.get(id);

      if (!asset) {
        logErrorMessageToMonitoringTool(`Asset with id '${id}' was not found in data store`);
        return null;
      }

      return asset;
    };

    try {
      const itemsToMove = Array.from(selectedAssets)
        .filter((id) => {
          const asset = getAsset(id);

          return asset && asset.collectionId !== targetCollectionId;
        })
        .map(
          (assetId: Uuid): IMoveAssetItemToCollectionServerModel => ({
            assetId,
            targetCollectionId,
          }),
        );

      const result = await deps.assetService.moveAssetsToCollection(itemsToMove);
      const movedAssetsIds = Collection.filter(selectedAssets, (assetId) =>
        result.movedAssetsIds.includes(assetId),
      );

      const successfulIds = Array.from(movedAssetsIds).reduce<
        ReadonlyArray<[Uuid, CollectionReference | null]>
      >((reduced, assetId) => {
        const asset = getAsset(assetId);

        return asset
          ? [...reduced, [assetId, asset.collectionId ? { id: asset.collectionId } : null]]
          : reduced;
      }, []);

      dispatch(completed(new Map(successfulIds)));
      dispatch(
        deps.trackUserEvent(TrackedEvent.AssetBulkAction, {
          action: AssetBulkActionEventTypes.MovedToCollection,
          count: movedAssetsIds.size,
        }),
      );

      await dispatch(
        deps.loadListingAssets({
          orderBy,
          requestTrigger: AssetsRequestTrigger.FetchAfterBulkAction,
        }),
      );
    } catch (error) {
      dispatch(failed('Assets could not be moved.'));
      throw error;
    }
  };
