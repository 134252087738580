import { Box } from '@kontent-ai/component-library/Box';
import { Callout } from '@kontent-ai/component-library/Callout';
import { AnimatedProgressIcon } from '@kontent-ai/component-library/Icons';
import { SimpleStatusDefault } from '@kontent-ai/component-library/SimpleStatus';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing, gridUnit, px } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { ModalDialog } from '../../../../../../component-library/components/Dialogs/ModalDialog/ModalDialog.tsx';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { MinDialogWidth } from '../../../../projectSettings/environments/constants/uiConstants.ts';

type VariantTranslationDialogProps = {
  readonly disabledStopButtonTooltipText: string | null;
  readonly isOpen: boolean;
  readonly sourceLanguageName: string | null;
  readonly onTranslationCancel: () => Promise<void>;
};

export const VariantTranslationDialog: React.FC<VariantTranslationDialogProps> = ({
  disabledStopButtonTooltipText,
  isOpen,
  sourceLanguageName,
  onTranslationCancel,
}) => {
  return (
    <ModalDialog
      isDismissable
      isOpen={isOpen}
      minWidth={px(MinDialogWidth).toString()}
      headline="Translating content with AI"
      extraAction={{
        text: 'Stop the translation',
        onClick: onTranslationCancel,
        disabled: !!disabledStopButtonTooltipText,
        tooltipText: disabledStopButtonTooltipText,
        ...getDataUiActionAttribute(DataUiAction.Delete),
      }}
    >
      <Box width={70 * gridUnit} paddingBottom={Spacing.S}>
        <Stack spacing={Spacing.XL}>
          <Stack spacing={Spacing.S}>
            <SimpleStatusDefault
              label="Your content is being translated."
              icon={AnimatedProgressIcon}
            />
          </Stack>
          <p>
            Take a break or keep working on other things – your translation will continue in the
            background even if you close this window.
          </p>
          <Callout calloutType="quickTip">
            <p>
              This action replaces all text content and copies non-text content from{' '}
              {sourceLanguageName ?? 'the source language'}. Components will require translation
              later.
            </p>
            <p>You can always access previous versions in the version history.</p>
          </Callout>
        </Stack>
      </Box>
    </ModalDialog>
  );
};

VariantTranslationDialog.displayName = 'VariantTranslationDialog';
