import { Dispatch, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { ApiStatus } from '../../../../../_shared/models/ApiStatus.ts';
import { logErrorToMonitoringToolWithCustomMessage } from '../../../../../_shared/utils/logError.ts';
import {
  Environment_ContentManagementApiActivation_Finished,
  Environment_ContentManagementApiDeactivation_Finished,
} from '../../constants/generalSettingsActionTypes.ts';

interface ISwitchManagementApiStatusDeps {
  readonly projectRepository: {
    readonly activateContentManagementApiKey: () => Promise<void>;
    readonly deactivateContentManagementApiKey: () => Promise<void>;
  };
}

const activationFinished = (environmentId: Uuid) =>
  ({
    type: Environment_ContentManagementApiActivation_Finished,
    payload: {
      environmentId,
    },
  }) as const;

const deactivationFinished = (environmentId: Uuid) =>
  ({
    type: Environment_ContentManagementApiDeactivation_Finished,
    payload: {
      environmentId,
    },
  }) as const;

export type SwitchManagementApiStatusActionsType = ReturnType<
  typeof activationFinished | typeof deactivationFinished
>;

export const switchManagementApiStatusCreator = (deps: ISwitchManagementApiStatusDeps) => {
  return (environmentId: Uuid, newStatus: ApiStatus): ThunkPromise => {
    return async (dispatch: Dispatch): Promise<void> => {
      try {
        if (newStatus === ApiStatus.Enabled) {
          await deps.projectRepository.activateContentManagementApiKey();
          dispatch(activationFinished(environmentId));
        } else {
          await deps.projectRepository.deactivateContentManagementApiKey();
          dispatch(deactivationFinished(environmentId));
        }
      } catch (error) {
        logErrorToMonitoringToolWithCustomMessage(
          `switchManagementApiStatus.ts: Failed to ${
            newStatus === ApiStatus.Enabled ? 'activate' : 'deactivate'
          } ManagementApiStatus.`,
          error,
        );
      }
    };
  };
};
