import { Dispatch, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { ApiStatus } from '../../../../../_shared/models/ApiStatus.ts';
import { logErrorToMonitoringToolWithCustomMessage } from '../../../../../_shared/utils/logError.ts';
import {
  Environment_DeliveryApiSecureAccessActivation_Finished,
  Environment_DeliveryApiSecureAccessDeactivation_Finished,
} from '../../constants/generalSettingsActionTypes.ts';

interface ISwitchSecuredDeliveryApiStatusDeps {
  readonly projectRepository: {
    readonly activateDeliveryApiSecuredAccess: () => Promise<void>;
    readonly deactivateDeliveryApiSecuredAccess: () => Promise<void>;
  };
}

const activationFinished = (environmentId: Uuid) =>
  ({
    type: Environment_DeliveryApiSecureAccessActivation_Finished,
    payload: {
      environmentId,
    },
  }) as const;

const deactivationFinished = (environmentId: Uuid) =>
  ({
    type: Environment_DeliveryApiSecureAccessDeactivation_Finished,
    payload: {
      environmentId,
    },
  }) as const;

export type SwitchSecuredDeliveryApiStatusActionsType = ReturnType<
  typeof activationFinished | typeof deactivationFinished
>;

export const switchSecuredDeliveryApiStatusCreator = (
  deps: ISwitchSecuredDeliveryApiStatusDeps,
) => {
  return (environmentId: Uuid, newStatus: ApiStatus): ThunkPromise => {
    return async (dispatch: Dispatch): Promise<void> => {
      try {
        if (newStatus === ApiStatus.Enabled) {
          await deps.projectRepository.activateDeliveryApiSecuredAccess();
          dispatch(activationFinished(environmentId));
        } else {
          await deps.projectRepository.deactivateDeliveryApiSecuredAccess();
          dispatch(deactivationFinished(environmentId));
        }
      } catch (error) {
        logErrorToMonitoringToolWithCustomMessage(
          `switchSecuredDeliveryApiStatus.ts: Failed to ${
            newStatus === ApiStatus.Enabled ? 'activate' : 'deactivate'
          } SecuredDeliveryApiStatus.`,
          error,
        );
      }
    };
  };
};
