import { InvariantException } from '@kontent-ai/errors';
import { assert, Collection } from '@kontent-ai/utils';
import { Dispatch, GetState, ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { trackUserEvent } from '../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import { DefaultVariantId } from '../../../../../../_shared/constants/variantIdValues.ts';
import {
  ContentItemId,
  MemoizedContentItemId,
} from '../../../../../../_shared/models/ContentItemId.ts';
import { TrackUserEventAction } from '../../../../../../_shared/models/TrackUserEvent.type.ts';
import {
  ContentItemEditingChangeAction,
  ContentItemEditingEventOrigins,
} from '../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import {
  getMemoizedContentItemId,
  memoizeContentItemId,
} from '../../../../../../_shared/models/utils/contentItemIdUtils.ts';
import { getSelectedLanguageIdOrThrow } from '../../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { logErrorToMonitoringToolWithCustomMessage } from '../../../../../../_shared/utils/logError.ts';
import { ItemListOperationResultServerModel } from '../../../../../../repositories/serverModels/IListingContentItemServerModel.type.ts';
import { IContentItemWithVariantServerModel } from '../../../../../../repositories/serverModels/INewContentItemServerModel.ts';
import { IContentItemListScheduledPublishQueryModel } from '../../../../../contentInventory/content/models/filter/ContentItemListScheduleModels.type.ts';
import { ItemEditingModalDialogType } from '../../../../constants/itemEditingModalDialogType.ts';
import {
  IContentItemVariantReference,
  getContentItemVariantReferenceFromListingItem,
} from '../../../../models/contentItem/ContentItemVariantReference.ts';
import { getModalDialogActionOrigin } from '../../../../selectors/getModalDialogActionOrigin.ts';
import { IParsedItemVariant } from '../../../ContentItemEditing/utils/parseContentItem.ts';
import { prepareWorkflowStepTrackingDataWithFeatures } from '../../../ContentItemEditing/utils/prepareWorkflowStepTrackingData.ts';
import { ILoadListingItemsAction } from '../../../LoadedItems/actions/thunks/loadListingItems.ts';
import {
  ContentEditing_CascadeModal_SchedulingFailed,
  ContentEditing_CascadeModal_SchedulingFinished,
  ContentEditing_CascadeModal_SchedulingStarted,
} from '../../constants/cascadeModalActionTypes.ts';
import { CascadeSchedulingErrorMessage } from '../../constants/uiConstants.ts';
import { getFirstLevelChildrenItemIds } from '../../selectors/getFirstLevelChildrenItemIds.ts';
import {
  getItemsAvailableForSelection,
  getLoadedTransitiveDependencies,
  getSelectedItems,
} from '../../selectors/getSelectedItems.ts';
import {
  callBulkActionInChunks,
  emptyListOperationResult,
} from '../../utils/callBulkActionInChunks.ts';
import { getCannotPublishReasonForContext } from '../../utils/getCannotPublishReason.ts';
import { groupItemIdsByVariantId } from '../../utils/groupContentItemIds.ts';

const EditedItemCount = 1;

interface IDeps {
  readonly loadListingItems: ILoadListingItemsAction;
  readonly loadDefaultListingItems: ILoadListingItemsAction;
  readonly loadContentItemUsage: (itemId: Uuid, variantId: Uuid) => ThunkPromise;
  readonly scheduleSingleVariant: (
    id: ContentItemId,
    actionOrigin: ContentItemEditingEventOrigins,
  ) => ThunkPromise;
  readonly trackUserEvent: TrackUserEventAction;
  readonly contentItemRepository: {
    readonly getItemWithVariant: (
      itemId: Uuid,
      variantId: Uuid,
    ) => Promise<IContentItemWithVariantServerModel>;
    readonly scheduleVariantsPublish: (
      variantId: Uuid,
      query: IContentItemListScheduledPublishQueryModel,
    ) => Promise<ItemListOperationResultServerModel>;
  };

  readonly parseContentItemVariant: (
    contentItemWithVariant: IContentItemWithVariantServerModel,
  ) => IParsedItemVariant;
}

const started = (editedItemName: string, totalNumberOfItemsToBeScheduled: number) =>
  ({
    type: ContentEditing_CascadeModal_SchedulingStarted,
    payload: {
      editedItemName,
      totalNumberOfItemsToBeScheduled,
    },
  }) as const;

const finished = (payload: {
  readonly defaultVariantReference: IContentItemVariantReference | null;
  readonly failedItemIds: ReadonlySet<MemoizedContentItemId>;
  readonly itemVariantData: IParsedItemVariant;
  readonly itemWithVariant: IContentItemWithVariantServerModel;
  readonly modifiedItemIds: ReadonlySet<MemoizedContentItemId>;
}) =>
  ({
    type: ContentEditing_CascadeModal_SchedulingFinished,
    payload,
  }) as const;

const failed = (errorMessage: string) =>
  ({
    type: ContentEditing_CascadeModal_SchedulingFailed,
    payload: { errorMessage },
  }) as const;

export type CascadeScheduleDialogSubmittedActionType = ReturnType<
  typeof started | typeof finished | typeof failed
>;

export const cascadeScheduleDialogSubmittedActionCreator =
  (deps: IDeps) => (): ThunkPromise => async (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const {
      contentApp: {
        changeWorkflowStepModalData: {
          scheduledToPublishAt,
          scheduledPublishDisplayTimeZone,
          scheduledToUnpublishAt,
          scheduledUnpublishDisplayTimeZone,
        },
        editedContentItem,
        editedContentItemVariant,
      },
      webSpotlightApp: { subpagesById },
    } = state;

    const actionOrigin = getModalDialogActionOrigin(state);
    const selectedLanguageId = getSelectedLanguageIdOrThrow(getState());
    const editingDefaultVariant = selectedLanguageId === DefaultVariantId;

    if (!editedContentItem || !editedContentItemVariant) {
      throw InvariantException('editedContentItem or variant is falsy');
    }

    if (!scheduledToPublishAt) {
      throw InvariantException('scheduledToPublishAt is falsy');
    }

    const { id } = editedContentItemVariant;
    const getCannotScheduleReason = getCannotPublishReasonForContext(
      ItemEditingModalDialogType.CascadeScheduleDialog,
    );
    const itemsToBeScheduled = new Set([
      ...getSelectedItems(state, getCannotScheduleReason),
      memoizeContentItemId(id),
    ]);
    const displayedItemsCount = getLoadedTransitiveDependencies(state).size;
    const itemsAvailableForSelectionCount = getItemsAvailableForSelection(
      state,
      getCannotScheduleReason,
    ).length;
    const formerPublishingState = editedContentItemVariant.publishingState;

    if (itemsToBeScheduled.size === 1) {
      dispatch(
        trackUserEvent(TrackedEvent.CascadeScheduleNoSelectedItems, {
          formerPublishingState,
          displayedItems: displayedItemsCount,
          itemsAvailableForSelection: itemsAvailableForSelectionCount,
        }),
      );
      dispatch(deps.scheduleSingleVariant(id, actionOrigin));
      return;
    }

    try {
      dispatch(started(editedContentItem.name, itemsToBeScheduled.size));

      const itemIdsToScheduleByVariantId = groupItemIdsByVariantId(Array.from(itemsToBeScheduled));

      // When publishing non-default variants with their default counterparts, we need to publish default variants first so that non-localizable elements
      // already have a value when non-default variants are published.
      let defaultItemsToReload: ReadonlyArray<Uuid> = [];
      let defaultScheduleResult = emptyListOperationResult;
      if (!editingDefaultVariant && itemIdsToScheduleByVariantId.has(DefaultVariantId)) {
        const idsToSchedule = itemIdsToScheduleByVariantId.get(DefaultVariantId) ?? [];

        defaultScheduleResult = await callBulkActionInChunks(Immutable.Set(idsToSchedule), (ids) =>
          deps.contentItemRepository.scheduleVariantsPublish(DefaultVariantId, {
            listingOperationQuery: { includedItemIds: ids.toArray() },
            scheduledToPublishAt,
            scheduledPublishDisplayTimeZone,
            scheduledToUnpublishAt,
            scheduledUnpublishDisplayTimeZone,
          }),
        );

        defaultItemsToReload = [...defaultScheduleResult.failedItemIds.toArray(), id.itemId];
      }

      const idsToSchedule = itemIdsToScheduleByVariantId.get(selectedLanguageId) ?? [];
      const scheduleResult = await callBulkActionInChunks(Immutable.Set(idsToSchedule), (ids) =>
        deps.contentItemRepository.scheduleVariantsPublish(selectedLanguageId, {
          listingOperationQuery: { includedItemIds: ids.toArray() },
          scheduledToPublishAt,
          scheduledPublishDisplayTimeZone,
          scheduledToUnpublishAt,
          scheduledUnpublishDisplayTimeZone,
        }),
      );

      const firstLevelChildrenItemIds = Array.from(getFirstLevelChildrenItemIds(state)).map(
        ({ itemId }) => itemId,
      );
      const subpagesIdsToReload = subpagesById
        .toArray()
        .flat()
        .filter((subpageId) => idsToSchedule.includes(subpageId));

      const itemsToReload = Array.from(
        new Set([
          editedContentItemVariant.id.itemId,
          ...firstLevelChildrenItemIds,
          ...scheduleResult.failedItemIds.toArray(),
          ...subpagesIdsToReload,
        ]),
      );

      const [itemWithVariant, defaultListingItems] = await Promise.all([
        deps.contentItemRepository.getItemWithVariant(id.itemId, id.variantId),
        dispatch(deps.loadDefaultListingItems(defaultItemsToReload)),
        dispatch(deps.loadContentItemUsage(id.itemId, id.variantId)),
        dispatch(deps.loadListingItems(itemsToReload)),
      ]);

      const modifiedItemIds = Collection.addMany(
        new Set(
          defaultScheduleResult.modifiedItemIds
            .toArray()
            .map((itemId) => getMemoizedContentItemId(itemId, DefaultVariantId)),
        ),
        scheduleResult.modifiedItemIds
          .toArray()
          .map((itemId) => getMemoizedContentItemId(itemId, selectedLanguageId)),
      );

      const failedItemIds = Collection.addMany(
        new Set(
          defaultScheduleResult.failedItemIds
            .toArray()
            .map((itemId) => getMemoizedContentItemId(itemId, DefaultVariantId)),
        ),
        scheduleResult.failedItemIds
          .toArray()
          .map((itemId) => getMemoizedContentItemId(itemId, selectedLanguageId)),
      );

      const selectedItemsCount = itemsToBeScheduled.size - EditedItemCount;
      dispatch(
        trackUserEvent(TrackedEvent.CascadeScheduleWithSelectedItems, {
          displayedItems: displayedItemsCount,
          itemsAvailableForSelection: itemsAvailableForSelectionCount,
          selectedItems: selectedItemsCount,
          failedItemsCount: failedItemIds.size,
          allAvailableItemsSelected: itemsAvailableForSelectionCount === selectedItemsCount,
          formerPublishingState,
        }),
      );

      const itemVariantData = deps.parseContentItemVariant(itemWithVariant);
      assert(defaultListingItems, () => 'Default listing items not loaded');
      const defaultVariantReference = getContentItemVariantReferenceFromListingItem(
        defaultListingItems.find((item) => item.variant?.id.itemId === id.itemId) ?? null,
      );

      dispatch(
        finished({
          defaultVariantReference,
          failedItemIds,
          itemVariantData,
          itemWithVariant,
          modifiedItemIds,
        }),
      );

      dispatch(
        trackUserEvent(
          TrackedEvent.ContentItemEditing,
          prepareWorkflowStepTrackingDataWithFeatures(itemVariantData, {
            action: ContentItemEditingChangeAction.Schedule,
            origin: actionOrigin,
          }),
        ),
      );
    } catch (error) {
      logErrorToMonitoringToolWithCustomMessage('Error during cascade scheduling', error);
      dispatch(failed(CascadeSchedulingErrorMessage));
    }
  };
