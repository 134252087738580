import { memoize } from '@kontent-ai/memoization';
import React from 'react';
import { trackUserEvent } from '../../../../_shared/actions/thunks/trackUserEvent.ts';
import { IconName } from '../../../../_shared/constants/iconEnumGenerated.ts';
import { TrackedEvent } from '../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { GuidelinesEventTypes } from '../../../../_shared/models/TrackUserEventData.ts';
import {
  getCurrentProjectId,
  getProjectPlan,
} from '../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { ISnippets } from '../../../../data/reducers/snippets/ISnippets.type.ts';
import { ITaxonomyGroups } from '../../../../data/reducers/taxonomyGroups/ITaxonomyGroups.type.ts';
import {
  ElementType,
  TypeElementType,
} from '../../../contentInventory/content/models/ContentItemElementType.ts';
import { isWebSpotlightEnabled } from '../../../webSpotlight/selectors/webSpotlightSelectors.ts';
import {
  typeElementDropped,
  typeElementPickedUp,
} from '../../shared/actions/sharedContentModelsActions.ts';
import { addNewTypeElement } from '../../shared/actions/thunkSharedContentModelsActions.ts';
import {
  TypeElementLibrary as ContentTypeElementLibraryComponent,
  TypeElementItemConfig,
} from '../../shared/components/TypeElementLibrary.tsx';
import { NewElementId } from '../../shared/reducers/typeEditor/draggedElementId.ts';
import { isSnippetTypeElement } from '../../shared/types/typeElementTypeGuards.ts';
import { elementTypeNameMap } from '../../shared/utils/typeElementsUtils.ts';

const getItemElementsConfigs = memoize.maxOne(
  (areCustomElementsEnabled: boolean): ReadonlyArray<TypeElementItemConfig> => {
    return [
      {
        name: elementTypeNameMap[ElementType.Text],
        elementType: ElementType.Text,
        iconName: IconName.ParagraphShort,
      },
      {
        name: elementTypeNameMap[ElementType.RichText],
        elementType: ElementType.RichText,
        iconName: IconName.Paragraph,
      },
      {
        name: elementTypeNameMap[ElementType.Number],
        elementType: ElementType.Number,
        iconName: IconName.Octothorpe,
      },
      {
        name: elementTypeNameMap[ElementType.MultipleChoice],
        elementType: ElementType.MultipleChoice,
        iconName: IconName.CbCheck,
      },
      {
        name: elementTypeNameMap[ElementType.DateTime],
        elementType: ElementType.DateTime,
        iconName: IconName.Calendar,
      },
      {
        name: elementTypeNameMap[ElementType.Asset],
        elementType: ElementType.Asset,
        iconName: IconName.Picture,
      },
      {
        name: elementTypeNameMap[ElementType.LinkedItems],
        elementType: ElementType.LinkedItems,
        iconName: IconName.Puzzle,
      },
      {
        name: elementTypeNameMap[ElementType.Custom],
        elementType: ElementType.Custom,
        iconName: IconName.CustomElement,
        isDisabled: !areCustomElementsEnabled,
        disabledMessage:
          'Custom elements are not available \nin your plan.\nUpgrade your subscription.',
      },
    ];
  },
);

const getAuxiliaryElementsConfig = memoize.maxOne(
  (
    taxonomyGroups: ITaxonomyGroups,
    snippets: ISnippets,
    subpagesElementEnabled: boolean,
    contentTypeContainsUrlSlug: boolean,
    contentTypeContainsSubpages: boolean,
    allSnippetsAlreadyUsed: boolean,
  ): ReadonlyArray<TypeElementItemConfig> => {
    const taxonomyGroupExists = !taxonomyGroups.byId.isEmpty();
    const isSnippetListEmpty = snippets.byId.isEmpty();
    const snippetDisabledMessage = isSnippetListEmpty
      ? 'Create at least one content type snippet\nto use this element.'
      : 'You can add this element only as\nmany times as there are snippets.';

    const baseElements: Array<TypeElementItemConfig> = [
      {
        name: elementTypeNameMap[ElementType.Guidelines],
        elementType: ElementType.Guidelines,
        iconName: IconName.SchemePathCircles,
      },
      {
        name: elementTypeNameMap[ElementType.Taxonomy],
        elementType: ElementType.Taxonomy,
        iconName: IconName.Drawers,
        isDisabled: !taxonomyGroupExists,
        disabledMessage: 'Create at least one taxonomy group\nto use this element.',
      },
      {
        name: elementTypeNameMap[ElementType.UrlSlug],
        elementType: ElementType.UrlSlug,
        iconName: IconName.Chain,
        isDisabled: contentTypeContainsUrlSlug,
        disabledMessage: 'You can have only one URL slug\nelement in a content type.',
      },
      {
        name: elementTypeNameMap[ElementType.ContentTypeSnippet],
        elementType: ElementType.ContentTypeSnippet,
        iconName: IconName.LListArticle,
        isDisabled: isSnippetListEmpty || allSnippetsAlreadyUsed,
        disabledMessage: snippetDisabledMessage,
      },
    ];

    if (subpagesElementEnabled) {
      return baseElements.concat({
        name: elementTypeNameMap[ElementType.Subpages],
        elementType: ElementType.Subpages,
        iconName: IconName.ParentChildScheme,
        isDisabled: contentTypeContainsSubpages,
        disabledMessage: 'You can have only one Subpages\nelement in a content type.',
      });
    }

    return baseElements;
  },
);

export const ContentTypeElementLibrary: React.FC = () => {
  const dispatch = useDispatch();
  const currentProjectId = useSelector(getCurrentProjectId);
  const areCustomElementsEnabled = useSelector(
    (state) => getProjectPlan(state, currentProjectId).features.areCustomElementsEnabled,
  );
  const snippets = useSelector((state) => state.data.snippets);
  const subpagesElementEnabled = useSelector(isWebSpotlightEnabled);
  const taxonomyGroups = useSelector((state) => state.data.taxonomyGroups);
  const typeElements = useSelector(
    (state) => state.contentModelsApp.typeEditor.editedType.typeElements,
  );

  const contentTypeContainsUrlSlug = typeElements.some(
    (e) => !!e && e.type === ElementType.UrlSlug,
  );
  const contentTypeContainsSubpages = typeElements.some(
    (e) => !!e && e.type === ElementType.Subpages,
  );
  const allSnippetsAlreadyUsed = snippets.byId
    .keySeq()
    .every((snippetId) =>
      typeElements.some((el) => isSnippetTypeElement(el) && el.snippetId === snippetId),
    );

  const onContentElementClicked = (elementType: TypeElementType) => {
    if (elementType === ElementType.Guidelines) {
      dispatch(trackUserEvent(TrackedEvent.Guidelines, { action: GuidelinesEventTypes.Created }));
    }

    dispatch(addNewTypeElement(elementType));
  };

  const contentElementToolbarItemConfigurations = getItemElementsConfigs(areCustomElementsEnabled);
  const auxiliaryElementToolbarItemConfigurations = getAuxiliaryElementsConfig(
    taxonomyGroups,
    snippets,
    subpagesElementEnabled,
    contentTypeContainsUrlSlug,
    contentTypeContainsSubpages,
    allSnippetsAlreadyUsed,
  );

  return (
    <ContentTypeElementLibraryComponent
      auxiliaryElementToolbarItemConfigurations={auxiliaryElementToolbarItemConfigurations}
      contentElementToolbarItemConfigurations={contentElementToolbarItemConfigurations}
      onContentElementClicked={onContentElementClicked}
      onDragEnd={() => dispatch(typeElementDropped())}
      onDragStart={() => dispatch(typeElementPickedUp(NewElementId))}
    />
  );
};
