import { memoize } from '@kontent-ai/memoization';
import React from 'react';
import { IconName } from '../../../../_shared/constants/iconEnumGenerated.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import {
  getCurrentProjectId,
  getProjectPlan,
} from '../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { ITaxonomyGroups } from '../../../../data/reducers/taxonomyGroups/ITaxonomyGroups.type.ts';
import {
  ElementType,
  TypeElementType,
} from '../../../contentInventory/content/models/ContentItemElementType.ts';
import {
  TypeElementLibrary as ContentTypeElementLibraryComponent,
  TypeElementItemConfig,
} from '../../shared/components/TypeElementLibrary.tsx';
import { NewElementId } from '../../shared/reducers/typeEditor/draggedElementId.ts';
import { elementTypeNameMap } from '../../shared/utils/typeElementsUtils.ts';
import { typeElementDropped, typeElementPickedUp } from '../actions/snippetsActions.ts';
import { addNewSnippetTypeElementAction } from '../actions/thunkSnippetsActions.ts';

const getItemElementsConfigs = memoize.maxOne(
  (areCustomElementsEnabled: boolean): ReadonlyArray<TypeElementItemConfig> => {
    return [
      {
        name: elementTypeNameMap[ElementType.Text],
        elementType: ElementType.Text,
        iconName: IconName.ParagraphShort,
      },
      {
        name: elementTypeNameMap[ElementType.RichText],
        elementType: ElementType.RichText,
        iconName: IconName.Paragraph,
      },
      {
        name: elementTypeNameMap[ElementType.Number],
        elementType: ElementType.Number,
        iconName: IconName.Octothorpe,
      },
      {
        name: elementTypeNameMap[ElementType.MultipleChoice],
        elementType: ElementType.MultipleChoice,
        iconName: IconName.CbCheck,
      },
      {
        name: elementTypeNameMap[ElementType.DateTime],
        elementType: ElementType.DateTime,
        iconName: IconName.Calendar,
      },
      {
        name: elementTypeNameMap[ElementType.Asset],
        elementType: ElementType.Asset,
        iconName: IconName.Picture,
      },
      {
        name: elementTypeNameMap[ElementType.LinkedItems],
        elementType: ElementType.LinkedItems,
        iconName: IconName.Puzzle,
      },
      {
        name: elementTypeNameMap[ElementType.Custom],
        elementType: ElementType.Custom,
        iconName: IconName.CustomElement,
        isDisabled: !areCustomElementsEnabled,
        disabledMessage:
          'Custom elements are not available \nin your plan.\nUpgrade your subscription.',
      },
    ];
  },
);

const getAuxiliaryElementsConfig = memoize.maxOne(
  (taxonomyGroups: ITaxonomyGroups): ReadonlyArray<TypeElementItemConfig> => {
    const taxonomyGroupExists = !taxonomyGroups.byId.isEmpty();
    return [
      {
        name: elementTypeNameMap[ElementType.Guidelines],
        elementType: ElementType.Guidelines,
        iconName: IconName.SchemePathCircles,
      },
      {
        name: elementTypeNameMap[ElementType.Taxonomy],
        elementType: ElementType.Taxonomy,
        iconName: IconName.Drawers,
        isDisabled: !taxonomyGroupExists,
        disabledMessage: 'Create at least one taxonomy group\nto use this element.',
      },
    ];
  },
);

export const ContentTypeSnippetElementLibrary: React.FC = () => {
  const dispatch = useDispatch();
  const currentProjectId = useSelector(getCurrentProjectId);
  const areCustomElementsEnabled = useSelector(
    (state) => getProjectPlan(state, currentProjectId).features.areCustomElementsEnabled,
  );
  const taxonomyGroups = useSelector((state) => state.data.taxonomyGroups);

  return (
    <ContentTypeElementLibraryComponent
      auxiliaryElementToolbarItemConfigurations={getAuxiliaryElementsConfig(taxonomyGroups)}
      contentElementToolbarItemConfigurations={getItemElementsConfigs(areCustomElementsEnabled)}
      onContentElementClicked={(elementType: TypeElementType) =>
        dispatch(addNewSnippetTypeElementAction(elementType))
      }
      onDragEnd={() => dispatch(typeElementDropped())}
      onDragStart={() => dispatch(typeElementPickedUp(NewElementId))}
    />
  );
};
