import { useContext } from 'react';
import { loadProjectProperties } from '../../../../_shared/actions/thunkSharedActions.ts';
import { Loader } from '../../../../_shared/components/Loader.tsx';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { useThunkPromise } from '../../../../_shared/hooks/useThunkPromise.ts';
import { getCurrentProjectId } from '../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { AiTranslationsContext } from '../../../richText/plugins/ai/actions/TranslateContent/contexts/AiTranslationsContext.tsx';
import { AiAcceleratorsFiltersEmptyState } from '../components/AiAcceleratorsFiltersEmptyState.tsx';
import { AiAcceleratorsAppFilterContext } from '../contexts/AiAcceleratorsAppFilterContext.tsx';
import { AiAcceleratorsFeatureCodename } from '../types/AiAcceleratorsFeatureCodenames.ts';
import { AiAcceleratorsFeatureMetadata } from '../types/AiAcceleratorsFeatureMetadata.type.ts';
import { getAiAcceleratorsFeatures } from '../utils/getAiAcceleratorsFeatures.ts';
import { AiTranslationFeature } from './Features/AiTranslationFeature.tsx';

export const AiAcceleratorsFeatures = () => {
  const { sortBy, status } = useContext(AiAcceleratorsAppFilterContext);

  const codenameToMetadata = useFeaturesMetadata();
  const features = getAiAcceleratorsFeatures(codenameToMetadata, sortBy, status);

  const currentProjectId = useSelector(getCurrentProjectId);
  const [projectPropertiesLoaded] = useThunkPromise(loadProjectProperties, currentProjectId);

  if (!projectPropertiesLoaded) {
    return <Loader />;
  }

  if (features.length === 0) {
    return <AiAcceleratorsFiltersEmptyState />;
  }

  return (
    <>
      {features.map((feature) => {
        const Component = FeatureCodenameToComponent[feature.codename];
        return (
          <Component
            key={feature.codename}
            codename={feature.codename}
            name={feature.name}
            isEnabled={feature.isEnabled}
          />
        );
      })}
    </>
  );
};

const useFeaturesMetadata = (): ReadonlyRecord<
  AiAcceleratorsFeatureCodename,
  AiAcceleratorsFeatureMetadata
> => ({
  'ai-element-translation': {
    name: 'Translate with AI',
    releaseDate: '2025-04-07T00:00:00.000Z',
    isEnabled: useContext(AiTranslationsContext).isTranslationsFeatureEnabled,
  },
});

const FeatureCodenameToComponent = {
  'ai-element-translation': AiTranslationFeature,
} as const;
