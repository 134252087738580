import { InvariantException } from '@kontent-ai/errors';
import { Action } from '../../../../../../@types/Action.type.ts';
import { Dispatch, GetState, ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import { TrackUserEventAction } from '../../../../../../_shared/models/TrackUserEvent.type.ts';
import { logErrorToMonitoringToolWithCustomMessage } from '../../../../../../_shared/utils/logError.ts';
import { IContentItemWithVariantServerModel } from '../../../../../../repositories/serverModels/INewContentItemServerModel.ts';
import {
  ItemArchivingFinishedPayload,
  contentItemEditingSidebarSectionCleanedUp,
} from '../contentItemEditingActions.ts';

interface IDeps {
  readonly archiveVariantStarted: () => Action;
  readonly archiveVariantFinished: (payload: ItemArchivingFinishedPayload) => Action;
  readonly archiveVariantFailed: () => Action;
  readonly contentItemRepository: {
    readonly archiveVariant: (
      contentItemId: Uuid,
      variantId: Uuid,
    ) => Promise<IContentItemWithVariantServerModel>;
  };
  readonly loadContentTypes: () => ThunkPromise;
  readonly trackUserEvent: TrackUserEventAction;
}

export const createArchiveEditedContentItemVariantAction =
  (deps: IDeps) =>
  (): ThunkPromise =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const { editedContentItemVariant } = getState().contentApp;

    if (!editedContentItemVariant) {
      throw InvariantException(
        'Cannot dispatch archiveEditedContentItemVariant when no edited variant is set.',
      );
    }

    const id = editedContentItemVariant.id;

    dispatch(deps.archiveVariantStarted());
    try {
      const itemWithVariant = await deps.contentItemRepository.archiveVariant(
        id.itemId,
        id.variantId,
      );
      dispatch(
        deps.archiveVariantFinished({
          itemWithVariant,
          itemId: id.itemId,
          variantId: id.variantId,
        }),
      );
      dispatch(
        deps.trackUserEvent(TrackedEvent.ContentItemDeleted, {
          currentEditedItem: true,
          workflowStepId: editedContentItemVariant.assignment.workflowStatus.id,
        }),
      );
      await dispatch(deps.loadContentTypes());
      dispatch(contentItemEditingSidebarSectionCleanedUp());
    } catch (e) {
      logErrorToMonitoringToolWithCustomMessage(
        `Archiving variant with ID '${JSON.stringify(id)}' failed.`,
        e,
      );
      dispatch(deps.archiveVariantFailed());
    }
  };
