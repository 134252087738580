import { notNull } from '@kontent-ai/utils';
import React from 'react';
import { IntercomMessageServerModel } from '../../../../repositories/serverModels/IntercomMessageServerModel.type.ts';
import { trackUserEvent } from '../../../actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../constants/trackedEvent.ts';
import { useDispatch } from '../../../hooks/useDispatch.ts';
import { repositoryCollection } from '../../../repositories/repositories.ts';
import { logErrorToMonitoringTool } from '../../../utils/logError.ts';
import {
  StructuredFeedbackButtons as StructuredFeedbackButtonsComponent,
  StructuredFeedbackButtonsProps,
} from '../components/StructuredFeedbackButtons.tsx';
import {
  FeedbackReasons,
  FeedbackType,
  NegativeFeedbackPayload,
  StructuredFeedbackPayload,
  feedbackFeatureNameByAiFeedbackFeature,
} from '../structuredFeedback.ts';

const { userRepository } = repositoryCollection;

const createFeedbackMessage = (
  payload: NegativeFeedbackPayload,
  feedbackReasons: FeedbackReasons,
): IntercomMessageServerModel => ({
  text: [
    'This message was automatically generated by Kontent.ai.',
    '',
    `Feedback on feature: ${feedbackFeatureNameByAiFeedbackFeature[payload.feature]}`,
    '',
    'Reasons:',
    ...feedbackReasons
      .filter((item) => payload.reasons.includes(item.reason))
      .map((r) => `- ${r.label}`),
    payload.reasons.includes('other') ? '- Other' : null,
    '',
    `Explanation: ${payload.explanation || '(none)'}`,
    '',
    `Does the user want to be contacted: ${payload.canBeContacted ? 'Yes' : 'No'}`,
  ]
    .filter(notNull)
    .join('<br>'),
});

export const StructuredFeedbackButtons: React.FC<
  Omit<StructuredFeedbackButtonsProps, 'onSubmitNegative' | 'onSubmitPositive'>
> = (props) => {
  const dispatch = useDispatch();

  const trackFeedbackEvent = (payload: StructuredFeedbackPayload) => {
    dispatch(trackUserEvent(TrackedEvent.StructuredUserFeedback, payload));
  };

  const onSubmitNegative = (payload: NegativeFeedbackPayload) => {
    try {
      userRepository.sendIntercomMessage(createFeedbackMessage(payload, props.feedbackReasons));
    } catch (e) {
      logErrorToMonitoringTool(e);
    }
    trackFeedbackEvent(payload);
  };

  const onSubmitPositive = () => {
    trackFeedbackEvent({ feature: props.feature, feedbackType: FeedbackType.Positive });
  };

  return (
    <StructuredFeedbackButtonsComponent
      {...props}
      onSubmitNegative={onSubmitNegative}
      onSubmitPositive={onSubmitPositive}
    />
  );
};
