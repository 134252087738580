import { RefObject } from 'react';
import { useHistory } from 'react-router';
import { Loader } from '../../../../_shared/components/Loader.tsx';
import {
  ContentTypesRoute,
  EnvironmentRouteParams,
} from '../../../../_shared/constants/routePaths.ts';
import { DefaultVariantId } from '../../../../_shared/constants/variantIdValues.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { getCurrentProjectId } from '../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { getItems } from '../../../../_shared/utils/getItemListForScrollTable.ts';
import { logErrorMessageToMonitoringTool } from '../../../../_shared/utils/logError.ts';
import {
  getContentItemPath,
  matchPath,
} from '../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { loadContentTypeAsComponentUsage } from '../../../../data/actions/thunkDataActions.ts';
import { useLivePreviewPreferenceStorage } from '../../../../localStorages/useLivePreviewPreferenceStorage.ts';
import { ContentTypeUsageScrollTableEmptyState } from '../../../contentInventory/content/features/ContentItemInventory/containers/ContentTypeUsageScrollTableEmptyState.tsx';
import { ContentTypeUsageScrollTableTitle } from '../../../contentInventory/content/features/ContentItemInventory/containers/ContentTypeUsageScrollTableTitle.tsx';
import { ContentItemScrollTable } from '../../../contentInventory/content/features/ContentItemInventory/containers/ItemInventoryScrollTable/ContentItemScrollTable.tsx';
import { getNumberOfItemsInViewport } from '../../../contentInventory/content/reducers/listingUi/selectors/getNumberOfItemsInViewport.ts';
import { ContentItemSelectorScrollTableHead } from '../../../features/ModalContentItemSelector/containers/ContentItemSelectorScrollTable/ContentItemSelectorScrollTableHead.tsx';
import { ContentItemSelectorScrollTableRow } from '../../../features/ModalContentItemSelector/containers/ContentItemSelectorScrollTable/ContentItemSelectorScrollTableRow.tsx';
import { getContentTypeIdInContentTypeAsComponentUsageDialog } from '../selectors/getContentTypeId.ts';

type Props = {
  readonly closeModal: () => void;
  readonly isInitRetrieving: boolean;
  readonly parentContainerRef: RefObject<HTMLDivElement>;
};

export const ContentTypeUsedAsComponentScrollTable = ({
  closeModal,
  isInitRetrieving,
  parentContainerRef,
}: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const items = useSelector((state) => {
    const { contentTypeComponentsUsage } = state.data;
    return getItems(
      contentTypeComponentsUsage.items,
      contentTypeComponentsUsage.continuationToken,
      getNumberOfItemsInViewport(state.contentApp.listingUi.contentItemListingScrollTableState),
    );
  });
  const contentTypeId = useSelector(getContentTypeIdInContentTypeAsComponentUsageDialog);
  const currentProjectId = useSelector(getCurrentProjectId);
  const { isLivePreviewPreferred } = useLivePreviewPreferenceStorage(currentProjectId);

  const onItemClick = (contentItemId: Uuid, variantId?: Uuid) => {
    closeModal();
    const matchParams = matchPath<EnvironmentRouteParams>(location.pathname, ContentTypesRoute);
    if (!matchParams) {
      logErrorMessageToMonitoringTool('Current route does not belong to ContentItemsRoute routes');
    }

    const contentItemPath = getContentItemPath(
      location.pathname,
      contentItemId,
      isLivePreviewPreferred,
      variantId ?? DefaultVariantId,
    );
    history.push(contentItemPath);
  };

  return isInitRetrieving ? (
    <Loader />
  ) : (
    <ContentItemScrollTable
      items={items}
      onItemClick={onItemClick}
      onItemDoubleClick={onItemClick}
      onLoadContentItems={loadContentTypeAsComponentUsage({
        contentTypeId,
        scrollPositionChanged: true,
      })}
      parentContainerRef={parentContainerRef}
      renderEmptyState={() => <ContentTypeUsageScrollTableEmptyState />}
      renderRowItem={(params) => (
        <ContentItemSelectorScrollTableRow
          key={params.item.item.id + params.index}
          index={params.index}
          item={params.item}
          onItemClick={params.onItemClick}
          onItemDoubleClick={params.onItemDoubleClick}
        />
      )}
      renderTableHead={() => (
        <ContentItemSelectorScrollTableHead
          onItemOrderChanged={() => {
            dispatch(
              loadContentTypeAsComponentUsage({
                contentTypeId,
                scrollPositionChanged: false,
              }),
            );
          }}
        />
      )}
      renderTableTitle={() => <ContentTypeUsageScrollTableTitle contentTypeId={contentTypeId} />}
      withColumnSettings
      ariaLabel="Content type used"
    />
  );
};
