import { Collection } from '@kontent-ai/utils';
import {
  AuditLogRoute,
  CollectionsRoute,
  DefaultWorkflowRoute,
  GeneralEnvironmentSettingsRoute,
  LegacyWebhooksRoute,
  SpacesRoute,
  WorkflowsRoute,
} from '../../../_shared/constants/routePaths.ts';
import { useDataSelector } from '../../../_shared/hooks/useDataSelector.ts';
import { isCollectionsConfigurationVisible } from '../../../_shared/utils/collections/isCollectionsConfigurationVisible.ts';
import { isMultipleWorkflowsConfigurationVisible } from '../../../_shared/utils/workflow/isMultipleWorkflowsConfigurationVisible.ts';
import { IDataStore } from '../../../data/IDataStore.type.ts';
import { IPlan } from '../../../data/models/plans/Plan.ts';
import { IUserProjectInfo } from '../../../data/models/user/UserProjectInfo.ts';
import { AiAcceleratorsRoute } from '../aiAccelerators/constants/routePaths.ts';
import { useCanAccessAiAccelerators } from '../aiAccelerators/hooks/useCanAccessAiAccelerators.ts';
import { InnovationLabSettingsRoute } from '../innovationLab/constants/routePaths.ts';
import { canAccessInnovationLab } from '../innovationLab/utils/canAccessInnovationLab.ts';
import {
  areSpacesEnabledForCurrentProject,
  isAuditLogEnabledForCurrentProjectPlan,
} from '../utils/allowedFeaturesUtils.ts';
import { isEnvironmentGeneralSettingsTabVisible } from '../utils/environmentSettingsMenuCustomVisibilities.ts';

const isSimpleWorkflowConfigurationVisible = (state: IDataStore, currentProjectPlan: IPlan) =>
  !isMultipleWorkflowsConfigurationVisible(state.workflows.byId, currentProjectPlan);

export type EnvironmentSettingsMenuStructureFeatureAvailabilityItem = {
  readonly routePath: string;
  readonly isFeatureEnabled: boolean;
};

export const useGetEnvironmentSettingsMenuFeatureAvailability = (
  projectInfo: IUserProjectInfo,
  currentProjectPlan: IPlan,
  isLegacyWebhookConfigurationEnabled: boolean,
  isWebSpotlightEnabled: boolean,
): ReadonlyArray<EnvironmentSettingsMenuStructureFeatureAvailabilityItem> => [
  {
    routePath: GeneralEnvironmentSettingsRoute,
    isFeatureEnabled: useDataSelector((state) =>
      isEnvironmentGeneralSettingsTabVisible(
        state.user,
        projectInfo,
        currentProjectPlan,
        isWebSpotlightEnabled,
      ),
    ),
  },
  {
    routePath: AiAcceleratorsRoute,
    isFeatureEnabled: useCanAccessAiAccelerators(projectInfo),
  },
  {
    routePath: WorkflowsRoute,
    isFeatureEnabled: useDataSelector((state) =>
      isMultipleWorkflowsConfigurationVisible(state.workflows.byId, currentProjectPlan),
    ),
  },
  {
    routePath: DefaultWorkflowRoute,
    isFeatureEnabled: useDataSelector((state) =>
      isSimpleWorkflowConfigurationVisible(state, currentProjectPlan),
    ),
  },
  {
    routePath: SpacesRoute,
    isFeatureEnabled: areSpacesEnabledForCurrentProject(currentProjectPlan),
  },
  {
    routePath: CollectionsRoute,
    isFeatureEnabled: useDataSelector((state) =>
      isCollectionsConfigurationVisible(
        currentProjectPlan,
        Collection.getValues(state.collections.byId),
      ),
    ),
  },
  {
    routePath: LegacyWebhooksRoute,
    isFeatureEnabled: isLegacyWebhookConfigurationEnabled,
  },
  {
    routePath: AuditLogRoute,
    isFeatureEnabled: isAuditLogEnabledForCurrentProjectPlan(currentProjectPlan),
  },
  {
    routePath: InnovationLabSettingsRoute,
    isFeatureEnabled: useDataSelector((state) => canAccessInnovationLab(state.user, projectInfo)),
  },
];
