import { Box } from '@kontent-ai/component-library/Box';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { WebSpotlightZIndex } from '../../../../../../webSpotlight/constants/WebSpotlightZIndex.ts';
import { PreviewRefreshErrorNotification } from '../containers/PreviewRefreshErrorNotification.tsx';
import { ContentItemPreviewCanvas } from './ContentItemPreviewCanvas.tsx';

type Props = Readonly<{
  contentItemId: Uuid;
  spaceId: Uuid | null;
}>;

export const ContentItemPreview = ({ contentItemId, spaceId }: Props) => {
  return (
    <Box width="100%" height="100%" position="relative" flexGrow={1}>
      <Box
        padding={Spacing.S}
        position="absolute"
        width="100%"
        zIndex={WebSpotlightZIndex.PreviewErrorNotification}
      >
        <PreviewRefreshErrorNotification key={spaceId} />
      </Box>
      <ContentItemPreviewCanvas contentItemId={contentItemId} spaceId={spaceId} />
    </Box>
  );
};
