export const innovationLabFeatureCodenames = [
  'ai-asset-tagging',
  'ai-author-assist',
  'ai-image-description',
  'ai-item-categorization',
  'ai-linked-items',
  'ai-review',
  'ai-search',
  'asset-collection-mandatory',
  'asset-replace',
  'content-status',
  'refined-navigation',
  'secure-assets',
] as const;

export type InnovationLabFeatureCodename = (typeof innovationLabFeatureCodenames)[number];

// These include the ones that are extra feature toggles for standard features but don't have their own card
export type AnyInnovationLabFeatureCodename =
  | InnovationLabFeatureCodename
  | 'ai-enabling-linked-items'
  | 'ai-enabling-search';
