/**
 * Render automatic popup in <Project> component and make sure it is not displayed for
 * subscriptions that do not allow automatic popups. Global handling can be found in <Project> component.
 */
export enum UserFeedbackSource {
  // How do you like feedback
  Ai = 'Ai',
  ContentModeling = 'Content Modeling',
  HelpUsImprove = 'Help us improve',
}

export interface UmuxFeedbackIntercomMessage {
  readonly source: UserFeedbackSource;
  readonly ease: string | null;
  readonly requirements: string | null;
  readonly comment: string;
}
