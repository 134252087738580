import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { logErrorToMonitoringTool } from '../../../../../_shared/utils/logError.ts';
import { ISpacesRepository } from '../../../../../repositories/interfaces/ISpacesRepository.type.ts';
import {
  Spaces_Delete_Failed,
  Spaces_Delete_Started,
  Spaces_Delete_Succeeded,
} from '../../constants/spacesActionTypes.ts';
import { SpacesActionFailedMessage } from '../../constants/spacesUiConstants.ts';

type Deps = {
  readonly spacesRepository: Pick<ISpacesRepository, 'delete'>;
};

const started = () =>
  ({
    type: Spaces_Delete_Started,
  }) as const;

const deleted = (spaceId: Uuid) =>
  ({
    type: Spaces_Delete_Succeeded,
    payload: {
      spaceId,
    },
  }) as const;

const failed = () =>
  ({
    type: Spaces_Delete_Failed,
    payload: {
      errorMessage: SpacesActionFailedMessage`delete`,
    },
  }) as const;

export type DeleteSpaceActionType = ReturnType<typeof started | typeof deleted | typeof failed>;

export const createDeleteSpaceAction =
  (deps: Deps) =>
  (spaceId: Uuid, onSuccess: () => void): ThunkPromise =>
  async (dispatch): Promise<void> => {
    dispatch(started());

    try {
      await deps.spacesRepository.delete(spaceId);
      dispatch(deleted(spaceId));

      onSuccess();
    } catch (error) {
      logErrorToMonitoringTool(error);
      dispatch(failed());
    }
  };
