import Immutable from 'immutable';
import { Dispatch, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { showErrorNotification } from '../../../../../_shared/actions/sharedActions.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import { TrackUserEventAction } from '../../../../../_shared/models/TrackUserEvent.type.ts';
import { logErrorToMonitoringToolWithCustomMessage } from '../../../../../_shared/utils/logError.ts';
import { ITaxonomyGroupServerModel } from '../../../../../repositories/serverModels/contentModels/TaxonomyGroupServerModel.type.ts';
import {
  Taxonomy_Groups_Archived,
  Taxonomy_Groups_Archiving,
} from '../../constants/taxonomyActionTypes.ts';
import { ArchiveTaxonomyGroupErrorMessage } from '../../constants/uiConstants.ts';
import { taxonomyGroupsLoaded } from '../taxonomyActions.ts';

interface ICreateArchiveTaxonomyGroupsActionDependencies {
  readonly taxonomyRepository: {
    readonly archiveTaxonomyGroups: (
      groupIds: Immutable.Set<Uuid>,
    ) => Promise<Array<ITaxonomyGroupServerModel>>;
    readonly getTaxonomyGroups: () => Promise<ReadonlyArray<ITaxonomyGroupServerModel>>;
  };
  readonly trackUserEvent: TrackUserEventAction;
  readonly loadTaxonomyGroups: () => ThunkPromise;
}

const taxonomyGroupsArchivingStarted = () =>
  ({
    type: Taxonomy_Groups_Archiving,
  }) as const;

const taxonomyGroupsArchived = (groupIds: Immutable.Set<Uuid>) =>
  ({
    type: Taxonomy_Groups_Archived,
    payload: {
      groupIds,
    },
  }) as const;

export type ArchiveTaxonomyGroupsActionsType = ReturnType<
  typeof taxonomyGroupsArchived | typeof taxonomyGroupsArchivingStarted
>;

export const createArchiveTaxonomyGroupsAction =
  (deps: ICreateArchiveTaxonomyGroupsActionDependencies) =>
  (groupIds: Immutable.Set<Uuid>): ThunkPromise =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(taxonomyGroupsArchivingStarted());
    try {
      await deps.taxonomyRepository.archiveTaxonomyGroups(groupIds);
      dispatch(taxonomyGroupsArchived(groupIds));

      dispatch(deps.loadTaxonomyGroups());

      const freshGroups = await deps.taxonomyRepository.getTaxonomyGroups();
      dispatch(taxonomyGroupsLoaded(freshGroups));

      dispatch(
        deps.trackUserEvent(TrackedEvent.TaxonomyGroupArchived, {
          'taxonomy-group-ids': JSON.stringify(groupIds.toJS()),
          'taxonomy-group-count': groupIds.count(),
        }),
      );
    } catch (error) {
      dispatch(showErrorNotification(ArchiveTaxonomyGroupErrorMessage));
      logErrorToMonitoringToolWithCustomMessage('Error while archiving a taxonomy group', error);
    }
  };
