import { ReactNode, useContext, useEffect } from 'react';
import { useHistory, useLocation, useParams, useRouteMatch } from 'react-router';
import {
  ContentItemPreviewRoute,
  ContentItemPreviewRouteParams,
  ContentItemRoute,
  ContentItemRouteParams,
  ContentItemsAppRouteSegment,
} from '../../../_shared/constants/routePaths.ts';
import {
  buildPath,
  parseContentItemIds,
} from '../../../_shared/utils/routing/routeTransitionUtils.ts';
import { LivePreviewConfigurationContext } from '../features/ContentItemEditing/features/contentItemPreview/context/LivePreviewConfigurationContext.tsx';

type Props = Readonly<{
  children: ReactNode;
}>;

export const RedirectIfLivePreviewNotEnabled = ({ children }: Props) => {
  const history = useHistory();
  const { search } = useLocation();
  const { isLivePreviewEnabled } = useContext(LivePreviewConfigurationContext);

  const { projectId, spaceId, variantId, contentItemIds } =
    useParams<ContentItemPreviewRouteParams<string>>();
  const isPreviewRoute = !!useRouteMatch(ContentItemPreviewRoute);

  useEffect(() => {
    if (isPreviewRoute && !isLivePreviewEnabled) {
      const path = buildPath<ContentItemRouteParams<UuidArray>>(ContentItemRoute, {
        projectId,
        app: ContentItemsAppRouteSegment.Content,
        variantId,
        spaceId,
        contentItemIds: parseContentItemIds(contentItemIds),
      });

      history.replace({
        pathname: path,
        search,
      });
    }
  }, [
    isPreviewRoute,
    isLivePreviewEnabled,
    projectId,
    variantId,
    spaceId,
    contentItemIds,
    history,
    search,
  ]);

  return children;
};
