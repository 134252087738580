import { Checkbox, checkboxPadding } from '@kontent-ai/component-library/Checkbox';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing, px } from '@kontent-ai/component-library/tokens';
import React, { useCallback } from 'react';
import styled from 'styled-components';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import {
  TypeElementCondition,
  emptyTypeElementCondition,
} from '../../../../../../_shared/models/utils/TypeElementCondition.ts';
import { isConditionalElementsFeatureEnabled } from '../../../../../../_shared/selectors/conditionalElements.ts';
import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import {
  DataUiInput,
  getDataUiInputAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { logErrorMessageToMonitoringTool } from '../../../../../../_shared/utils/logError.ts';
import { ElementType } from '../../../../../contentInventory/content/models/ContentItemElementType.ts';
import { ContentTypeKind } from '../../../constants/contentTypeKind.ts';
import { IBaseTypeElementData } from '../../../models/elements/types/TypeElementData.ts';
import { TypeElementConditionValidationResult } from '../../../utils/typeElementValidators/types/TypeElementWithConditionValidationResult.ts';
import { TypeElementConfigurationPane } from './TypeElementConfigurationPane.tsx';
import { TypeElementGuidelines } from './TypeElementGuidelines.tsx';
import { ConditionConfiguration } from './configuration/ConditionConfiguration.tsx';
import { NonLocalizableConfiguration } from './configuration/NonLocalizableConfiguration.tsx';
import { isConditionConfigurationAvailableForElementType } from './configuration/condition/utils/isConditionConfigurationAvailableForElementType.ts';

interface ITypeElementConfigurationProps {
  readonly conditionValidationResult: TypeElementConditionValidationResult | null;
  readonly guidelinesDisabled: boolean;
  readonly guidelinesValid: boolean;
  readonly onChange: (updatedData: Partial<IBaseTypeElementData>) => void;
  readonly showConfig: boolean;
  readonly typeElementData: IBaseTypeElementData;
  readonly contentTypeKind: ContentTypeKind;
}

export const TypeElementConfiguration: React.FC<
  React.PropsWithChildren<ITypeElementConfigurationProps>
> = ({
  children,
  conditionValidationResult,
  guidelinesDisabled,
  guidelinesValid,
  onChange,
  showConfig,
  typeElementData,
  contentTypeKind,
}) => {
  const typeElements = useSelector((s) => getTypeElements(s, contentTypeKind));

  const handleRequiredChange = useCallback(
    (isRequired: boolean): void => {
      onChange({ isRequired });
    },
    [onChange],
  );

  const handleConditionChange = useCallback(
    (condition: TypeElementCondition): void => {
      onChange({ condition });
    },
    [onChange],
  );

  const handleGuidelinesChange = useCallback(
    (guidelines: string): void => {
      onChange({ guidelines });
    },
    [onChange],
  );

  const handleNonLocalizableChange = useCallback(
    (isNonLocalizable: boolean): void => {
      onChange({ isNonLocalizable });
    },
    [onChange],
  );

  return (
    <TypeElementConfigurationPane showConfig={showConfig}>
      <Stack spacing={Spacing.XL}>
        {contentTypeKind !== ContentTypeKind.AssetType && (
          <>
            {shouldShowConditionConfiguration(typeElementData.type, conditionValidationResult) && (
              <ConditionConfiguration
                condition={typeElementData.condition ?? emptyTypeElementCondition}
                conditionValidationResult={conditionValidationResult}
                elementId={typeElementData.elementId}
                isElementNonLocalizable={typeElementData.isNonLocalizable}
                onChange={handleConditionChange}
                typeElements={typeElements}
              />
            )}

            <Stack align="start" spacing={Spacing.S}>
              <CheckboxProtectedAreaCompensation>
                <Checkbox
                  checkboxState="default"
                  checked={typeElementData.isRequired}
                  onToggle={handleRequiredChange}
                  {...getDataUiInputAttribute(DataUiInput.ContentElementRequired)}
                >
                  Required
                </Checkbox>
              </CheckboxProtectedAreaCompensation>

              <NonLocalizableConfiguration
                onChange={handleNonLocalizableChange}
                typeElementData={typeElementData}
                typeElements={typeElements}
              />
            </Stack>
          </>
        )}
        <TypeElementGuidelines
          disabled={guidelinesDisabled}
          guidelines={typeElementData.guidelines}
          isGuidelinesValid={guidelinesValid}
          onChange={handleGuidelinesChange}
        />
        {children}
      </Stack>
    </TypeElementConfigurationPane>
  );
};

TypeElementConfiguration.displayName = 'TypeElementConfiguration';

const CheckboxProtectedAreaCompensation = styled.div`
  margin-top: -${px(checkboxPadding)}
`;

const getTypeElements = (
  state: IStore,
  contentTypeKind: ContentTypeKind,
): readonly IBaseTypeElementData[] => {
  switch (contentTypeKind) {
    case ContentTypeKind.AssetType:
    case ContentTypeKind.ContentType:
      return state.contentModelsApp.typeEditor.editedType.typeElements;
    case ContentTypeKind.ContentSnippet:
      return state.contentModelsApp.snippets.editor.editedContentTypeSnippet.typeElements;
  }
};

const shouldShowConditionConfiguration = (
  elementType: ElementType,
  conditionValidationResult: TypeElementConditionValidationResult | null,
): conditionValidationResult is TypeElementConditionValidationResult => {
  const isFeatureEnabled = isConditionalElementsFeatureEnabled();
  if (!isFeatureEnabled || !isConditionConfigurationAvailableForElementType(elementType)) {
    return false;
  }

  if (!conditionValidationResult) {
    logErrorMessageToMonitoringTool(
      `Expected conditionValidationResult to be present for "${elementType}" element type.`,
    );
    return false;
  }

  return true;
};
