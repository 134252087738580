import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { logErrorMessageToMonitoringTool } from '../../../../_shared/utils/logError.ts';
import { IContentType } from '../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';

export const getContentType = (state: IStore, typeId: Uuid): IContentType | undefined => {
  const contentType: IContentType | undefined = state.data.contentTypes.byId.get(typeId);

  if (!contentType) {
    logErrorMessageToMonitoringTool(`Content type with id ${typeId} was not found.`);
  }

  return contentType;
};
