import {
  CancelledPromiseError,
  ICancellablePromise,
  makeCancellablePromise,
  notNullNorUndefined,
} from '@kontent-ai/utils';
import { useCallback, useMemo, useRef, useState } from 'react';
import { logErrorToMonitoringTool } from '../../../../../_shared/utils/logError.ts';
import {
  ListingRequestOptionsModel,
  ListingServerResponseModel,
} from '../../../../../repositories/utils/ensureAllRequestedDataFetched.ts';
import { WidgetListingState } from '../types/WidgetListingState.type.ts';

export const useWidgetListingDataFetcher = <TModel, TServerData>(
  fetcher: (
    requestOptions: ListingRequestOptionsModel,
    abortSignal?: AbortSignal,
  ) => Promise<ListingServerResponseModel<TServerData>>,
  sourceDataMapper: (source: TServerData) => TModel,
) => {
  const [state, setState] = useState<WidgetListingState>(WidgetListingState.Init);
  const [data, setData] = useState<ReadonlyArray<NonNullable<TModel>>>([]);
  const [continuationToken, setContinuationToken] = useState<ContinuationToken>(null);
  const currentFetchRequest = useRef<ICancellablePromise | null>(null);

  const hasMoreResults = useMemo(
    () => (!continuationToken && state === WidgetListingState.Init) || continuationToken,
    [continuationToken, state],
  );

  const fetchItems = useCallback(
    (requestOptions: ListingRequestOptionsModel): ICancellablePromise => {
      const abortController = new AbortController();

      currentFetchRequest.current?.cancel();
      currentFetchRequest.current = makeCancellablePromise(() =>
        fetcher(requestOptions, abortController.signal),
      )
        .then((response) => {
          setContinuationToken(response.continuationToken);
          setData((prevState) => {
            const fetchedData = response.data
              .map(sourceDataMapper)
              .filter(notNullNorUndefined) as ReadonlyArray<NonNullable<TModel>>;

            const newData = [...prevState, ...fetchedData];
            setState(newData.length > 0 ? WidgetListingState.Loaded : WidgetListingState.Empty);
            return newData;
          });
        })
        .catch((error) => {
          abortController.abort(error);

          if (!(error instanceof CancelledPromiseError)) {
            logErrorToMonitoringTool(error);
            setState(WidgetListingState.Error);
          }
        })
        .finally(() => {
          currentFetchRequest.current = null;
        });
      return currentFetchRequest.current;
    },
    [fetcher, sourceDataMapper],
  );

  const fetchInit = useCallback(
    (numberOfItems: number): ICancellablePromise => {
      setContinuationToken(null);
      setData([]);
      setState(WidgetListingState.Loading);
      return fetchItems({ maxItemsCount: numberOfItems, continuationToken: null });
    },
    [fetchItems],
  );

  const fetchMore = useCallback(
    (numberOfItems: number): ICancellablePromise => {
      if (!hasMoreResults) {
        return makeCancellablePromise(() => Promise.resolve(undefined));
      }
      setState(WidgetListingState.LoadingMore);
      return fetchItems({ maxItemsCount: numberOfItems, continuationToken });
    },
    [continuationToken, fetchItems, hasMoreResults],
  );

  return {
    data,
    fetchInit,
    fetchMore,
    hasMoreResults,
    state,
  };
};
