import { createGuid, delay } from '@kontent-ai/utils';
import { Dispatch, ThunkPromise } from '../../../../../../../../@types/Dispatcher.type.ts';
import { showErrorNotification } from '../../../../../../../../_shared/actions/sharedActions.ts';
import { IStore } from '../../../../../../../../_shared/stores/IStore.type.ts';
import { logErrorToMonitoringToolWithCustomMessage } from '../../../../../../../../_shared/utils/logError.ts';
import { IContentItemRepository } from '../../../../../../../../repositories/interfaces/IContentItemRepository.type.ts';
import { StatusMessageAction } from '../../../../../stores/IContentAppStoreState.ts';
import {
  ContentListing_UndoArchiveItems_Finished,
  ContentListing_UndoArchiveItems_Started,
} from '../../../constants/contentItemInventoryActionTypes.ts';
import { BulkUndoArchiveErrorMessage } from '../../../constants/uiConstants.ts';
import { createBulkActionResult } from '../../../utils/createBulkActionResult.ts';
import {
  createBulkActionFinished,
  createBulkActionStarted,
  resetStatusInfo,
} from '../../contentItemInventoryActions.ts';
import { setTimeoutForMessageChange } from './setTimeoutForMessageChange.ts';

interface IUndoDeleteContentItemsActionDependencies {
  readonly contentItemRepository: IContentItemRepository;
  readonly loadListingContentItems: () => ThunkPromise;
}

const actionStarted = createBulkActionStarted<typeof ContentListing_UndoArchiveItems_Started>(
  ContentListing_UndoArchiveItems_Started,
);
const actionFinished = createBulkActionFinished<typeof ContentListing_UndoArchiveItems_Finished>(
  ContentListing_UndoArchiveItems_Finished,
);

export type UndoDeleteContentItemsActionsType = ReturnType<
  typeof actionStarted | typeof actionFinished
>;

export const undoDeleteContentItemsActionCreator =
  (deps: IUndoDeleteContentItemsActionDependencies) =>
  (variantId: Uuid, affectedIds: ReadonlyArray<Uuid>): ThunkPromise =>
  async (dispatch: Dispatch, getState: () => IStore): Promise<any> => {
    const operationId = createGuid();
    dispatch(actionStarted({ operationId }));
    dispatch(setTimeoutForMessageChange(operationId));

    try {
      const operationResult = await deps.contentItemRepository.restoreVariants(variantId, {
        itemIds: affectedIds,
      });
      const bulkActionResult = createBulkActionResult(operationResult, variantId);

      dispatch(actionFinished(bulkActionResult));
      dispatch(deps.loadListingContentItems());

      delay(5000).then(() => {
        const {
          successfulIds,
          lastAction,
          variantId: lastVariantId,
        } = getState().contentApp.listingUi.statusInfo;
        const sameIds = successfulIds === bulkActionResult.successfulIds;
        const sameAction = lastAction.lastActionType === StatusMessageAction.Archived;
        const sameLanguage = lastVariantId === bulkActionResult.variantId;
        if (sameAction && sameIds && sameLanguage) {
          dispatch(resetStatusInfo());
        }
      });
    } catch (error) {
      logErrorToMonitoringToolWithCustomMessage('Error while bulk restore', error);
      dispatch(showErrorNotification(BulkUndoArchiveErrorMessage));
    }
  };
