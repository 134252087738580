import { isAbortError } from '@kontent-ai/errors';
import { repositoryCollection } from '../../../_shared/repositories/repositories.ts';
import { logErrorToMonitoringToolWithCustomMessage } from '../../../_shared/utils/logError.ts';
import { ILanguageRepository } from '../../../repositories/interfaces/ILanguageRepository.type.ts';
import {
  ILanguageServerModel,
  ILanguagesServerModel,
} from '../../../repositories/serverModels/ProjectLanguagesServerModel.type.ts';

interface ICreateVariantServiceDeps {
  readonly languageRepository: Pick<ILanguageRepository, 'getLanguagesForProject'>;
}

export interface IVariantService {
  readonly getVariantIdFromCodename: (
    variantCodename: string,
    projectId: Uuid,
    abortSignal?: AbortSignal,
  ) => Promise<Uuid | null>;
}

const getMatchingLanguageByCodename = (
  variantCodename: string,
  projectLanguages: ILanguagesServerModel,
): ILanguageServerModel | null => {
  if (
    projectLanguages.defaultLanguage &&
    projectLanguages.defaultLanguage.codeName === variantCodename
  ) {
    return projectLanguages.defaultLanguage;
  }

  if (!projectLanguages.languages) {
    return null;
  }

  const matchingLanguage = projectLanguages.languages.find(
    (language: ILanguageServerModel) => language.codeName === variantCodename,
  );
  return matchingLanguage ? matchingLanguage : null;
};

export const variantServiceCreator = ({
  languageRepository,
}: ICreateVariantServiceDeps): IVariantService => ({
  getVariantIdFromCodename: async (
    variantCodename: string,
    projectId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<Uuid | null> => {
    const validInput = !!projectId && !!variantCodename;
    if (!validInput) {
      return null;
    }

    try {
      const projectLanguages = await languageRepository.getLanguagesForProject(
        projectId,
        abortSignal,
      );
      const language = getMatchingLanguageByCodename(variantCodename, projectLanguages);
      return language ? language.id : null;
    } catch (error) {
      if (!isAbortError(error)) {
        logErrorToMonitoringToolWithCustomMessage(
          `Cannot load project languages for project id ${projectId}`,
          error,
        );
        return null;
      }

      throw error as unknown;
    }
  },
});

export const variantService = variantServiceCreator({
  languageRepository: repositoryCollection.languageRepository,
});
