import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import ContentInventory from '../../applications/contentInventory/shared/containers/ContentInventory.tsx';
import ContentModels from '../../applications/contentModels/shared/containers/ContentModels.tsx';
import { CustomApps } from '../../applications/customApps/containers/CustomApps.tsx';
import { EnvironmentSettings } from '../../applications/environmentSettings/root/containers/EnvironmentSettings.tsx';
import { MissionControlPage } from '../../applications/missionControl/components/MissionControlPage.tsx';
import { RedirectHomeRoutesToMissionControl } from '../../applications/missionControl/components/RedirectHomeRoutesToMissionControl.tsx';
import { EnforceServiceAgreement } from '../../applications/subscriptionManagement/shared/containers/EnforceServiceAgreement.tsx';
import { ensureSelectedVariantForWebSpotlight } from '../../applications/webSpotlight/actions/thunkWebSpotlightActions.ts';
import { localizedRouteLeft } from '../../applications/webSpotlight/actions/webSpotlightActions.ts';
import { WebSpotlight } from '../../applications/webSpotlight/components/WebSpotlight.tsx';
import { EnsureWebSpotlight } from '../../applications/webSpotlight/containers/EnsureWebSpotlight.tsx';
import { AppNames } from '../constants/applicationNames.ts';
import {
  EnvironmentRouteParams,
  HomeRootRoute,
  WebSpotlightEntryRouteParams,
} from '../constants/routePaths.ts';
import { ShowAiFeedbackModalAutomatically } from '../containers/AiSurvey/ShowAiFeedbackModalAutomatically.tsx';
import { EnsureSelectedVariantIdInRoute } from '../containers/EnsureSelectedVariantIdInRoute.tsx';
import { ShowHelpUsImproveSurveyDialogAutomatically } from '../containers/HelpUsImproveSurvey/ShowHelpUsImproveSurveyDialogAutomatically.tsx';
import { TryEnsuringSelectedVariantId } from '../containers/TryEnsuringSelectedVariantId.tsx';
import { AuthorizedSection } from '../containers/routing/AuthorizedSection.tsx';
import { IRouteContext, RouteContext } from '../containers/routing/RouteContext.tsx';
import { getSelectedLanguageId } from '../selectors/getSelectedLanguageId.ts';
import { Capability, neverAllowed } from '../utils/permissions/capability.ts';
import { HtmlPageTitle, SpecialAppNames } from './HtmlPageTitle.tsx';
import { useRedirectPropsWithSameSearch } from './routing/useRedirectPropsWithSameSearch.tsx';

export type ProjectRoutes = {
  readonly content: RouteConfig;
  readonly contentModels: RouteConfig;
  readonly customApps?: RouteConfig;
  readonly environmentSettings: RouteConfig;
  readonly missionControl?: RouteConfig;
  readonly webSpotlight?: RouteConfig;
};

type RouteConfig = {
  readonly path: string;
  readonly requiredCapabilities: ReadonlyArray<Capability>;
};

type Props = {
  readonly areAutomaticPopupsEnabled: boolean;
  readonly firstAccessibleRoute: string;
  readonly projectName: string;
  readonly routes: ProjectRoutes;
};

export const Project: React.FC<Props> = ({
  areAutomaticPopupsEnabled,
  firstAccessibleRoute,
  projectName,
  routes,
}) => {
  const getRedirectPropsWithSameSearch = useRedirectPropsWithSameSearch();

  return (
    <>
      <HtmlPageTitle appName={SpecialAppNames.Project} customName={projectName} />
      <EnforceServiceAgreement>
        <TryEnsuringSelectedVariantId>
          <Switch>
            <Route path={HomeRootRoute}>
              <RedirectHomeRoutesToMissionControl />
            </Route>
            {routes.missionControl && (
              <Route path={routes.missionControl.path}>
                <AuthorizedSection
                  appName={AppNames.MissionControl}
                  requiresOneOfCapabilities={routes.missionControl.requiredCapabilities}
                >
                  <MissionControlPage />
                </AuthorizedSection>
              </Route>
            )}
            {routes.webSpotlight && (
              <Route path={routes.webSpotlight.path}>
                <RouteContext>
                  {(props: IRouteContext<WebSpotlightEntryRouteParams>) => (
                    <AuthorizedSection
                      appName={AppNames.WebSpotlight}
                      requiresOneOfCapabilities={
                        routes.webSpotlight?.requiredCapabilities ?? neverAllowed
                      }
                    >
                      <EnsureSelectedVariantIdInRoute
                        key={`${props.match.params.projectId}${props.match.params.variantId}`}
                        currentRouteVariantId={props.match.params.variantId}
                        ensureSelectedVariant={ensureSelectedVariantForWebSpotlight}
                        getSelectedLanguageIdFromStore={getSelectedLanguageId}
                        localizedRouteLeft={localizedRouteLeft}
                      >
                        <EnsureWebSpotlight>
                          <WebSpotlight />
                        </EnsureWebSpotlight>
                      </EnsureSelectedVariantIdInRoute>
                    </AuthorizedSection>
                  )}
                </RouteContext>
              </Route>
            )}
            <Route path={routes.content.path}>
              <AuthorizedSection
                appName={AppNames.Content}
                requiresOneOfCapabilities={routes.content.requiredCapabilities}
              >
                <ContentInventory />
              </AuthorizedSection>
            </Route>
            <Route path={routes.contentModels.path}>
              <RouteContext>
                {({ match }: IRouteContext<EnvironmentRouteParams>) => (
                  <AuthorizedSection
                    appName={AppNames.ContentModels}
                    requiresOneOfCapabilities={routes.contentModels.requiredCapabilities}
                  >
                    <ContentModels projectId={match.params.projectId} />
                  </AuthorizedSection>
                )}
              </RouteContext>
            </Route>
            {routes.customApps && (
              <Route path={routes.customApps.path}>
                <AuthorizedSection
                  appName={AppNames.CustomApps}
                  requiresOneOfCapabilities={routes.customApps.requiredCapabilities}
                >
                  <CustomApps />
                </AuthorizedSection>
              </Route>
            )}
            <Route path={routes.environmentSettings.path}>
              <AuthorizedSection
                appName={AppNames.EnvironmentSettings}
                requiresOneOfCapabilities={routes.environmentSettings.requiredCapabilities}
              >
                <EnvironmentSettings />
              </AuthorizedSection>
            </Route>
            <Route>
              <Redirect {...getRedirectPropsWithSameSearch({ to: firstAccessibleRoute })} />
            </Route>
          </Switch>
        </TryEnsuringSelectedVariantId>
        {areAutomaticPopupsEnabled && (
          <>
            <ShowHelpUsImproveSurveyDialogAutomatically />
            <ShowAiFeedbackModalAutomatically />
          </>
        )}
      </EnforceServiceAgreement>
    </>
  );
};

Project.displayName = 'Project';
