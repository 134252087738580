import { Action } from '../../../../@types/Action.type.ts';
import {
  Environment_LoadLivePreviewConfiguration_Finished,
  Environment_SetLivePreviewConfiguration_Finished,
} from '../../../../applications/environmentSettings/general/constants/generalSettingsActionTypes.ts';
import { ILivePreviewConfiguration } from '../ILivePreviewConfiguration.type.ts';

const initialState: ILivePreviewConfiguration = {
  status: 'disabled',
};

export const livePreviewConfiguration = (
  state = initialState,
  action: Action,
): ILivePreviewConfiguration => {
  switch (action.type) {
    case Environment_LoadLivePreviewConfiguration_Finished:
    case Environment_SetLivePreviewConfiguration_Finished:
      return action.payload.livePreviewConfiguration;

    default:
      return state;
  }
};
