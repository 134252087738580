import { Dispatch, ThunkPromise } from '../../../../../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../../../../../_shared/constants/trackedEvent.ts';
import { UpdateWorkflowStepErrorMessage } from '../../../../../../../../_shared/features/ChangeWorkflowStepModal/constants/uiConstants.ts';
import { createUpdatedAssignmentForUpdateWorkflowStep } from '../../../../../../../../_shared/features/ChangeWorkflowStepModal/utils/changeWorkflowStepModalUtils.ts';
import { TrackUserEventAction } from '../../../../../../../../_shared/models/TrackUserEvent.type.ts';
import { ContentItemWorkflowStepBulkActionEventData } from '../../../../../../../../_shared/models/TrackUserEventData.ts';
import { getSelectedLanguageIdOrThrow } from '../../../../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { IStore } from '../../../../../../../../_shared/stores/IStore.type.ts';
import { logErrorToMonitoringToolWithCustomMessage } from '../../../../../../../../_shared/utils/logError.ts';
import { IBulkAssignmentUpdateServerModel } from '../../../../../../../../repositories/serverModels/IAssignmentServerModel.type.ts';
import { ItemListOperationResultServerModel } from '../../../../../../../../repositories/serverModels/IListingContentItemServerModel.type.ts';
import {
  IAssignment,
  emptyAssignment,
} from '../../../../../../../itemEditor/models/contentItem/Assignment.ts';
import { ContentItemListingOperationQueryModel } from '../../../../../models/filter/ContentItemListOperationQueryModel.type.ts';
import { getItemListingOperationQueryFromFilter } from '../../../../../utils/contentItemListModelConverters.ts';
import {
  ContentListing_UpdateWorkflowStep_Failed,
  ContentListing_UpdateWorkflowStep_Finished,
} from '../../../constants/contentItemInventoryActionTypes.ts';
import { createBulkActionResult } from '../../../utils/createBulkActionResult.ts';
import {
  createBulkActionFailed,
  createBulkActionFinished,
} from '../../contentItemInventoryActions.ts';
import { setTimeoutForMessageChange } from './setTimeoutForMessageChange.ts';

export type IUpdateWorkflowStep = (
  variantId: Uuid,
  query: ContentItemListingOperationQueryModel,
  assignment: IBulkAssignmentUpdateServerModel,
) => Promise<ItemListOperationResultServerModel>;

export type CreateUpdateWorkflowStepEventData = (
  modifiedVariantsCount: number,
) => ContentItemWorkflowStepBulkActionEventData;

type UpdateSelectedVariantsWorkflowStepActionDependencies = {
  readonly trackUserEvent: TrackUserEventAction;
  readonly loadListingContentItems: () => ThunkPromise;
  readonly convertAssignmentToUpdateServerModel: (
    assignment: IAssignment,
  ) => IBulkAssignmentUpdateServerModel;
};

const finished = createBulkActionFinished<typeof ContentListing_UpdateWorkflowStep_Finished>(
  ContentListing_UpdateWorkflowStep_Finished,
);

const failed = createBulkActionFailed<typeof ContentListing_UpdateWorkflowStep_Failed>(
  ContentListing_UpdateWorkflowStep_Failed,
);

export type UpdateSelectedVariantsWorkflowStepActionsType = ReturnType<
  typeof finished | typeof failed
>;

export const createUpdateSelectedVariantsWorkflowStepAction =
  (deps: UpdateSelectedVariantsWorkflowStepActionDependencies) =>
  (
    operationId: Uuid,
    createUpdateWorkflowStepEventData: CreateUpdateWorkflowStepEventData,
    updateWorkflowStep: IUpdateWorkflowStep,
  ): ThunkPromise =>
  async (dispatch: Dispatch, getState: () => IStore): Promise<any> => {
    const state = getState();
    const selectedLanguageId = getSelectedLanguageIdOrThrow(state);
    const query = getItemListingOperationQueryFromFilter(state);

    dispatch(setTimeoutForMessageChange(operationId));

    const assignment: IAssignment = {
      ...emptyAssignment,
      ...createUpdatedAssignmentForUpdateWorkflowStep(state.contentApp.changeWorkflowStepModalData),
    };

    try {
      const operationResult = await updateWorkflowStep(
        selectedLanguageId,
        query,
        deps.convertAssignmentToUpdateServerModel(assignment),
      );
      dispatch(finished(createBulkActionResult(operationResult, selectedLanguageId)));

      const modifiedVariantsCount = operationResult.modifiedItemIds.length;
      const eventData = createUpdateWorkflowStepEventData(modifiedVariantsCount);

      dispatch(deps.trackUserEvent(TrackedEvent.ContentEntryBulkAssignment, eventData));
    } catch (error) {
      logErrorToMonitoringToolWithCustomMessage('Error while bulk update workflow step', error);
      dispatch(failed(UpdateWorkflowStepErrorMessage));
    }

    dispatch(deps.loadListingContentItems());
  };
