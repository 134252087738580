import { useContext } from 'react';
import { ProjectDataRestrictedToSpecificDatacenterContext } from '../../../../_shared/contexts/ProjectDataRestrictedToSpecificDatacenterContext.tsx';
import { useDataSelector } from '../../../../_shared/hooks/useDataSelector.ts';
import { isProjectManager } from '../../../../_shared/utils/permissions/isProjectManager.ts';
import { IUserProjectInfo } from '../../../../data/models/user/UserProjectInfo.ts';

export const useCanAccessAiAccelerators = (projectInfo: IUserProjectInfo): boolean => {
  const user = useDataSelector((state) => state.user);

  const { isProjectDataRestrictedToSpecificDatacenter } = useContext(
    ProjectDataRestrictedToSpecificDatacenterContext,
  );

  return isProjectManager(user, projectInfo) && !isProjectDataRestrictedToSpecificDatacenter;
};
