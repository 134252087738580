import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { loadProjectProperties } from '../../../../../_shared/actions/thunkSharedActions.ts';
import { repositoryCollection } from '../../../../../_shared/repositories/repositories.ts';
import { getCurrentProjectId } from '../../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { logErrorToMonitoringToolWithCustomMessage } from '../../../../../_shared/utils/logError.ts';
import { AiAcceleratorsFeatureCodename } from '../../types/AiAcceleratorsFeatureCodenames.ts';

const { aiAcceleratorsRepository } = repositoryCollection;

export const deactivateFeature =
  (featureName: AiAcceleratorsFeatureCodename): ThunkPromise =>
  async (dispatch, getState): Promise<void> => {
    try {
      const currentProjectId = getCurrentProjectId(getState());
      await aiAcceleratorsRepository.disableFeature(featureName);
      await dispatch(loadProjectProperties(currentProjectId));
    } catch (error) {
      logErrorToMonitoringToolWithCustomMessage(
        'Failed to disable AI accelerator feature or re-fetch project properties.',
        error,
      );
    }
  };
