import { Collection } from '@kontent-ai/utils';
import { History } from 'history';
import { HandleUnsavedFormOnNavigation } from '../../../../_shared/containers/HandleUnsavedFormOnNavigation.tsx';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { useThunkPromise } from '../../../../_shared/hooks/useThunkPromise.ts';
import { isAssetTypeEnabled as isAssetTypeEnabledSelector } from '../../../../_shared/selectors/enhancedAssetManagement.ts';
import {
  getCurrentProjectPlan,
  getCurrentProjectSubscription,
} from '../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { isCollectionsConfigurationVisible } from '../../../../_shared/utils/collections/isCollectionsConfigurationVisible.ts';
import { logErrorToMonitoringTool } from '../../../../_shared/utils/logError.ts';
import {
  createNewRoleLink,
  createRoleEditingLink,
} from '../../../../_shared/utils/routing/projectSubscriptionRoutingUtils.ts';
import { isSitemapEnabled as isSitemapEnabledUtil } from '../../../contentModels/sitemap/utils/sitemapUtils.ts';
import {
  areSpacesEnabledForCurrentProject,
  isAuditLogEnabledForCurrentProjectPlan,
} from '../../utils/allowedFeaturesUtils.ts';
import {
  roleValidationFailed,
  roleValidationFailedNoSelectedCapabilities,
} from '../actions/rolesActions.ts';
import { createRole, initRoleCreator } from '../actions/thunkRolesActions.ts';
import { RoleEditor as RoleEditorComponent } from '../components/RoleEditor.tsx';
import { RoleEditorStatus } from '../models/RoleEditorStatus.ts';
import { getCustomRolesLimitInfo } from '../selectors/customRoleLimit.ts';
import { canCreateNewCustomRole } from '../utils/customRolesUtils.ts';
import { getUpdateServerModelFromEditedRole } from '../utils/getUpdateServerModelFromEditedRole.ts';
import { hasUnsavedChanges } from '../utils/roleEditorStatusUtils.ts';
import { isRoleValid } from '../utils/validationUtils.ts';

type Props = {
  readonly history: History;
  readonly projectId: Uuid;
  readonly withSubscriptionIdInRoute?: Uuid;
};

export const RoleCreator = ({ history, projectId, withSubscriptionIdInRoute }: Props) => {
  useThunkPromise(initRoleCreator, history, projectId, withSubscriptionIdInRoute ?? null);

  const areCollectionsEnabled = useSelector((s) =>
    isCollectionsConfigurationVisible(
      getCurrentProjectPlan(s),
      Collection.getValues(s.data.collections.byId),
    ),
  );
  const areSpacesEnabled = useSelector((state) =>
    areSpacesEnabledForCurrentProject(getCurrentProjectPlan(state)),
  );
  const isAuditLogEnabled = useSelector((state) =>
    isAuditLogEnabledForCurrentProjectPlan(getCurrentProjectPlan(state)),
  );
  const isAssetTypeEnabled = useSelector((s) =>
    isAssetTypeEnabledSelector(getCurrentProjectPlan(s), s.data.assetTypes.defaultAssetType),
  );
  const isSitemapEnabled = useSelector((s) =>
    isSitemapEnabledUtil(getCurrentProjectSubscription(s)),
  );
  const editedRole = useSelector((s) => s.rolesApp.editorUi.editedRole);
  const canCreateNew = useSelector((s) => canCreateNewCustomRole(getCustomRolesLimitInfo(s)));
  const status = useSelector((s) => s.rolesApp.editorUi.status);
  const contentTypesById = useSelector((s) => s.data.contentTypes.byId);

  const dispatch = useDispatch();

  const roleToCreate = getUpdateServerModelFromEditedRole(editedRole, contentTypesById);

  const saveFromUnsavedNavigationHandler = async (
    onSuccess: () => void,
    onFail: () => void,
  ): Promise<void> => {
    try {
      if (!isRoleValid(editedRole)) {
        dispatch(roleValidationFailed());
        onFail();
        return;
      }

      if (!roleToCreate.capabilities.length) {
        dispatch(roleValidationFailedNoSelectedCapabilities());
        onFail();
        return;
      }

      await dispatch(createRole(roleToCreate, contentTypesById));
      onSuccess();
    } catch (error) {
      onFail();
      logErrorToMonitoringTool(error);
    }
  };

  const onControlSHandler = async (e: KeyboardEvent): Promise<void> => {
    e.preventDefault();

    if (!canCreateNew || status === RoleEditorStatus.IsBeingSaved) return;

    if (!isRoleValid(editedRole)) {
      dispatch(roleValidationFailed());
      return;
    }

    if (!roleToCreate.capabilities.length) {
      dispatch(roleValidationFailedNoSelectedCapabilities());
      return;
    }

    const createdRole = await dispatch(createRole(roleToCreate, contentTypesById));
    history.push(
      createRoleEditingLink({
        projectId,
        roleId: createdRole.id,
        subscriptionId: withSubscriptionIdInRoute,
      }),
    );
  };

  return (
    <>
      <HandleUnsavedFormOnNavigation
        hasUnsavedChanges={hasUnsavedChanges(status)}
        isBeingSaved={status === RoleEditorStatus.IsBeingSaved}
        onSaveChanges={saveFromUnsavedNavigationHandler}
      />
      <RoleEditorComponent
        areCollectionsEnabled={areCollectionsEnabled}
        areSpacesEnabled={areSpacesEnabled}
        editedRoleName={editedRole.name}
        isAssetTypeEnabled={isAssetTypeEnabled}
        isAuditLogEnabled={isAuditLogEnabled}
        isRoleBeingCreated
        isSitemapEnabled={isSitemapEnabled}
        newRoleLink={createNewRoleLink({
          projectId,
          subscriptionId: withSubscriptionIdInRoute,
        })}
        onControlSHandler={onControlSHandler}
        projectId={projectId}
        readonly={!canCreateNew}
      />
    </>
  );
};
