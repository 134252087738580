import { useEffect } from 'react';
import { ThunkFunction } from '../../@types/Dispatcher.type.ts';
import { onSignalRConnected, onSignalRConnectingFailed } from '../actions/sharedActions.ts';
import { onCurrentContentItemChange } from '../actions/signalRNotifications/onCurrentContentItemChange.ts';
import { onItemLiveUsersChange } from '../actions/signalRNotifications/onItemLiveUsersChange.ts';
import { onLockedElementsChange } from '../actions/signalRNotifications/onLockedElementsChange.ts';
import { useAiMessageBuffer } from '../contexts/AiMessageBufferProvider.tsx';
import { useTranslationTaskManager } from '../contexts/TranslationTaskManagerProvider.tsx';
import { getCurrentProject } from '../selectors/userProjectsInfoSelectors.ts';
import { projectNotificationService } from '../services/projectNotificationService.ts';
import { SignalRClient } from '../services/signalR/signalRClient.ts';
import {
  ISignalRConnectionCallbacks,
  Notification,
  Notifications,
} from '../services/signalR/signalRClient.type.ts';
import { Capability, getUserCapability } from '../utils/permissions/capability.ts';
import { getTranslationStatusCallback } from '../utils/translations/getTranslationStatusCallback.ts';
import { useDispatch } from './useDispatch.ts';
import { useSelector } from './useSelector.ts';

export const useProjectNotificationService = (): void => {
  const dispatch = useDispatch();
  const currentProject = useSelector(getCurrentProject);
  const isAuthorized = getUserCapability(currentProject).canOneOf([Capability.ViewContent]);
  const aiMessagesBuffer = useAiMessageBuffer();
  const translationManager = useTranslationTaskManager();

  useEffect(() => {
    if (!isAuthorized) {
      return;
    }

    const notificationCallbacks: {
      readonly [TKey in Notification]: (message: Notifications[TKey]['payload']) => void;
    } = {
      [Notification.ContentChange]: (message) => dispatch(onCurrentContentItemChange(message)),
      [Notification.ItemLiveUsersChange]: (message) => dispatch(onItemLiveUsersChange(message)),
      [Notification.LockedElementsChange]: (message) => dispatch(onLockedElementsChange(message)),
      [Notification.AiOperationResponse]: (message) => aiMessagesBuffer.messageReceived(message),
      [Notification.VariantTranslationTaskNotification]: (message) => {
        const task = translationManager.messageReceived(message);
        dispatch(getTranslationStatusCallback(task));
      },
    };

    const signalRCallbacks: ISignalRConnectionCallbacks = {
      onConnected: () => dispatch(onConnected()),
      onConnectingFailed: (reason) => dispatch(onSignalRConnectingFailed(reason)),
      onNotified: (type, args) => notificationCallbacks[type](args),
    };
    projectNotificationService.connectToProjectHub(
      new SignalRClient(),
      currentProject.projectId,
      signalRCallbacks,
    );
    return projectNotificationService.disconnectFromProjectHub;
  }, [isAuthorized, currentProject.projectId, aiMessagesBuffer, translationManager]);
};

const onConnected = (): ThunkFunction => (dispatch, getState) => {
  const {
    contentApp,
    data: { user },
  } = getState();

  projectNotificationService.ensureClientStateForUser(
    contentApp.editedContentItemVariant?.id,
    contentApp.editorUi.lockedElements,
    user.info.userId,
  );

  dispatch(onSignalRConnected());
};
